import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll
} from 'firebase/storage';
import { storage } from '../auth/firebaseConfig';

export const uploadFile = async (
  file: File,
  path: string
): Promise<string> => {
  try {
    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const uploadMultipleImages = async (
  files: FileList,
  basePath: string
): Promise<string[]> => {
  const uploadPromises = Array.from(files).map((file, index) => {
    const path = `${basePath}/${Date.now()}_${index}_${file.name}`;
    return uploadFile(file, path);
  });

  try {
    const uploadedUrls = await Promise.all(uploadPromises);
    return uploadedUrls;
  } catch (error) {
    console.error('Error uploading multiple images:', error);
    throw error;
  }
};

export const deleteImage = async (url: string): Promise<void> => {
  try {
    const fileRef = ref(storage, url);
    await deleteObject(fileRef);
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};

export const listFilesInFolder = async (folderPath: string): Promise<string[]> => {
  try {
    const folderRef = ref(storage, folderPath);
    const result = await listAll(folderRef);
    const fileUrls = await Promise.all(result.items.map(itemRef => getDownloadURL(itemRef)));
    return fileUrls;
  } catch (error) {
    console.error('Error listing files in folder:', error);
    throw error;
  }
};

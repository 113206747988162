import React, { useState } from 'react';
import { insertDocument } from '../../utility/firebaseHelpers';
import { serverTimestamp } from 'firebase/firestore';
import {
  Loader2,
  Send,
  CheckCircle,
  XCircle,
  MessageSquare
} from 'lucide-react';

const FeedbackForm = () => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!feedback.trim() || rating === 0) {
      setError('Please provide both feedback and rating');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      await insertDocument('platformFeedback', {
        feedback,
        rating,
        createdAt: serverTimestamp()
      });

      setIsSubmitting(false);
      setIsSuccess(true);
      setFeedback('');
      setRating(0);

      // Reset success state after animation
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } catch (err) {
      setIsSubmitting(false);
      setError('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <div className="relative">
      {/* Feedback Toggle Button */}
      <button
        onClick={() => setIsVisible(!isVisible)}
        className="fixed bottom-4 right-4 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-all duration-300"
      >
        <MessageSquare className="w-6 h-6" />
      </button>

      {/* Feedback Form */}
      <div
        className={`fixed bottom-20 right-4 w-80 bg-white rounded-lg shadow-xl transform transition-all duration-300 ease-in-out ${
          isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
        }`}
      >
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-800">
              Share Your Feedback
            </h3>
            <button
              onClick={() => setIsVisible(false)}
              className="text-gray-500 hover:text-gray-700"
            >
              <XCircle className="w-5 h-5" />
            </button>
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-md text-sm">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            {/* Rating Stars */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                How would you rate your experience?
              </label>
              <div className="flex gap-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    onClick={() => setRating(star)}
                    className={`text-2xl transition-colors ${
                      star <= rating ? 'text-yellow-400' : 'text-gray-300'
                    }`}
                  >
                    ★
                  </button>
                ))}
              </div>
            </div>

            {/* Feedback Text */}
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Your feedback
              </label>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                rows={4}
                placeholder="Tell us what you think..."
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full ${
                isSuccess ? 'bg-green-500' : 'bg-blue-600 hover:bg-blue-700'
              } text-white py-2 px-4 rounded-md transition-all duration-300 flex items-center justify-center gap-2`}
            >
              {isSubmitting ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : isSuccess ? (
                <>
                  <CheckCircle className="w-5 h-5" />
                  Thank you!
                </>
              ) : (
                <>
                  <Send className="w-5 h-5" />
                  Submit Feedback
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  PropsWithChildren
} from 'react';

interface CookieConsentSettings {
  status: ConsentStatus;
  analytics: boolean;
  functionality: boolean;
  personalization: boolean;
  advertising: boolean;
  security: boolean;
  expiryDate: string | null;
}

export type ConsentStatus = 'accepted' | 'rejected' | 'partial' | null;

interface CookieConsentContextType {
  consentStatus: ConsentStatus;
  consentSettings: CookieConsentSettings;
  updateConsentStatus: (
    status: ConsentStatus,
    settings?: Partial<CookieConsentSettings>
  ) => void;
  hasAnalyticsConsent: () => boolean;
  hasFullConsent: () => boolean;
  clearConsent: () => void;
  showConsentForm: () => void;
  hideConsentForm: () => void;
  isConsentFormVisible: boolean;
}

const defaultSettings: CookieConsentSettings = {
  status: null,
  analytics: false,
  functionality: false,
  personalization: false,
  advertising: false,
  security: true, // Always required
  expiryDate: null
};

const CookieConsentContext = createContext<
  CookieConsentContextType | undefined
>(undefined);

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error(
      'useCookieConsent must be used within a CookieConsentProvider'
    );
  }
  return context;
};

export const CookieConsentProvider: React.FC<PropsWithChildren> = ({
  children
}) => {
  const [consentStatus, setConsentStatus] = useState<ConsentStatus>(null);
  const [consentSettings, setConsentSettings] =
    useState<CookieConsentSettings>(defaultSettings);
  const [isConsentFormVisible, setIsConsentFormVisible] = useState(false);

  // Load saved consent settings once on component mount
  useEffect(() => {
    const loadSavedConsent = () => {
      const storedConsent = localStorage.getItem(
        'cookieConsent'
      ) as ConsentStatus;
      const storedSettings = localStorage.getItem('consentSettings');
      if (storedSettings) {
        const settings = JSON.parse(storedSettings);
        if (settings.expiryDate && new Date(settings.expiryDate) > new Date()) {
          setConsentStatus(storedConsent);
          setConsentSettings(settings);
          console.log("retrivedSettings: ", settings)
        } else {
          clearConsent();
        }
      } else {
        setIsConsentFormVisible(true);
      }
    };

    loadSavedConsent();
  }, []);

  const clearConsent = () => {
    localStorage.removeItem('cookieConsent');
    localStorage.removeItem('consentSettings');
    setConsentStatus(null);
    setConsentSettings(defaultSettings);
    setIsConsentFormVisible(true);
  };

  const showConsentForm = () => {
    setIsConsentFormVisible(true);
  };

  const hideConsentForm = () => {
    setIsConsentFormVisible(false);
  };

  const updateConsentStatus = (
    status: ConsentStatus,
    settings?: Partial<CookieConsentSettings>
  ) => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);

    const newSettings: CookieConsentSettings = {
      ...defaultSettings, // Start with default to reset any previous settings
      ...settings, // Apply specific settings
      status,
      expiryDate: expiryDate.toISOString()
    };

    setConsentStatus(status);
    setConsentSettings(newSettings);
    setIsConsentFormVisible(false);

    // Save to localStorage
    localStorage.setItem('cookieConsent', status || '');
    localStorage.setItem('consentSettings', JSON.stringify(consentSettings));
  };

  const hasAnalyticsConsent = () => {
    return consentSettings.analytics && consentStatus === 'accepted';
  };

  const hasFullConsent = () => {
    return (
      consentStatus === 'accepted' &&
      Object.entries(consentSettings).every(([key, value]) => {
        if (key === 'status' || key === 'expiryDate') return true;
        return value === true;
      })
    );
  };

  return (
    <CookieConsentContext.Provider
      value={{
        consentStatus,
        consentSettings,
        updateConsentStatus,
        hasAnalyticsConsent,
        hasFullConsent,
        clearConsent,
        showConsentForm,
        hideConsentForm,
        isConsentFormVisible
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

// src/pages/cookie-preferences/CookiePreferences.tsx
import React from 'react';
import { useCookieConsent } from '../../context/CookieConsentContext';

const CookiePreferences: React.FC = () => {
  const { consentSettings, showConsentForm, clearConsent } = useCookieConsent();

  console.log('consentSettings: ', consentSettings);

  const preferenceItems = [
    {
      key: 'analytics',
      title: 'Analytics Cookies',
      description: 'Help us improve our website'
    },
    {
      key: 'functionality',
      title: 'Functionality Cookies',
      description: 'Enable site functionality and preferences'
    },
    {
      key: 'personalization',
      title: 'Personalization Cookies',
      description: 'Remember your preferences'
    },
    {
      key: 'advertising',
      title: 'Advertising Cookies',
      description: 'Help show you relevant ads'
    }
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Cookie Preferences</h1>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-2">Current Settings</h2>
          <p className="text-gray-600 mb-4">
            Last updated:{' '}
            {consentSettings.expiryDate
              ? new Date(consentSettings.expiryDate).toLocaleDateString()
              : 'Not set'}
          </p>

          <div className="space-y-4">
            {preferenceItems.map(({ key, title, description }) => (
              <div
                key={key}
                className="flex justify-between items-center py-2 border-b"
              >
                <div>
                  <h3 className="font-medium">{title}</h3>
                  <p className="text-sm text-gray-600">{description}</p>
                </div>
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${
                    consentSettings[key as keyof typeof consentSettings]
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}
                >
                  {consentSettings[key as keyof typeof consentSettings]
                    ? 'Enabled'
                    : 'Disabled'}
                </span>
              </div>
            ))}

            <div className="flex justify-between items-center py-2 border-b">
              <div>
                <h3 className="font-medium">Required Security Cookies</h3>
                <p className="text-sm text-gray-600">
                  Essential for site security
                </p>
              </div>
              <span className="px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                Always Enabled
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 justify-end">
          <button
            onClick={clearConsent}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors"
          >
            Reset All Preferences
          </button>
          <button
            onClick={showConsentForm}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            Update Preferences
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiePreferences;

import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing or using the RentSmart.ie website ("the Site"), you agree
          to comply with and be bound by these Terms of Service. If you do not
          agree to these terms, please do not use our Site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          2. Description of Service
        </h2>
        <p className="mb-4">
          RentSmart.ie is an online platform that connects property owners
          (landlords) with individuals seeking rental accommodations (renters)
          in Ireland. We provide a space for listing, searching, and
          facilitating communication regarding rental properties.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
        <p className="mb-4">
          To access certain features of the Site, you may be required to create
          an account. You are responsible for maintaining the confidentiality of
          your account information and for all activities that occur under your
          account.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. User Conduct</h2>
        <p className="mb-4">
          You agree not to use the Site for any unlawful purpose or in any way
          that could damage, disable, overburden, or impair our service. This
          includes but is not limited to:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Posting false, misleading, or fraudulent information</li>
          <li>Harassing, threatening, or intimidating other users</li>
          <li>
            Attempting to gain unauthorized access to the Site or other users'
            accounts
          </li>
          <li>
            Using the Site for any commercial purpose without our express
            written consent
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          5. Listing and Rental Agreements
        </h2>
        <p className="mb-4">
          RentSmart.ie is a platform for connecting landlords and renters. We do
          not own, manage, or control any properties listed on our Site. Any
          rental agreement is solely between the landlord and the renter. We are
          not responsible for any disputes arising from rental agreements
          facilitated through our Site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Privacy</h2>
        <p className="mb-4">
          Your use of the Site is also governed by our Privacy Policy, which can
          be found{' '}
          <a href="/privacy-policy" className="text-blue-500 hover:underline">
            here
          </a>
          .
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          7. Intellectual Property
        </h2>
        <p className="mb-4">
          The content on RentSmart.ie, including but not limited to text,
          graphics, logos, and software, is the property of RentSmart.ie and is
          protected by copyright and other intellectual property laws.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          8. Limitation of Liability
        </h2>
        <p className="mb-4">
          RentSmart.ie is not liable for any direct, indirect, incidental,
          consequential, or punitive damages arising out of your use of the Site
          or any transactions resulting from its use.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Changes to Terms</h2>
        <p className="mb-4">
          We reserve the right to modify these Terms of Service at any time. We
          will notify users of any significant changes via email or through a
          notice on our Site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Governing Law</h2>
        <p className="mb-4">
          These Terms of Service are governed by and construed in accordance
          with the laws of Ireland. Any disputes relating to these terms shall
          be subject to the exclusive jurisdiction of the courts of Ireland.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">11. Contact Information</h2>
        <p className="mb-4">
          If you have any questions about these Terms of Service, please contact
          us at:
        </p>
        <p>Email: legal@rentsmart.ie</p>
      </section>
    </div>
  );
};

export default TermsOfService;

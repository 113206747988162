import React from 'react';
import { formatPrice } from '../../../utility/formatPrice';
import { ListingIntent } from '../property-card/PropertyCardTypes';
import { capitalizeWords } from '../../../utility/capitalizeWords';
import { getListingIntentColor } from '../../../utility/property-utilities/listingIntentColor';
import { MapPin, Building2, Euro, Bed, Bath, CalendarDays, Clock, Tag } from 'lucide-react';

interface PropertyDetailsProps {
  location: string;
  type: string;
  price: number;
  bedrooms: number;
  bathrooms: number;
  availableFrom: string;
  listingIntent: ListingIntent;
  dateAdded: string;
}

const PropertyDetails: React.FC<PropertyDetailsProps> = ({
  location,
  type,
  price,
  bedrooms,
  bathrooms,
  availableFrom,
  dateAdded,
  listingIntent
}) => {
  const rentAppend = listingIntent === 'rent' ? ' per month' : '';
  return (
    <div className="bg-white p-8 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">
        Property Details
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex items-center text-gray-600">
          <MapPin className="w-6 h-6 mr-3 text-blue-500" />
          <span>{capitalizeWords(location)}</span>
        </div>
        <div className="flex items-center text-gray-600">
          <Building2 className="w-6 h-6 mr-3 text-blue-500" />
          <span>{type}</span>
        </div>
        <div className="flex items-center text-gray-600">
          <Euro className="w-6 h-6 mr-3 text-blue-500" />
          <span>
            €{formatPrice(price)} {rentAppend}
          </span>
        </div>
        <div className="flex items-center text-gray-600 space-x-4">
          <div className="flex items-center">
            <Bed className="w-6 h-6 mr-2 text-blue-500" />
            <span>{bedrooms} Bed</span>
          </div>
          <div className="flex items-center">
            <Bath className="w-6 h-6 mr-2 text-blue-500" />
            <span>{bathrooms} Bath</span>
          </div>
        </div>
        <div className="flex items-center text-gray-600">
          <CalendarDays className="w-6 h-6 mr-3 text-blue-500" />
          <span>Available from {availableFrom}</span>
        </div>
        <div className="flex items-center text-gray-600">
          <Clock className="w-6 h-6 mr-3 text-blue-500" />
          <span>Added on {dateAdded}</span>
        </div>

        {/* Listing Intent Badge */}
        <div className="flex items-center">
          <div
            className={`inline-flex items-center bg-gradient-to-r ${getListingIntentColor(listingIntent)} text-white px-4 py-2 rounded-full text-sm font-semibold shadow-md`}
            style={{ maxWidth: 'fit-content', whiteSpace: 'nowrap' }}
          >
            <Tag className="w-4 h-4 mr-2" />
            <span>For {capitalizeWords(listingIntent)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;

import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';

export const firebaseConfig = {
  apiKey: 'AIzaSyDpjy395whvuBm_5hbPUiNBSkvPnseoEFg',
  authDomain: 'rentsmart-6e31b.firebaseapp.com',
  projectId: 'rentsmart-6e31b',
  storageBucket: 'rentsmart-6e31b.appspot.com',
  messagingSenderId: '262303086551',
  appId: '1:262303086551:web:54632f4975accd5a1a3d92',
  measurementId: 'G-3DRWJ0Y7B4'
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const firebaseFunctions = getFunctions(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const firebaseRemoteConfigApp = getRemoteConfig(app);
export const analytics = getAnalytics(app);

import React, { useState, useMemo } from 'react';
import { User } from 'firebase/auth';
import { FieldValue, Timestamp } from 'firebase/firestore';
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import { RegularConversation, GuestInquiryConversation } from './messagesTypes';

type UnifiedConversation = RegularConversation | GuestInquiryConversation;

interface UnifiedConversationListProps {
  conversations: RegularConversation[];
  guestInquiries: GuestInquiryConversation[];
  selectedItemId: string | null;
  onSelectItem: (id: string, type: 'conversation' | 'guestInquiry') => void;
  onArchiveItem: (
    id: string,
    archive: boolean,
    type: 'conversation' | 'guestInquiry'
  ) => void;
  namePhoto: { [key: string]: { name: string; photoUrl: string | null } };
  currentUser: User | null;
}

const MessagePreview: React.FC<{ message: string }> = ({ message }) => (
  <p className="text-sm text-gray-600 truncate max-w-[250px]">{message}</p>
);

const UnifiedConversationList: React.FC<UnifiedConversationListProps> = ({
  conversations,
  guestInquiries,
  selectedItemId,
  onSelectItem,
  onArchiveItem,
  namePhoto,
  currentUser
}) => {
  const [activeTab, setActiveTab] = useState<'all' | 'active' | 'archive'>(
    'active'
  );

  // Compute counts for each tab
  const counts = useMemo(() => {
    const allItems: UnifiedConversation[] = [
      ...conversations,
      ...guestInquiries
    ];

    const activeItems = allItems.filter((item) => !item.archived);
    const archivedItems = allItems.filter((item) => item.archived);

    return {
      all: allItems.length,
      active: activeItems.length,
      archive: archivedItems.length
    };
  }, [conversations, guestInquiries]);

  const unifiedItems = useMemo(() => {
    const allItems: UnifiedConversation[] = [
      ...conversations.map((conv) => ({
        ...conv,
        type: 'conversation' as const
      })),
      ...guestInquiries.map((inquiry) => ({
        ...inquiry,
        type: 'guestInquiry' as const
      }))
    ];

    // Update the UnifiedConversationList sorting logic to handle FieldValue
    const getSortableTime = (
      timestamp: Timestamp | FieldValue | null | undefined
    ) => {
      if (!timestamp) {
        return 0;
      }

      // Check if it's a Timestamp
      if (timestamp instanceof Timestamp) {
        return timestamp.seconds;
      }

      // If it's a FieldValue (serverTimestamp), treat it as current time
      // This is a temporary value until the server updates it
      return Date.now() / 1000;
    };

    // Sort by timestamp with null check
    allItems.sort((a, b) => {
      const timeA = getSortableTime(a.lastMessageTimestamp);
      const timeB = getSortableTime(b.lastMessageTimestamp);
      return timeB - timeA;
    });

    // Filter based on active tab
    switch (activeTab) {
      case 'active':
        return allItems.filter((item) => !item.archived);
      case 'archive':
        return allItems.filter((item) => item.archived);
      default:
        return allItems;
    }
  }, [conversations, guestInquiries, activeTab]);

  const renderAvatar = (item: UnifiedConversation) => {
    if (item.type === 'guestInquiry') {
      return (
        <div className="w-12 h-12 bg-gray-400 rounded-full flex items-center justify-center">
          <UserIcon className="w-8 h-8 text-white" />
        </div>
      );
    }

    const otherParticipant =
      item.participants.find((p) => p !== currentUser?.uid) || '';
    const { name, photoUrl } = namePhoto[otherParticipant] || {
      name: 'Unknown',
      photoUrl: null
    };

    return photoUrl ? (
      <img
        src={photoUrl}
        alt={name}
        className="w-12 h-12 rounded-full object-cover"
      />
    ) : (
      <div className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center text-white font-semibold text-lg">
        {name.charAt(0).toUpperCase()}
      </div>
    );
  };

  const getName = (item: UnifiedConversation) => {
    if (item.type === 'guestInquiry') {
      return (
        <div className="flex items-center">
          <span>{item.guestName}</span>
          <span className="ml-2 px-2 py-0.5 bg-gray-100 text-gray-600 text-xs rounded-full">
            Guest
          </span>
        </div>
      );
    }

    const otherParticipant =
      item.participants.find((p) => p !== currentUser?.uid) || '';
    return namePhoto[otherParticipant]?.name || 'Unknown User';
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex border-b border-gray-200 bg-white sticky top-0 z-10">
        {(['all', 'active', 'archive'] as const).map((tab) => (
          <button
            key={tab}
            className={`relative flex-1 py-3 px-4 font-medium text-sm transition-colors
            ${
              activeTab === tab
                ? 'text-blue-600 border-b-2 border-blue-600 bg-blue-50/50'
                : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
            {/* Add pill with count */}
            <span className="absolute top-1 right-4 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-blue-600 rounded-full">
              {counts[tab]}
            </span>
          </button>
        ))}
      </div>
      <div className="overflow-y-auto flex-grow">
        {unifiedItems.map((item) => (
          <div
            key={item.id}
            className={`border-b border-gray-100 hover:bg-gray-50 transition-colors cursor-pointer
              ${selectedItemId === item.id ? 'bg-blue-50' : ''}
              ${item.type === 'guestInquiry' ? 'border-l-4 border-l-gray-300' : ''}
            `}
            onClick={() => onSelectItem(item.id, item.type)}
          >
            <div className="p-4 flex items-center">
              <div className="mr-4">{renderAvatar(item)}</div>
              <div className="flex-grow min-w-0">
                <div className="font-semibold text-gray-800">
                  {getName(item)}
                </div>
                <MessagePreview message={item.lastMessage || ''} />
              </div>
              <div className="flex flex-col items-end ml-4 flex-shrink-0">
                <span className="text-xs text-gray-500">
                  {item.lastMessageTimestamp instanceof Timestamp
                    ? item.lastMessageTimestamp
                        .toDate()
                        .toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit'
                        })
                    : ''}
                </span>
                <button
                  className="mt-2 text-gray-500 hover:text-blue-600"
                  onClick={(e) => {
                    e.stopPropagation();
                    onArchiveItem(item.id, !item.archived, item.type);
                  }}
                >
                  {item.archived ? (
                    <ArchiveBoxXMarkIcon className="h-5 w-5" />
                  ) : (
                    <ArchiveBoxIcon className="h-5 w-5" />
                  )}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnifiedConversationList;

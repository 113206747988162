import React from 'react';
import { Link } from 'react-router-dom';
import {
  ShieldExclamationIcon,
  ExclamationTriangleIcon,
  DocumentTextIcon,
  EyeIcon,
  BanknotesIcon,
  UserGroupIcon,
  ClipboardDocumentCheckIcon
} from '@heroicons/react/24/outline';

const SafetyPage: React.FC = () => {
  const commonScams = [
    {
      title: 'Advance Payment Scams',
      description:
        'Scammer asks for deposit/rent before showing the property or signing a lease.',
      icon: BanknotesIcon,
      signs: [
        'Pressure to send money quickly',
        'Requests for wire transfers or cryptocurrency',
        'Unusually low prices for the area',
        'Unable to view the property in person'
      ]
    },
    {
      title: 'Identity Theft Attempts',
      description:
        'Requests for unnecessary personal information or documentation before viewing.',
      icon: UserGroupIcon,
      signs: [
        'Asking for PPS number before viewing',
        'Requesting copies of passport/ID without meeting',
        'Excessive personal information requests',
        'Pressuring for immediate document sharing'
      ]
    },
    {
      title: 'Fake Listings',
      description:
        'Copy legitimate listings with altered contact details or prices.',
      icon: DocumentTextIcon,
      signs: [
        'Images found on multiple listings',
        'Price significantly below market value',
        'Poor quality or stock photos',
        "Listing details don't match location"
      ]
    },
    {
      title: 'Overseas Landlord Scams',
      description:
        'Claims to be abroad and cannot show property but requests payment.',
      icon: EyeIcon,
      signs: [
        'Cannot meet in person',
        'Overseas payment requests',
        'Complicated story about being abroad',
        'Offers to mail keys after payment'
      ]
    }
  ];

  const safetyTips = [
    'Always view a property in person before making any payments',
    'Meet the landlord or agent at the property',
    'Never wire money or pay in cryptocurrency',
    'Get a proper lease agreement and receipt for any payments',
    'Be wary of prices that seem too good to be true',
    'Use official RentSmart.ie messaging system for all communications',
    'Report suspicious listings immediately'
  ];

  return (
    <div className="min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Alert Banner */}
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Stay Safe on RentSmart.ie
              </h3>
              <p className="mt-2 text-sm text-yellow-700">
                Your safety is our priority. Please read these guidelines
                carefully and report any suspicious activity.
              </p>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-12">
          {/* Header */}
          <div className="text-center">
            <ShieldExclamationIcon className="mx-auto h-16 w-16 text-indigo-600" />
            <h1 className="mt-4 text-3xl font-extrabold text-gray-900 sm:text-4xl">
              RentSmart Safety Center
            </h1>
            <p className="mt-4 text-lg text-gray-500 max-w-2xl mx-auto">
              Protect yourself from rental scams and stay safe while using
              RentSmart.ie. Learn about common scams and best practices.
            </p>
          </div>

          {/* Common Scams Section */}
          <div>
            <h2 className="text-2xl font-bold text-gray-900 mb-8">
              Common Rental Scams to Watch Out For
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {commonScams.map((scam) => (
                <div
                  key={scam.title}
                  className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow"
                >
                  <div className="flex items-center mb-4">
                    <scam.icon className="h-8 w-8 text-indigo-600" />
                    <h3 className="ml-3 text-xl font-semibold text-gray-900">
                      {scam.title}
                    </h3>
                  </div>
                  <p className="text-gray-600 mb-4">{scam.description}</p>
                  <h4 className="font-medium text-gray-900 mb-2">
                    Warning Signs:
                  </h4>
                  <ul className="list-disc pl-5 text-gray-600 space-y-1">
                    {scam.signs.map((sign, index) => (
                      <li key={index}>{sign}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Safety Tips Section */}
          <div>
            <h2 className="text-2xl font-bold text-gray-900 mb-8">
              Safety Tips
            </h2>
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="grid gap-4">
                {safetyTips.map((tip, index) => (
                  <div key={index} className="flex items-start">
                    <ClipboardDocumentCheckIcon className="h-6 w-6 text-green-500 mr-3 flex-shrink-0 mt-0.5" />
                    <p className="text-gray-700">{tip}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Report Section */}
          <div className="bg-indigo-50 rounded-lg p-8 text-center">
            <h2 className="text-2xl font-bold text-indigo-900 mb-4">
              Need to Report Something?
            </h2>
            <p className="text-indigo-700 mb-6">
              If you encounter a suspicious listing or message, please report it
              immediately. Our team will investigate and take appropriate
              action.
            </p>
            <div className="space-x-4">
              <Link
                to="/report-listing"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Report a Listing
              </Link>
              <Link
                to="/help-center"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
              >
                Visit Help Center
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafetyPage;

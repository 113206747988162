// UserReviews.tsx

import React, { useState } from 'react';
import { StarIcon } from '@heroicons/react/24/solid';

interface Review {
  id: number;
  user: string;
  rating: number;
  comment: string;
  date: string;
}

const initialReviews: Review[] = [
  {
    id: 1,
    user: 'John Doe',
    rating: 5,
    comment: 'Amazing property! Highly recommend.',
    date: '2023-08-15'
  },
  {
    id: 2,
    user: 'Jane Smith',
    rating: 4,
    comment: 'Great location and friendly agents.',
    date: '2023-08-10'
  }
  // Add more initial reviews as needed
];

const UserReviews: React.FC = () => {
  const [reviews, setReviews] = useState<Review[]>(initialReviews);
  const [user, setUser] = useState<string>('');
  const [rating, setRating] = useState<number>(5);
  const [comment, setComment] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (user.trim() === '' || comment.trim() === '') return;
    const newReview: Review = {
      id: Date.now(),
      user,
      rating,
      comment,
      date: new Date().toISOString().split('T')[0]
    };
    setReviews([newReview, ...reviews]);
    setUser('');
    setRating(5);
    setComment('');
    setSuccess(true);
    setTimeout(() => setSuccess(false), 3000);
  };

  const averageRating =
    reviews.reduce((acc, curr) => acc + curr.rating, 0) / reviews.length;

  return (
    <div className="max-w-5xl mx-auto bg-white shadow-lg rounded-lg p-6">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        User Reviews & Ratings
      </h2>
      {/* Average Rating */}
      <div className="flex items-center mb-6">
        <StarIcon className="w-6 h-6 text-yellow-500 mr-2" />
        <span className="text-xl font-medium text-gray-700">
          {averageRating.toFixed(1)} / 5
        </span>
      </div>
      {/* Review Form */}
      <form onSubmit={handleSubmit} className="mb-6">
        {success && (
          <div className="mb-4 p-3 bg-green-100 text-green-700 rounded-lg">
            Your review has been submitted!
          </div>
        )}
        <div className="mb-4">
          <label className="block text-gray-700">Name</label>
          <input
            type="text"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            required
            className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:outline-none"
            placeholder="Your name"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Rating</label>
          <select
            value={rating}
            onChange={(e) => setRating(parseInt(e.target.value))}
            className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:outline-none"
          >
            {[5, 4, 3, 2, 1].map((r) => (
              <option key={r} value={r}>
                {r} Star{r > 1 ? 's' : ''}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Comment</label>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
            rows={4}
            className="w-full mt-1 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-yellow-500 focus:outline-none"
            placeholder="Your review"
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition duration-300"
        >
          Submit Review
        </button>
      </form>
      {/* Display Reviews */}
      <div>
        {reviews.map((review) => (
          <div
            key={review.id}
            className="mb-4 p-4 border border-gray-200 rounded-lg"
          >
            <div className="flex items-center mb-2">
              <StarIcon className="w-5 h-5 text-yellow-500 mr-1" />
              <span className="text-gray-800 font-medium">{review.user}</span>
              <span className="text-gray-600 ml-auto text-sm">
                {review.date}
              </span>
            </div>
            <p className="text-gray-700">{review.comment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserReviews;

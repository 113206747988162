// src/routes/propertyToolsRoutes.ts

import { lazy, LazyExoticComponent } from 'react';

interface RouteConfig {
  path: string;
  component: LazyExoticComponent<React.FC>;
}

const propertyToolsRoutes: RouteConfig[] = [
  {
    path: 'mortgage-calculator',
    component: lazy(
      () =>
        import(
          '../components/property-tools/MortgageCalculator/MortgageCalculator'
        )
    )
  },
  {
    path: 'property-comparison',
    component: lazy(
      () =>
        import(
          '../components/property-tools/PropertyComparisonTool/PropertyComparisonTool'
        )
    )
  },
  {
    path: 'interactive-property-map',
    component: lazy(
      () =>
        import(
          '../components/property-tools/InteractivePropertyMap/InteractivePropertyMap'
        )
    )
  },
  {
    path: 'price-trend-graph',
    component: lazy(
      () =>
        import('../components/property-tools/PriceTrendGraph/PriceTrendGraph')
    )
  },
  {
    path: 'affordability-calculator',
    component: lazy(
      () =>
        import(
          '../components/property-tools/AffordabilityCalculator/AffordabilityCalculator'
        )
    )
  },
    {
      path: 'property-valuation',
      component: lazy(
        () =>
          import(
            '../components/property-tools/PropertyValuation/PropertyValuation'
          )
      )
    },
  {
    path: 'neighborhood-insights',
    component: lazy(
      () =>
        import(
          '../components/property-tools/NeighborhoodInsights/NeighborhoodInsights'
        )
    )
  },
    {
      path: 'financing-options',
      component: lazy(
        () =>
          import(
            '../components/property-tools/FinancingOptions/FinancingOptions'
          )
      )
    },
  {
    path: 'rental-yield-calculator',
    component: lazy(
      () =>
        import(
          '../components/property-tools/RentalYieldCalculator/RentalYieldCalculator'
        )
    )
  },
  {
    path: 'property-alerts',
    component: lazy(
      () =>
        import(
          '../components/property-tools/PropertyAlerts/PropertyAlerts'
        )
    )
  }
];

export default propertyToolsRoutes;

import { MessageCircle } from 'lucide-react';

const ResponseTimeBadge = () => {
  return (
    <div className="inline-flex items-center px-3 py-1.5 bg-white border border-gray-200 shadow-sm rounded-full text-sm font-medium text-gray-700">
      <MessageCircle
        className="w-4 h-4 mr-2 text-green-500"
        strokeWidth={2.5}
      />
      Usually replies within a few hours
    </div>
  );
};

export default ResponseTimeBadge;

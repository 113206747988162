// src/components/auto-apply/LocationSelector.tsx

import React, { useState, useRef, useEffect } from 'react';
import { MapPin, X } from 'lucide-react';
import { locations, getLocationSuggestions } from '../../data/locations';

interface LocationSelectorProps {
  selectedLocations: string[];
  onLocationsChange: (locations: string[]) => void;
}

const LocationSelector: React.FC<LocationSelectorProps> = ({
  selectedLocations,
  onLocationsChange
}) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.trim() === '') {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    const suggestionResults = getLocationSuggestions(value)
      .map((result) => result.original)
      .filter((location) => !selectedLocations.includes(location));

    setSuggestions(suggestionResults);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (location: string) => {
    if (!selectedLocations.includes(location)) {
      onLocationsChange([...selectedLocations, location]);
    }
    setInputValue('');
    setSuggestions([]);
    setShowSuggestions(false);
  };

  const handleRemoveLocation = (locationToRemove: string) => {
    onLocationsChange(
      selectedLocations.filter((loc) => loc !== locationToRemove)
    );
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node) &&
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700 flex items-center">
        <MapPin className="w-4 h-4 mr-2" /> Preferred Locations
      </label>

      {/* Selected Locations */}
      <div className="flex flex-wrap gap-2 mb-2">
        {selectedLocations.map((location) => (
          <div
            key={location}
            className="flex items-center bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-sm"
          >
            {location}
            <button
              onClick={() => handleRemoveLocation(location)}
              className="ml-2 hover:text-blue-900"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>

      {/* Location Input */}
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300"
          placeholder="Type to search locations..."
        />

        {/* Suggestions Dropdown */}
        {showSuggestions && suggestions.length > 0 && (
          <ul
            ref={suggestionsRef}
            className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-y-auto"
          >
            {suggestions.map((suggestion) => (
              <li
                key={suggestion}
                onClick={() => handleSuggestionClick(suggestion)}
                className="px-4 py-2 hover:bg-blue-50 cursor-pointer text-gray-700 hover:text-blue-700"
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>

      <p className="mt-1 text-sm text-gray-500">
        Search and select multiple locations
      </p>
    </div>
  );
};

export default LocationSelector;

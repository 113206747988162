import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 last:border-b-0">
      <button
        className="flex justify-between items-center w-full py-4 text-left text-gray-800 hover:text-blue-600 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">{question}</span>
        <ChevronDownIcon
          className={`w-5 h-5 transition-transform duration-200 ${
            isOpen ? 'transform rotate-180' : ''
          }`}
        />
      </button>
      {isOpen && (
        <div className="pb-4 text-gray-600">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

const PropertyApplicationFAQ: React.FC = () => {
  const faqData = [
    {
      question: 'How do I apply for a property?',
      answer:
        'The process typically involves three main steps: sending an inquiry to the property owner, waiting for their response, and organizing a viewing if the property meets your requirements.'
    },
    {
      question: 'What should I include in my initial inquiry?',
      answer:
        'In your initial inquiry, briefly introduce yourself, express your interest in the property, and ask any important questions you may have. Be polite and professional in your communication.'
    },
    {
      question: 'How long should I wait for a response?',
      answer:
        "Property owners usually respond within 1-3 business days. If you haven't heard back after this time, you may want to send a polite follow-up message."
    },
    {
      question: 'How do I organize a viewing?',
      answer:
        "Once the property owner responds positively to your inquiry, you can discuss suitable dates and times for a viewing. Be flexible and respectful of the owner's or current tenant's schedule."
    },
    {
      question: 'What should I look out for during a viewing?',
      answer:
        "During a viewing, pay attention to the overall condition of the property, ask about utilities and any included amenities, and take note of the neighborhood. Don't hesitate to ask questions about the lease terms or property history."
    },
    {
      question: 'How can I protect myself from rental scams?',
      answer:
        "Be cautious of listings that seem too good to be true or ask for money before you've seen the property. Always verify the owner's identity and their right to rent the property. If something feels off, trust your instincts and report any suspicious listings to our team."
    }
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-6 max-w-2xl mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">
        Applying for a Property: FAQ
      </h2>
      <div className="space-y-2">
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div>
      <div className="mt-6 p-4 bg-blue-50 rounded-lg">
        <h3 className="text-lg font-medium text-blue-800 mb-2">
          Stay Safe: Beware of Scams
        </h3>
        <p className="text-blue-700">
          Always be cautious when applying for properties online. Never send
          money or personal financial information before verifying the
          legitimacy of a listing. If you encounter any suspicious activity,
          please report it immediately using our reporting tools.
        </p>
      </div>
    </div>
  );
};

export default PropertyApplicationFAQ;

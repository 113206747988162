import React from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { GuestInquiry } from './messagesTypes';

interface GuestInquiryViewProps {
  inquiry: GuestInquiry;
  onBackToList: () => void;
  isMobileView: boolean;
}

const GuestInquiryView: React.FC<GuestInquiryViewProps> = ({
  inquiry,
  onBackToList,
  isMobileView
}) => {
  return (
    <div className="flex flex-col h-full">
      <div className="p-4 border-b flex items-center">
        {isMobileView && (
          <button
            onClick={onBackToList}
            className="mr-2 text-gray-600 hover:text-gray-800"
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </button>
        )}
        <h2 className="font-semibold text-gray-800 text-xl">
          Guest Inquiry from {inquiry.senderName}
        </h2>
      </div>
      <div className="flex-grow overflow-y-auto p-4 bg-gray-50">
        <div className="bg-white p-4 rounded-lg shadow">
          <p>{inquiry.content}</p>
          <small className="text-gray-500 block mt-2">
            Sent on: {inquiry.timestamp.toDate().toLocaleString()}
          </small>
        </div>
      </div>
      <div className="p-4 border-t bg-white">
        <a
          href={`mailto:${inquiry.senderEmail}`}
          className="block w-full text-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
        >
          Reply via Email
        </a>
        <p className="text-center text-gray-500 mt-2">
          You cannot reply directly to guest inquiries. Please use the email
          link above to respond.
        </p>
      </div>
    </div>
  );
};

export default GuestInquiryView;

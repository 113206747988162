import React, { useEffect, useState } from 'react';
import { useAuth } from '../../auth/firebaseAuth';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { auth, db } from '../../auth/firebaseConfig';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { doc, serverTimestamp, where, writeBatch } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { getDocumentById, updateDocument, queryDocuments } from '../../utility/firebaseHelpers';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [email, setEmail] = useState('');
   const location = useLocation();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const validateForm = () => {
    if (!email) {
      setError('Please enter your email address.');
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address.');
      return false;
    }
    if (!isForgotPassword && !password) {
      setError('Please enter your password.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setError('');

    if (isForgotPassword) {
      try {
        await sendPasswordResetEmail(auth, email);
        setResetEmailSent(true);
      } catch (error) {
        setError('Failed to send password reset email. Please try again.');
        console.error(error);
      }
    } else {
      try {
        // First authenticate the user
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        // Check if account is deactivated
        const userDoc = await getDocumentById('users', userCredential.user.uid);

        if (userDoc?.isDeactivated) {
          // Reactivate the account
          await updateDocument('users', userCredential.user.uid, {
            isDeactivated: false,
            reactivatedAt: serverTimestamp(),
            status: 'active'
          });

          // Also reactivate associated listings
          const listings = await queryDocuments('listings', [
            where('propertyOwnerUid', '==', userCredential.user.uid),
            where('status', '==', 'inactive')
          ]);

          const batch = writeBatch(db);
          listings.forEach((listing) => {
            const listingRef = doc(db, 'listings', listing.id.toString());
            batch.update(listingRef, {
              status: 'active',
              reactivatedAt: serverTimestamp()
            });
          });

          await batch.commit();

          // Show success message
          toast.success('Your account has been successfully reactivated!');
        }

        // Navigate to intended destination
        const redirectTo = location.state?.from?.pathname || '/browse';
        navigate(redirectTo, { replace: true });
      } catch (error) {
        setError(
          'Failed to log in. Please check your credentials and try again.'
        );
        console.error(error);
      }
    }

    setIsLoading(false);
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
    setError('');
    setResetEmailSent(false);
  };

   useEffect(() => {
     if (currentUser) {
       const redirectTo = location.state?.from?.pathname || '/browse';
       navigate(redirectTo, { replace: true });
     }
   }, [currentUser, navigate, location]);

  return (
    <div className="w-full max-w-md mx-auto mt-8">
      <div className="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4">
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          {isForgotPassword ? 'Reset Password' : 'Login to your account'}
        </h2>
        <form onSubmit={handleSubmit}>
          {error && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
              role="alert"
            >
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          {resetEmailSent && (
            <div
              className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4"
              role="alert"
            >
              <span className="block sm:inline">
                Password reset email sent. Please check your inbox.
              </span>
            </div>
          )}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-semibold mb-2"
              htmlFor="email"
            >
              Email address
            </label>
            <input
              id="email"
              type="email"
              placeholder="Email address"
              className="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {!isForgotPassword && (
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="••••••"
                  className="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
          )}
          {isForgotPassword && (
            <div className="mb-6 text-sm text-gray-600">
              Enter your email address above and click the button below. We'll
              send you an email with instructions to reset your password.
            </div>
          )}
          <div className="flex items-center justify-between mb-6">
            <button
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline flex items-center justify-center"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {isForgotPassword ? 'Sending...' : 'Logging in...'}
                </>
              ) : isForgotPassword ? (
                'Reset Password'
              ) : (
                'Login'
              )}
            </button>
          </div>
        </form>
        <div className="text-center">
          <button
            onClick={toggleForgotPassword}
            className="text-blue-500 hover:text-blue-700 font-semibold"
          >
            {isForgotPassword ? 'Back to Login' : 'Forgot password?'}
          </button>
        </div>
        <div className="mt-6 text-center">
          <p className="text-gray-600">
            New here?{' '}
            <Link
              to="/signup"
              className="text-blue-500 hover:text-blue-700 font-semibold"
            >
              Create an account
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
// Lazy load BrowseListingsPage
const BrowseListingsPage = React.lazy(() => import('../pages/browse-listings/BrowseListingsPage'));

export interface MarketingRoute {
  path: string;
  element: React.ReactNode;
  seo: {
    title: string;
    description: string;
    keywords: string[];
  };
}

const marketingRoutes: MarketingRoute[] = [
  {
    path: '/affordable-rentals-dublin',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Dublin',
          listingIntent: 'rent',
          maxPrice: 1500,
          propertyType: 'Any'
        }}
      />
    ),
    seo: {
      title: 'Affordable Rentals in Dublin | Budget-Friendly Homes',
      description:
        'Discover affordable rental properties in Dublin. Browse a wide range of budget-friendly apartments and houses in prime locations. Find your ideal home today!',
      keywords: [
        'affordable rentals',
        'Dublin',
        'budget-friendly',
        'apartments',
        'houses',
        'cheap rentals'
      ]
    }
  },
  {
    path: '/luxury-homes-cork',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Cork',
          listingIntent: 'sale',
          minPrice: 500000,
          propertyType: 'House'
        }}
      />
    ),
    seo: {
      title: 'Luxury Homes for Sale in Cork | Exclusive Properties',
      description:
        'Explore luxurious homes for sale in Cork. Find high-end properties with premium amenities, stunning designs, and prime locations. Your dream home awaits!',
      keywords: [
        'luxury homes',
        'Cork',
        'high-end properties',
        'premium real estate',
        'exclusive houses'
      ]
    }
  },
  {
    path: '/student-apartments-galway',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Galway',
          listingIntent: 'rent',
          maxPrice: 800,
          propertyType: 'Apartment',
          bedrooms: 1
        }}
      />
    ),
    seo: {
      title: 'Student Apartments in Galway | Affordable University Living',
      description:
        'Find the perfect student apartment in Galway. Browse affordable, convenient rentals near universities and colleges. Ideal for students seeking comfortable living spaces.',
      keywords: [
        'student apartments',
        'Galway',
        'university housing',
        'affordable rentals',
        'college accommodation'
      ]
    }
  },
  {
    path: '/family-homes-limerick',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Limerick',
          listingIntent: 'sale',
          bedrooms: 3,
          propertyType: 'House'
        }}
      />
    ),
    seo: {
      title: 'Family Homes for Sale in Limerick | Spacious Houses',
      description:
        'Discover perfect family homes for sale in Limerick. Explore spacious houses with 3+ bedrooms, ideal for growing families. Find your new family haven today!',
      keywords: [
        'family homes',
        'Limerick',
        'houses for sale',
        'spacious houses',
        '3-bedroom homes'
      ]
    }
  },
  {
    path: '/city-center-rentals-dublin',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Dublin City Center',
          listingIntent: 'rent',
          propertyType: 'Apartment'
        }}
      />
    ),
    seo: {
      title: 'Dublin City Center Apartments | Prime Location Rentals',
      description:
        'Rent apartments in the heart of Dublin. Find modern, convenient flats in Dublin City Center. Perfect for professionals and urban lifestyle enthusiasts.',
      keywords: [
        'Dublin City Center',
        'apartment rentals',
        'city living',
        'urban apartments',
        'central Dublin'
      ]
    }
  },
  {
    path: '/energy-efficient-homes-dublin',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Dublin',
          listingIntent: 'sale',
          berRating: 'A'
        }}
      />
    ),
    seo: {
      title: 'Energy-Efficient Homes in Dublin | A-Rated BER Properties',
      description:
        'Shop for energy-efficient homes in Dublin with A-rated BER. Discover eco-friendly, cost-effective properties that combine sustainability with modern living.',
      keywords: [
        'energy-efficient homes',
        'Dublin',
        'A-rated BER',
        'eco-friendly houses',
        'sustainable living'
      ]
    }
  },
  {
    path: '/affordable-house-shares-cork',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Cork',
          listingIntent: 'rent',
          maxPrice: 600,
          propertyType: 'House'
        }}
      />
    ),
    seo: {
      title: 'Affordable House Shares in Cork | Budget-Friendly Co-Living',
      description:
        'Find affordable house shares in Cork. Explore budget-friendly co-living options in great locations. Ideal for students, young professionals, and anyone seeking shared accommodation.',
      keywords: [
        'house shares',
        'Cork',
        'affordable living',
        'co-living',
        'shared accommodation'
      ]
    }
  },
  {
    path: '/new-apartments-waterford',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Waterford',
          listingIntent: 'sale',
          propertyType: 'Apartment',
          availableFrom: new Date()
        }}
      />
    ),
    seo: {
      title: 'New Apartments for Sale in Waterford | Modern Living',
      description:
        'Explore brand new apartments for sale in Waterford. Discover modern living spaces with the latest amenities and designs. Find your perfect new home in Waterford today!',
      keywords: [
        'new apartments',
        'Waterford',
        'modern living',
        'apartments for sale',
        'new builds'
      ]
    }
  },
  {
    path: '/spacious-rentals-kilkenny',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Kilkenny',
          listingIntent: 'rent',
          bedrooms: 2,
          bathrooms: 2
        }}
      />
    ),
    seo: {
      title: 'Spacious Rentals in Kilkenny | Comfortable 2-Bed Homes',
      description:
        'Rent spacious homes in Kilkenny. Browse comfortable 2-bedroom, 2-bathroom properties perfect for small families or those needing extra space. Find your roomy retreat!',
      keywords: [
        'spacious rentals',
        'Kilkenny',
        '2-bedroom homes',
        'comfortable living',
        'family rentals'
      ]
    }
  },
  {
    path: '/affordable-homes-galway',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Galway',
          listingIntent: 'sale',
          maxPrice: 300000,
          propertyType: 'House'
        }}
      />
    ),
    seo: {
      title: 'Affordable Homes for Sale in Galway | Budget-Friendly Houses',
      description:
        'Discover affordable houses for sale in Galway. Explore budget-friendly homes under €300,000, perfect for first-time buyers or investors. Your affordable dream home awaits!',
      keywords: [
        'affordable homes',
        'Galway',
        'houses for sale',
        'budget-friendly',
        'first-time buyers'
      ]
    }
  },
  {
    path: '/studio-apartments-dublin',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Dublin',
          listingIntent: 'rent',
          propertyType: 'Apartment',
          bedrooms: 1,
          maxPrice: 1200
        }}
      />
    ),
    seo: {
      title: 'Studio Apartments for Rent in Dublin | Compact City Living',
      description:
        'Find cozy studio apartments for rent in Dublin. Explore compact, affordable living spaces perfect for singles or couples. Embrace efficient city living in Dublin!',
      keywords: [
        'studio apartments',
        'Dublin',
        'compact living',
        'affordable rentals',
        'city apartments'
      ]
    }
  },
  {
    path: '/family-rentals-cork',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Cork',
          listingIntent: 'rent',
          bedrooms: 3,
          propertyType: 'House'
        }}
      />
    ),
    seo: {
      title: 'Family House Rentals in Cork | Spacious 3-Bed Homes',
      description:
        'Rent family-friendly houses in Cork. Browse spacious 3-bedroom homes perfect for families. Find your ideal rental with room for everyone in Cork!',
      keywords: [
        'family rentals',
        'Cork',
        '3-bedroom houses',
        'spacious homes',
        'house rentals'
      ]
    }
  },
  {
    path: '/luxury-apartments-dublin',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Dublin',
          listingIntent: 'sale',
          minPrice: 400000,
          propertyType: 'Apartment',
          berRating: 'A'
        }}
      />
    ),
    seo: {
      title: 'Luxury Apartments for Sale in Dublin | High-End Living',
      description:
        'Explore luxury apartments for sale in Dublin. Discover high-end, energy-efficient living spaces with A-rated BER. Experience the pinnacle of urban sophistication!',
      keywords: [
        'luxury apartments',
        'Dublin',
        'high-end living',
        'A-rated BER',
        'premium properties'
      ]
    }
  },
  {
    path: '/affordable-homes-limerick',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Limerick',
          listingIntent: 'sale',
          maxPrice: 250000,
          propertyType: 'House'
        }}
      />
    ),
    seo: {
      title: 'Affordable Homes for Sale in Limerick | Budget-Friendly Houses',
      description:
        'Find affordable houses for sale in Limerick. Explore budget-friendly homes under €250,000, ideal for first-time buyers or investors. Your affordable Limerick home awaits!',
      keywords: [
        'affordable homes',
        'Limerick',
        'budget-friendly houses',
        'houses for sale',
        'first-time buyers'
      ]
    }
  },
  {
    path: '/short-term-rentals-galway',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Galway',
          listingIntent: 'rent',
          availableFrom: new Date(),
          maxPrice: 2000
        }}
      />
    ),
    seo: {
      title: 'Short-Term Rentals in Galway | Flexible Accommodation',
      description:
        'Discover short-term rentals in Galway. Find flexible, fully-furnished accommodation for your temporary stay. Perfect for vacations, work trips, or transitional periods.',
      keywords: [
        'short-term rentals',
        'Galway',
        'temporary accommodation',
        'furnished rentals',
        'flexible stays'
      ]
    }
  },
  {
    path: '/pet-friendly-rentals-dublin',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Dublin',
          listingIntent: 'rent',
          propertyType: 'Any'
        }}
      />
    ),
    seo: {
      title: 'Pet-Friendly Rentals in Dublin | Animal-Loving Homes',
      description:
        'Find pet-friendly rentals in Dublin. Discover apartments and houses that welcome your furry friends. Perfect for animal lovers seeking a home for the whole family.',
      keywords: [
        'pet-friendly rentals',
        'Dublin',
        'animal-friendly homes',
        'dog-friendly apartments',
        'cat-friendly houses'
      ]
    }
  },
  {
    path: '/waterfront-properties-wexford',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Wexford',
          listingIntent: 'sale',
          propertyType: 'Any'
        }}
      />
    ),
    seo: {
      title: 'Waterfront Properties for Sale in Wexford | Coastal Living',
      description:
        'Explore stunning waterfront properties for sale in Wexford. Find your dream coastal home with breathtaking views and direct water access. Experience seaside living at its finest.',
      keywords: [
        'waterfront properties',
        'Wexford',
        'coastal homes',
        'seaside living',
        'beach houses'
      ]
    }
  },
  {
    path: '/remote-work-friendly-rentals-galway',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Galway',
          listingIntent: 'rent',
          propertyType: 'Any'
        }}
      />
    ),
    seo: {
      title: 'Remote Work-Friendly Rentals in Galway | Home Office Spaces',
      description:
        'Discover rentals in Galway perfect for remote workers. Find homes with dedicated office spaces or extra rooms ideal for setting up your home workspace. Embrace the work-from-home lifestyle.',
      keywords: [
        'remote work rentals',
        'Galway',
        'home office',
        'work-from-home',
        'telecommuter-friendly'
      ]
    }
  },
  {
    path: '/historic-homes-kilkenny',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Kilkenny',
          listingIntent: 'sale',
          propertyType: 'House'
        }}
      />
    ),
    seo: {
      title: 'Historic Homes for Sale in Kilkenny | Character Properties',
      description:
        "Explore charming historic homes for sale in Kilkenny. Find character-filled properties that blend old-world charm with modern comforts. Own a piece of Kilkenny's rich history.",
      keywords: [
        'historic homes',
        'Kilkenny',
        'character properties',
        'period houses',
        'old-world charm'
      ]
    }
  },
  {
    path: '/first-time-buyer-homes-cork',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Cork',
          listingIntent: 'sale',
          maxPrice: 300000,
          propertyType: 'Any'
        }}
      />
    ),
    seo: {
      title: 'First-Time Buyer Homes in Cork | Affordable Starter Properties',
      description:
        'Find perfect homes for first-time buyers in Cork. Explore affordable properties ideal for getting on the property ladder. Start your homeownership journey in Cork today!',
      keywords: [
        'first-time buyer homes',
        'Cork',
        'affordable properties',
        'starter homes',
        'new homeowner'
      ]
    }
  },
  {
    path: '/luxury-penthouses-dublin',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Dublin',
          listingIntent: 'sale',
          propertyType: 'Apartment',
          minPrice: 750000
        }}
      />
    ),
    seo: {
      title: 'Luxury Penthouses for Sale in Dublin | Exclusive Sky Homes',
      description:
        'Discover opulent penthouses for sale in Dublin. Experience unparalleled luxury with stunning city views, premium amenities, and sophisticated design. Elevate your lifestyle to new heights.',
      keywords: [
        'luxury penthouses',
        'Dublin',
        'sky homes',
        'exclusive apartments',
        'high-end living'
      ]
    }
  },
  {
    path: '/rural-retreats-mayo',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Mayo',
          listingIntent: 'sale',
          propertyType: 'House'
        }}
      />
    ),
    seo: {
      title: 'Rural Retreats for Sale in Mayo | Countryside Escapes',
      description:
        'Find your perfect rural retreat in Mayo. Explore countryside homes offering peace, privacy, and stunning natural surroundings. Escape to your own slice of Irish countryside.',
      keywords: [
        'rural retreats',
        'Mayo',
        'countryside homes',
        'country living',
        'secluded properties'
      ]
    }
  },
  {
    path: '/student-lettings-limerick',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Limerick',
          listingIntent: 'rent',
          maxPrice: 800,
          propertyType: 'Any'
        }}
      />
    ),
    seo: {
      title: 'Student Lettings in Limerick | University Accommodation',
      description:
        'Explore student lettings in Limerick. Find affordable accommodation close to universities and colleges. Perfect for students seeking convenient and budget-friendly living options.',
      keywords: [
        'student lettings',
        'Limerick',
        'university accommodation',
        'college housing',
        'student-friendly rentals'
      ]
    }
  },
  {
    path: '/accessible-homes-dublin',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Dublin',
          listingIntent: 'Any',
          propertyType: 'Any'
        }}
      />
    ),
    seo: {
      title: 'Accessible Homes in Dublin | Mobility-Friendly Properties',
      description:
        'Discover accessible homes in Dublin. Find properties designed for mobility and comfort, featuring amenities like step-free access, wide doorways, and adapted bathrooms. Live independently with ease.',
      keywords: [
        'accessible homes',
        'Dublin',
        'mobility-friendly',
        'adapted properties',
        'disability-friendly housing'
      ]
    }
  },
  {
    path: '/investment-properties-galway',
    element: (
      <BrowseListingsPage
        initialSearchParams={{
          location: 'Galway',
          listingIntent: 'sale',
          propertyType: 'Any'
        }}
      />
    ),
    seo: {
      title:
        'Investment Properties for Sale in Galway | Lucrative Opportunities',
      description:
        'Explore investment properties for sale in Galway. Find lucrative opportunities in residential and commercial real estate. Ideal for investors looking to expand their portfolio in a thriving market.',
      keywords: [
        'investment properties',
        'Galway',
        'real estate investment',
        'rental income',
        'property portfolio'
      ]
    }
  }
];

// Wrap export in Suspense fallback for lazy-loaded components
const marketingRoutesWithSuspense = marketingRoutes.map((route) => ({
  ...route,
  element: (
    <React.Suspense fallback={<div>Loading...</div>}>
      {route.element}
    </React.Suspense>
  ),
}));

export default marketingRoutesWithSuspense;

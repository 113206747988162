import React, { useState } from 'react';
import {
  ArrowRightIcon,
  EyeIcon,
  EyeSlashIcon
} from '@heroicons/react/24/solid';
import { Mail, Phone, BuildingIcon } from 'lucide-react'; // Import Lucide icons
import {
  isLandlord,
  isRenter,
  isAgentOrisLandlord,
  User
} from '../../types/userTypes';
import { Link } from 'react-router-dom';

interface LandlordInformationProps {
  user: User;
}

const LandlordInformation: React.FC<LandlordInformationProps> = ({ user }) => {
  const [isEmailRevealed, setIsEmailRevealed] = useState(false);

  const toggleEmailReveal = () => {
    setIsEmailRevealed(!isEmailRevealed);
  };

  const handleCall = () => {
    window.location.href = `tel:${user.phone}`;
  };

  const handleEmail = () => {
    window.location.href = `mailto:${user.email}`;
  };

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="bg-gray-800 text-white py-3 px-4">
        <h2 className="text-3xl font-bold  ">Listing Owner</h2>
      </div>

      <div className="p-4">
        <div className="flex flex-col items-center mb-4">
          {user.photoUrl ? (
            <img
              src={user.photoUrl}
              alt={user.name}
              className="w-32 h-32 rounded-full object-cover mb-2"
            />
          ) : (
            <div className="w-32 h-32 rounded-full bg-gray-300 flex items-center justify-center mb-2">
              <span className="text-3xl font-bold text-gray-600">
                {user.name.charAt(0).toUpperCase()}
              </span>
            </div>
          )}
          <h3 className="text-lg font-semibold text-gray-800">{user.name}</h3>
          {isLandlord(user) && user.company && (
            <p className="text-gray-600 text-sm mt-1">{user.company}</p>
          )}
          {isRenter(user) && user.bio && (
            <p className="text-gray-600 text-sm mt-1">{user.bio}</p>
          )}
        </div>

        <div className="space-y-3">
          {isAgentOrisLandlord(user) &&
            'psraLicence' in user &&
            user.psraLicence &&
            user.psraLicence.length > 0 && (
              <div className="flex items-center">
                <BuildingIcon className="h-5 w-5 text-gray-500 mr-2" />
                <span className="text-gray-700 text-sm">
                  <b>PSRA LICENCE:</b> {user.psraLicence}
                </span>
              </div>
            )}

          <div className="flex flex-col sm:flex-row gap-2">
            {user.listingPreferences?.showEmail && (
              <button
                onClick={handleEmail}
                className="flex-1 flex items-center justify-center gap-1 bg-blue-500 text-white px-3 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
              >
                <Mail className="w-5 h-5" />
                <span>Email</span>
                {isEmailRevealed ? (
                  <EyeSlashIcon
                    className="w-5 h-5 ml-1 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleEmailReveal();
                    }}
                  />
                ) : (
                  <EyeIcon
                    className="w-5 h-5 ml-1 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleEmailReveal();
                    }}
                  />
                )}
              </button>
            )}

            {user.listingPreferences?.showMobile && (
              <button
                onClick={handleCall}
                className="flex-1 flex items-center justify-center gap-1 bg-green-500 text-white px-3 py-2 rounded-lg hover:bg-green-600 transition-colors duration-300"
              >
                <Phone className="w-5 h-5" />
                <span>Call</span>
              </button>
            )}
          </div>

          {user.listingPreferences?.showEmail && (
            <div
              className={`text-center text-sm transition-all duration-300 ${
                isEmailRevealed ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <span className="text-gray-600">
                {isEmailRevealed ? user.email : ''}
              </span>
            </div>
          )}
        </div>

        <div className="mt-4">
          <Link
            to={`/property-owner-listings/${user.id}`}
            className="flex items-center justify-center bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-4 py-2 rounded-md font-medium hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-0.5"
          >
            View All Listings
            <ArrowRightIcon className="w-5 h-5 ml-1" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandlordInformation;

import { getValue, fetchAndActivate } from 'firebase/remote-config';
import { firebaseRemoteConfigApp } from '../auth/firebaseConfig';

// Define the structure of your remote config
interface RemoteConfigParams {
  isAttachmentEnabledInChat: boolean;
  welcomeMessage: string;
  maxListingsPerUser: number;
  isComingSoonEnabled: boolean;
  isFeedbackEnabled: boolean;
  requireEmailVerificationToCreateListing: boolean;
  requireIdentityVerification: boolean;
  isGroupedListingEnabled: boolean;
  isAutoApplyEnabled: boolean;

  // Add more config parameters as needed
}

type RemoteConfigKey =
  | 'isAttachmentEnabledInChat'
  | 'welcomeMessage'
  | 'isComingSoonEnabled'
  | 'maxListingsPerUser'
  | 'isFeedbackEnabled'
  | 'featuredListingIds'
  | 'requireEmailVerificationToCreateListing'
  | 'isGroupedListingEnabled'
  | 'isAutoApplyEnabled'
  | 'requireIdentityVerification'

const configDefaults: { [key in RemoteConfigKey]: string | number | boolean } =
  {
    isAttachmentEnabledInChat: false,
    welcomeMessage: 'Welcome to RentSmart.ie!',
    maxListingsPerUser: 10,
    isComingSoonEnabled: true,
    requireEmailVerificationToCreateListing: false,
    requireIdentityVerification: false,
    isGroupedListingEnabled: false,
    isFeedbackEnabled: true,
    isAutoApplyEnabled: true,
    featuredListingIds: '[]' // Store array as JSON string
    // Set more default values here
  };

class RemoteConfigHelper {
  private static instance: RemoteConfigHelper;
  private remoteConfig = firebaseRemoteConfigApp;

  private constructor() {
    this.remoteConfig.defaultConfig = configDefaults;
    this.remoteConfig.settings = {
      fetchTimeoutMillis: 60000, // 1 minute
      minimumFetchIntervalMillis: 3600000 // 1 hour in production
    };
    this.initializeRemoteConfig();
  }

  public static getInstance(): RemoteConfigHelper {
    if (!RemoteConfigHelper.instance) {
      RemoteConfigHelper.instance = new RemoteConfigHelper();
    }
    return RemoteConfigHelper.instance;
  }

  public async initializeRemoteConfig(): Promise<void> {
    try {
      await fetchAndActivate(this.remoteConfig);
    } catch (error) {}
  }

  // Boolean config accessors
  public isAttachmentEnabledInChat(): boolean {
    return this.getBooleanValue('isAttachmentEnabledInChat');
  }

  public requireEmailVerificationToCreateListing(): boolean {
    return this.getBooleanValue('requireEmailVerificationToCreateListing');
  }

  public requireIdentityVerification(): boolean {
    return this.getBooleanValue('requireIdentityVerification');
  }

  public isGroupedListingEnabled(): boolean {
    return this.getBooleanValue('isGroupedListingEnabled');
  }

  public isComingSoonEnabled(): boolean {
    return this.getBooleanValue('isComingSoonEnabled');
  }

  public isFeedbackEnabled(): boolean {
    return this.getBooleanValue('isFeedbackEnabled');
  }

  public isAutoApplyEnabled(): boolean {
    return this.getBooleanValue('isAutoApplyEnabled');
  }

  // String config accessors
  public getWelcomeMessage(): string {
    return this.getStringValue('welcomeMessage');
  }

  // Number config accessors
  public getMaxListingsPerUser(): number {
    return this.getNumberValue('maxListingsPerUser');
  }

  // Generic accessors
  private getBooleanValue(key: keyof RemoteConfigParams): boolean {
    return getValue(this.remoteConfig, key).asBoolean();
  }

  private getStringValue(key: keyof RemoteConfigParams): string {
    return getValue(this.remoteConfig, key).asString();
  }

  private getNumberValue(key: keyof RemoteConfigParams): number {
    return getValue(this.remoteConfig, key).asNumber();
  }

  private getArrayValue(key: keyof RemoteConfigParams): string[] {
    const value = getValue(this.remoteConfig, key).asString();
    return value ? JSON.parse(value) : [];
  }
}

export const remoteConfig = RemoteConfigHelper.getInstance();

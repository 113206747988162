import React, { useState, useEffect } from 'react';
import {
  Share2,
  Clock,
  Bell,
  CheckCircle,
  Calendar,
  Home,
  Lock,
  Loader,
  Bath,
  BedDouble,
  Car,
  Dog,
  EuroIcon,
  Save
} from 'lucide-react';
import { Timestamp } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { useAuth } from '../../auth/firebaseAuth';
import {
  AutoApplyStatus,
  AutoApplyUser,
  fetchAutoApplyData,
  getDocumentById,
  updateDocument
} from '../../utility/firebaseHelpers';
import AutoApplyUpsell from '../auto-apply-upsell/AutoApplyUpsell';
import { useNavigate } from 'react-router-dom';
import { remoteConfig } from '../../utility/firebaseRemoteConfigHelper';
import LocationSelector from '../location-selector/LocationSelector';

interface AutoApplyPreferences {
  maxPrice: number;
  minPrice: number;
  locations: string[];
  propertyTypes: string[];
  minBedrooms: number;
  minBathrooms: number;
  mustHaveParking: boolean;
  petFriendly: boolean;
  availableFrom: Date | null;
  keywords: string[];
}

type AvailableFromType = Date | Timestamp | null;

const toISOStringDate = (date: AvailableFromType) => {
  if (!date) return '';
  return date instanceof Timestamp
    ? date.toDate().toISOString().split('T')[0]
    : date.toISOString().split('T')[0];
};

const AutoApplyDashboard: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [autoApplyStatus, setAutoApplyStatus] =
    useState<AutoApplyStatus | null>(null);
  const [autoApplyUser, setAutoApplyUser] = useState<AutoApplyUser | null>(
    null
  );
  const [preferences, setPreferences] = useState<AutoApplyPreferences>({
    maxPrice: 2000,
    minPrice: 1000,
    locations: [],
    propertyTypes: [],
    minBedrooms: 1,
    minBathrooms: 1,
    mustHaveParking: false,
    petFriendly: false,
    availableFrom: null,
    keywords: []
  });

  // Fetch Auto Apply data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch status data
        if (currentUser) {
          // Fetch user data
          const userData = await getDocumentById<AutoApplyUser>(
            'autoApplyUsers',
            currentUser.uid
          );
          setAutoApplyUser(userData);

          const status = await fetchAutoApplyData(userData);
          setAutoApplyStatus(status);

          // Set preferences if available
          if (status.preferences) {
            setPreferences(status.preferences);
          }
        }
      } catch (error) {
        console.error('Error fetching Auto Apply data:', error);
        toast.error('Failed to load Auto Apply data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  // Check if feature is currently active
  // Helper functions
  const isFeatureActive = () => {
    return autoApplyStatus?.isFeatureEnabledByUser ?? false;
  };

  const getRemainingDays = () => {
    return autoApplyStatus?.expiryDate
      ? Math.max(
          0,
          Math.ceil(
            (autoApplyStatus.expiryDate.getTime() - new Date().getTime()) /
              (1000 * 60 * 60 * 24)
          )
        )
      : 0;
  };

  const getSharesNeeded = () => {
    return autoApplyStatus?.sharesNeeded ?? 2;
  };

  const handlePreferencesUpdate = async (
    newPrefs: Partial<AutoApplyPreferences>
  ) => {
    if (!currentUser || !autoApplyStatus) return;

    try {
      const updatedPreferences = { ...preferences, ...newPrefs };
      await updateDocument('autoApplyUsers', currentUser.uid, {
        preferences: updatedPreferences,
        lastUpdated: Timestamp.now()
      });

      setPreferences(updatedPreferences);
      toast.success('Preferences updated successfully');
    } catch (error) {
      console.error('Error updating preferences:', error);
      toast.error('Failed to update preferences');
    }
  };

  const handleAutoApplyToggle = async () => {
    if (!currentUser || !autoApplyUser) return;

    try {
      // Toggle `isEnabled` in Firebase
      const newStatus = !autoApplyUser.isEnabled;
      await updateDocument('autoApplyUsers', currentUser.uid, {
        isEnabled: newStatus,
        lastUpdated: Timestamp.now()
      });

      // Update local state
      setAutoApplyUser((prev) =>
        prev ? { ...prev, isEnabled: newStatus } : null
      );
      setAutoApplyStatus((prev) =>
        prev ? { ...prev, isFeatureEnabledByUser: newStatus } : null
      );

      toast.success(
        newStatus
          ? 'Auto Apply has been enabled'
          : 'Auto Apply has been disabled'
      );
    } catch (error) {
      console.error('Error toggling Auto Apply:', error);
      toast.error('Failed to update Auto Apply status');
    }
  };

  if (!remoteConfig.isAutoApplyEnabled()) navigate('/');

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-blue-600" role="status" />
      </div>
    );
  }

  if (!currentUser) return <AutoApplyUpsell />;

  return (
    <div className="min-h-screen py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Premium Feature Banner */}
        <div className="bg-gradient-to-r from-blue-600 to-indigo-600 rounded-2xl overflow-hidden shadow-xl mb-8">
          <div className="p-8 sm:p-12">
            <div className="flex items-start justify-between">
              <div>
                <h1 className="text-3xl font-bold text-white mb-4">
                  Auto Apply
                </h1>
                <p className="text-blue-100 text-lg mb-6">
                  Let's do all the work for you and apply to properties for you
                </p>
                <div className="flex items-center space-x-2 text-blue-100">
                  <Clock size={20} />
                  <span>
                    {isFeatureActive()
                      ? `${getRemainingDays()} days remaining`
                      : getSharesNeeded() > 0
                        ? `Share ${getSharesNeeded()} more ${
                            getSharesNeeded() === 1 ? 'property' : 'properties'
                          } with your friends to ${autoApplyStatus?.expiryDate ? 'reactivate' : 'activate'}`
                        : 'Feature not active'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Status section */}
        <div className="bg-white rounded-xl shadow-md p-6 mb-8">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-4 sm:space-y-0">
            {/* Left side with status icon and text */}
            <div className="flex items-center space-x-4">
              <div
                className={`p-3 rounded-full ${
                  isFeatureActive()
                    ? 'bg-green-100 text-green-600'
                    : 'bg-gray-100 text-gray-600'
                }`}
              >
                <Share2 size={24} />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  Activation Status
                </h2>
                <p className="text-gray-600">
                  {getSharesNeeded() > 0
                    ? `Share ${getSharesNeeded()} more ${
                        getSharesNeeded() === 1 ? 'property' : 'properties'
                      } to ${autoApplyUser?.expiresAt ? 'reactivate' : 'activate'}`
                    : isFeatureActive()
                      ? 'Auto Apply is active'
                      : 'Auto Apply is inactive'}
                </p>
              </div>
            </div>

            {/* Right side with stats and toggle button */}
            <div className="flex flex-col sm:flex-row items-start sm:items-center sm:space-x-6 space-y-4 sm:space-y-0">
              {/* Stats */}
              <div className="text-left sm:text-right w-full sm:w-auto">
                <p className="text-sm text-gray-600">Properties Shared</p>
                <p className="text-2xl font-bold text-blue-600">
                  {autoApplyStatus?.shareCount ?? 0}/
                  {autoApplyUser?.expiresAt ? '4' : '2'}
                </p>
              </div>

              {/* Toggle Button */}
              {getSharesNeeded() === 0 && (
                <button
                  onClick={handleAutoApplyToggle}
                  className={`flex items-center justify-center px-4 py-2 rounded-lg font-medium transition-all duration-200 w-full sm:w-auto ${
                    isFeatureActive()
                      ? 'bg-red-100 text-red-600 hover:bg-red-200'
                      : 'bg-green-100 text-green-600 hover:bg-green-200'
                  }`}
                >
                  <span className="mr-2">
                    {isFeatureActive() ? 'Disable' : 'Enable'}
                  </span>
                  {isFeatureActive() ? (
                    <Lock className="w-4 h-4" />
                  ) : (
                    <CheckCircle className="w-4 h-4" />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white rounded-xl shadow-md p-6">
            <div className="flex items-center space-x-4">
              <div className="p-3 rounded-full bg-blue-100 text-blue-600">
                <CheckCircle size={24} />
              </div>
              <div>
                <p className="text-gray-600">Total Property Applications</p>
                <p className="text-2xl font-bold text-gray-900">
                  {autoApplyStatus?.stats.totalApplications ?? 0}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-md p-6">
            <div className="flex items-center space-x-4">
              <div className="p-3 rounded-full bg-yellow-100 text-yellow-600">
                <Clock size={24} />
              </div>
              <div>
                <p className="text-gray-600">Time Remaining</p>
                <p className="text-2xl font-bold text-gray-900">
                  {getRemainingDays()} days
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Conditional Preferences Form */}
        {isFeatureActive() || getSharesNeeded() === 0 ? (
          <div className="bg-white rounded-2xl shadow-lg p-8 mb-8">
            {/* Header with Save Button */}
            <div className="flex items-center justify-between mb-8">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">
                  Auto Apply Preferences
                </h2>
                <p className="text-gray-500 mt-1">
                  Customize how Auto Apply searches for your perfect home
                </p>
              </div>
              <button
                onClick={() => handlePreferencesUpdate(preferences)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all"
              >
                <Save className="w-4 h-4 mr-2" /> Save Changes
              </button>
            </div>

            <div className="space-y-8">
              {/* Price Range Section */}
              <div className="bg-gray-50 p-6 rounded-xl">
                <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                  <EuroIcon className="w-5 h-5 mr-2 text-blue-600" /> Budget
                  Range
                </h3>
                <div className="flex items-center space-x-4">
                  <div className="relative flex-1">
                    <span className="absolute inset-y-0 left-3 flex items-center text-gray-500">
                      €
                    </span>
                    <input
                      type="number"
                      placeholder="Min"
                      value={preferences.minPrice}
                      onChange={(e) =>
                        handlePreferencesUpdate({
                          minPrice: parseInt(e.target.value)
                        })
                      }
                      className="pl-8 w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                  <span className="text-gray-400">to</span>
                  <div className="relative flex-1">
                    <span className="absolute inset-y-0 left-3 flex items-center text-gray-500">
                      €
                    </span>
                    <input
                      type="number"
                      placeholder="Max"
                      value={preferences.maxPrice}
                      onChange={(e) =>
                        handlePreferencesUpdate({
                          maxPrice: parseInt(e.target.value)
                        })
                      }
                      className="pl-8 w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                </div>
              </div>

              {/* Property Details Section */}
              <div className="bg-gray-50 p-6 rounded-xl">
                <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                  <Home className="w-5 h-5 mr-2 text-blue-600" /> Property
                  Details
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <LocationSelector
                    selectedLocations={preferences.locations}
                    onLocationsChange={(locations) =>
                      handlePreferencesUpdate({ locations })
                    }
                  />

                  {/* Property Types */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
                      <Home className="w-4 h-4 mr-2" /> Property Types
                    </label>
                    <div className="space-y-2">
                      {['Apartment', 'Studio', 'Duplex', 'Townhouse'].map(
                        (type) => (
                          <label
                            key={type}
                            className="flex items-center p-3 bg-white rounded-lg border border-gray-200 hover:bg-blue-50 transition-colors cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              checked={preferences.propertyTypes.includes(type)}
                              onChange={(e) => {
                                const types = e.target.checked
                                  ? [...preferences.propertyTypes, type]
                                  : preferences.propertyTypes.filter(
                                      (t) => t !== type
                                    );
                                handlePreferencesUpdate({
                                  propertyTypes: types
                                });
                              }}
                              className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <span className="ml-2 text-gray-700">{type}</span>
                          </label>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Requirements Section */}
              <div className="bg-gray-50 p-6 rounded-xl">
                <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                  <BedDouble className="w-5 h-5 mr-2 text-blue-600" />{' '}
                  Requirements
                </h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Rooms */}
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-4 rounded-lg border border-gray-200">
                      <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
                        <BedDouble className="w-4 h-4 mr-2" /> Min Bedrooms
                      </label>
                      <select
                        value={preferences.minBedrooms}
                        onChange={(e) =>
                          handlePreferencesUpdate({
                            minBedrooms: parseInt(e.target.value)
                          })
                        }
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        {[1, 2, 3, 4, 5].map((num) => (
                          <option key={num} value={num}>
                            {num}+ Beds
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="bg-white p-4 rounded-lg border border-gray-200">
                      <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
                        <Bath className="w-4 h-4 mr-2" /> Min Bathrooms
                      </label>
                      <select
                        value={preferences.minBathrooms}
                        onChange={(e) =>
                          handlePreferencesUpdate({
                            minBathrooms: parseInt(e.target.value)
                          })
                        }
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        {[1, 2, 3].map((num) => (
                          <option key={num} value={num}>
                            {num}+ Baths
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Additional Features */}
                  <div className="space-y-3">
                    <label className="block text-sm font-medium text-gray-700 mb-3 flex items-center">
                      Must Have Features
                    </label>
                    <div className="bg-white p-4 rounded-lg border border-gray-200 space-y-3">
                      <label className="flex items-center hover:bg-gray-50 p-2 rounded-md transition-colors cursor-pointer">
                        <Car className="w-4 h-4 mr-2" />
                        <input
                          type="checkbox"
                          checked={preferences.mustHaveParking}
                          onChange={(e) =>
                            handlePreferencesUpdate({
                              mustHaveParking: e.target.checked
                            })
                          }
                          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-gray-700">
                          Parking Required
                        </span>
                      </label>
                      <label className="flex items-center hover:bg-gray-50 p-2 rounded-md transition-colors cursor-pointer">
                        <Dog className="w-4 h-4 mr-2" />
                        <input
                          type="checkbox"
                          checked={preferences.petFriendly}
                          onChange={(e) =>
                            handlePreferencesUpdate({
                              petFriendly: e.target.checked
                            })
                          }
                          className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-gray-700">Pet Friendly</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Available From Section */}
              <div className="bg-gray-50 p-6 rounded-xl">
                <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                  <Calendar className="w-5 h-5 mr-2 text-blue-600" />{' '}
                  Availability
                </h3>
                <div className="max-w-md">
                  <label className="block text-sm font-medium text-gray-700 mb-2 flex items-center">
                    <Calendar className="w-4 h-4 mr-2" /> Available From
                  </label>
                  <input
                    type="date"
                    value={toISOStringDate(preferences.availableFrom)}
                    onChange={(e) =>
                      handlePreferencesUpdate({
                        availableFrom: e.target.value
                          ? new Date(e.target.value)
                          : null
                      })
                    }
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    min={new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-gray-50 rounded-xl p-6 text-center">
            <Lock className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              Share Properties to Unlock
            </h3>
            <p className="text-gray-600">
              Share {getSharesNeeded()} more{' '}
              {getSharesNeeded() === 1 ? 'property' : 'properties'} to access
              Auto Apply preferences and features.
            </p>
          </div>
        )}

        {/* Email Preferences Notice */}
        <div className="bg-blue-50 rounded-xl p-6">
          <div className="flex items-start space-x-4">
            <Bell className="text-blue-600 w-6 h-6 flex-shrink-0 mt-1" />
            <div>
              <h3 className="font-semibold text-blue-900">
                Email Notifications
              </h3>
              <p className="text-blue-700 mt-1">
                You'll receive email updates only when Auto Apply submits an
                application on your behalf. You can disable these notifications
                at any time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoApplyDashboard;

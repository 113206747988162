// src/hooks/useAnalyticsWithConsent.ts
import { useEffect } from 'react';
import {
  analyticsService,
  AnalyticsEvents,
  ValidEventName
} from '../analytics/firebaseAnalytics';
import { setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { getAnalytics } from 'firebase/analytics';
import { User } from 'firebase/auth';
import { useCookieConsent } from '../context/CookieConsentContext';

// Enhanced interfaces
interface PropertyData {
  type: string;
  price: number;
  location: string;
  images?: string[];
  amenities?: string[];
  virtualTourUrl?: string;
  [key: string]: any;
}

interface PropertyEngagement {
  viewDuration: number;
  scrollDepth: number;
  interactionCount: number;
  inquirySent: boolean;
  sectionViewTimes?: Record<string, number>;
}

interface SearchParams {
  query?: string;
  filters?: Record<string, any>;
  resultsCount?: number;
  [key: string]: any;
}

interface ErrorContext {
  component?: string;
  action?: string;
  timestamp?: string;
  [key: string]: any;
}

interface PerformanceMetrics {
  loadTime: number;
  renderTime: number;
  resourcesLoaded: number;
  timeToInteractive?: number;
}

export interface AnalyticsHookReturn {
  logEvent: (eventName: ValidEventName, params?: Record<string, any>) => void;
  trackPageView: (pageName: string, params?: Record<string, any>) => void;
  trackUserAction: (
    action: string,
    category: string,
    label?: string,
    value?: number
  ) => void;
  trackPropertyView: (propertyId: number, propertyData: PropertyData) => void;
  trackPropertySearch: (searchParams: SearchParams) => void;
  trackError: (
    errorCode: string,
    errorMessage: string,
    errorContext?: ErrorContext
  ) => void;
  initializeUser: (user: User) => void;
  // New tracking methods
  trackPropertyEngagement: (
    propertyId: number,
    engagement: PropertyEngagement
  ) => void;
  trackContentEffectiveness: (
    propertyId: number,
    contentMetrics: Record<string, any>
  ) => void;
  trackPerformance: (pageId: string, metrics: PerformanceMetrics) => void;
  trackInteraction: (
    interactionType: string,
    context: Record<string, any>
  ) => void;
  isEnabled: boolean;
}

export const useAnalyticsWithConsent = (): AnalyticsHookReturn => {
  const { hasAnalyticsConsent } = useCookieConsent();
  const analytics = analyticsService;
  const isEnabled = hasAnalyticsConsent();

  useEffect(() => {
    try {
      const analyticsInstance = getAnalytics();
      setAnalyticsCollectionEnabled(analyticsInstance, isEnabled);
    } catch (error) {
      console.error('Error configuring analytics:', error);
    }
  }, [isEnabled]);

  const safelyTrack = <T extends any[]>(
    trackingFunction: (...args: T) => void,
    ...args: T
  ) => {
    if (isEnabled) {
      try {
        trackingFunction.apply(analytics, args);
      } catch (error) {
        console.error('Error tracking analytics:', error);
      }
    }
  };

  return {
    // Existing methods
    logEvent: (eventName, params) =>
      safelyTrack(analytics.logEvent.bind(analytics), eventName, params),

    trackPageView: (pageName, params) =>
      safelyTrack(analytics.trackPageView.bind(analytics), pageName, params),

    trackUserAction: (action, category, label, value) =>
      safelyTrack(
        analytics.trackUserAction.bind(analytics),
        action,
        category,
        label,
        value
      ),

    trackPropertyView: (propertyId, propertyData) =>
      safelyTrack(analytics.trackPropertyView.bind(analytics), propertyId, {
        ...propertyData,
        timestamp: new Date().toISOString(),
        price_range: getPriceRange(propertyData.price),
        has_virtual_tour: !!propertyData.virtualTourUrl,
        image_count: propertyData.images?.length || 0,
        amenities_count: propertyData.amenities?.length || 0
      }),

    trackPropertySearch: (searchParams) =>
      safelyTrack(analytics.trackPropertySearch.bind(analytics), {
        ...searchParams,
        timestamp: new Date().toISOString()
      }),

    trackError: (errorCode, errorMessage, errorContext) =>
      safelyTrack(
        analytics.trackError.bind(analytics),
        errorCode,
        errorMessage,
        {
          ...errorContext,
          timestamp: new Date().toISOString()
        }
      ),

    initializeUser: (user) =>
      safelyTrack(analytics.initializeUser.bind(analytics), user),

    // New enhanced strategies tracking methods for platform improvements
    trackPropertyEngagement: (propertyId, engagement) =>
      safelyTrack(
        analytics.logEvent.bind(analytics),
        AnalyticsEvents.PROPERTY_ENGAGEMENT,
        {
          property_id: propertyId,
          ...engagement,
          timestamp: new Date().toISOString()
        }
      ),

    trackContentEffectiveness: (propertyId, contentMetrics) =>
      safelyTrack(
        analytics.logEvent.bind(analytics),
        AnalyticsEvents.CONTENT_ENGAGEMENT,
        {
          property_id: propertyId,
          ...contentMetrics,
          timestamp: new Date().toISOString()
        }
      ),

    trackPerformance: (pageId, metrics) =>
      safelyTrack(
        analytics.logEvent.bind(analytics),
        AnalyticsEvents.PERFORMANCE_METRICS,
        {
          page_id: pageId,
          ...metrics,
          timestamp: new Date().toISOString()
        }
      ),

    trackInteraction: (interactionType, context) =>
      safelyTrack(
        analytics.logEvent.bind(analytics),
        AnalyticsEvents.USER_INTERACTION,
        {
          interaction_type: interactionType,
          ...context,
          timestamp: new Date().toISOString()
        }
      ),

    isEnabled
  };
};

// Helper functions
const getPriceRange = (price: number): string => {
  if (price < 1000) return 'low';
  if (price < 2000) return 'medium';
  return 'high';
};

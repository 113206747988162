import React from 'react';
import {
  Star,
  Clock,
  Home,
  Building,
  TrendingUp,
  Heart,
  Key,
  Shield,
  GraduationCap,
  Laptop,
  Stethoscope,
  Briefcase,
  Building2,
  BarChart,
  Home as HomeIcon,
  LineChart,
  Wallet
} from 'lucide-react';

interface SocialProofProps {
  userType: 'renter' | 'agent' | 'landlord';
}

const socialProofData = {
  renter: {
    stats: [
      { value: '15,000+', label: 'Active Listings', icon: Home },
      { value: '48 Hours', label: 'Average Search Time', icon: Clock },
      { value: '98%', label: 'Search Success Rate', icon: TrendingUp }
    ],
    testimonials: [
      {
        name: 'Sarah Mitchell',
        role: 'Student at UCD',
        text: 'Found my dream apartment in Dublin within a week! The filtering options made it so easy to find exactly what I was looking for.',
        rating: 5,
        icon: GraduationCap
      },
      {
        name: "James O'Connor",
        role: 'Software Engineer',
        text: 'The instant alerts helped me be first to view a property that perfectly matched my criteria. Signed the lease the same day!',
        rating: 5,
        icon: Laptop
      },
      {
        name: 'Maria Garcia',
        role: 'Healthcare Professional',
        text: 'After searching for months on other platforms, I found my perfect home through RentSmart in just two weeks.',
        rating: 5,
        icon: Stethoscope
      }
    ]
  },
  agent: {
    stats: [
      { value: '€2.5M+', label: 'Monthly Transactions', icon: TrendingUp },
      { value: '2,500+', label: 'Properties Managed', icon: Building },
      { value: '99%', label: 'Client Satisfaction', icon: Heart }
    ],
    testimonials: [
      {
        name: 'Michael Sullivan',
        role: 'Senior Property Agent',
        text: 'RentSmart has revolutionized how we manage listings. The automated showing scheduler saves us countless hours every week.',
        rating: 5,
        icon: Briefcase
      },
      {
        name: 'Emma Walsh',
        role: 'Real Estate Agent',
        text: "The quality of leads we get through RentSmart is exceptional. The platform's verification process ensures we only deal with serious inquiries.",
        rating: 5,
        icon: Building2
      },
      {
        name: 'David Chen',
        role: 'Property Manager',
        text: "The analytics dashboard helps us make data-driven decisions. We've increased our occupancy rates by 25% since using RentSmart.",
        rating: 5,
        icon: BarChart
      }
    ]
  },
  landlord: {
    stats: [
      { value: '72 Hours', label: 'Average Listing Time', icon: Clock },
      { value: '95%', label: 'Occupancy Rate', icon: Key },
      { value: '100%', label: 'Verified Tenants', icon: Shield }
    ],
    testimonials: [
      {
        name: 'Paul Murphy',
        role: 'Property Owner',
        text: 'Listed my property and had quality tenants within 48 hours. The tenant verification process gives me peace of mind.',
        rating: 5,
        icon: HomeIcon
      },
      {
        name: 'Lisa Kennedy',
        role: 'Investment Property Owner',
        text: "Managing multiple properties used to be a headache. RentSmart's landlord dashboard makes it effortless.",
        rating: 5,
        icon: LineChart
      },
      {
        name: 'Thomas Byrne',
        role: 'Property Investor',
        text: 'The messaging system streamlines communication with potential tenants. I love how organized and efficient the whole process is.',
        rating: 5,
        icon: Wallet
      }
    ]
  }
};

const SocialProof: React.FC<SocialProofProps> = ({ userType }) => {
  const data = socialProofData[userType];

  return (
    <div className="w-full bg-white">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        {/* Stats Section
        <div className="mb-16">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {data.stats.map((stat, index) => {
              const Icon = stat.icon;
              return (
                <div
                  key={index}
                  className="bg-white overflow-hidden rounded-lg shadow-lg transition-all hover:shadow-xl"
                >
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center justify-center mb-4">
                      <div className="p-3 rounded-full bg-blue-100 text-blue-600">
                        <Icon size={24} />
                      </div>
                    </div>
                    <div className="text-center">
                      <dt className="text-3xl font-bold text-blue-600 mb-2">
                        {stat.value}
                      </dt>
                      <dd className="text-sm text-gray-600 font-medium">
                        {stat.label}
                      </dd>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        {/* Testimonials Section */}
        <div>
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            What Our {userType.charAt(0).toUpperCase() + userType.slice(1)}s Say
          </h2>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {data.testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg p-6 transition-all hover:shadow-xl"
              >
                <div className="flex items-center mb-4">
                  <div className="h-10 w-10 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center">
                    <testimonial.icon size={20} />
                  </div>
                  <div className="ml-4">
                    <div className="text-lg font-semibold text-gray-900">
                      {testimonial.name}
                    </div>
                    <div className="text-sm text-gray-600">
                      {testimonial.role}
                    </div>
                  </div>
                </div>
                <div className="mb-4 flex">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <Star
                      key={i}
                      size={20}
                      className="text-yellow-400 fill-current"
                    />
                  ))}
                </div>
                <p className="text-gray-600 italic">"{testimonial.text}"</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialProof;

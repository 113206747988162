export function detectDiscriminatoryContent(description: string): string[] {
  // Expanded list of potentially discriminatory keywords/phrases
  const discriminatoryKeywords = [
    'no immigrants',
    'no foreigners',
    'no children',
    'no single mothers',
    'no students',
    'only males',
    'only females',
    'no LGBTQ',
    'no same-sex couples',
    'only white',
    'only certain race',
    'no disabilities',
    'no wheelchair access',
    'must be employed',
    'no welfare',
    'no DSS',
    'no section 8',
    'no housing benefit',
    'only specific religion',
    'christians only',
    'no muslims',
    'no jews',
    'no hindu',
    'only straight',
    'no single men',
    'no single women',
    'no large families',
    'single occupancy only',
    'no black',
    'no asian',
    'no hispanic',
    'no minority',
    'caucasians only',
    'no public assistance',
    'proof of citizenship required',
    'native english speakers only',
    'no unemployment',
    'no low-income',
    'must earn 3x rent',
    'must provide employment details',
    'no evictions',
    'must have credit score above',
    'no elderly',
    'no retirees',
    'young professionals only',
    'no smokers',
    'must speak english',
    'no couples',
    'no roommates',
    'no live-in caregivers',
    'no service animals',
    'no guide dogs',
    'requires permanent residency',
    'no non-eu nationals',
    'no visa holders',
    'prefer european',
    'prefer locals',
    'no previous convictions',
    'must show health records',
    'no mental health issues',
    'background check required for certain groups'
  ];

  // Normalize description to lowercase for case-insensitive matching
  const normalizedDescription = description.toLowerCase();

  // Find any discriminatory keywords that are present in the description
  const detectedKeywords = discriminatoryKeywords.filter((keyword) =>
    normalizedDescription.includes(keyword)
  );

  return detectedKeywords;
}

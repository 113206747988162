import React, { useState } from 'react';
import {
  PaperAirplaneIcon,
  PaperClipIcon,
  XCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from '@heroicons/react/24/solid';
import { remoteConfig } from '../../../utility/firebaseRemoteConfigHelper';
import { useAuth } from '../../../auth/firebaseAuth';
import { isRenter } from '../../../types/userTypes';
import { MessageSquare, Info, Mail, PhoneCall, FileText } from 'lucide-react';
import { insertDocument } from '../../../utility/firebaseHelpers';

interface MessageInputProps {
  onSendMessage: (content: string) => void;
  onUploadFile: (file: File) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({
  onSendMessage,
  onUploadFile
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [filePreview, setFilePreview] = useState<{
    file: File;
    previewUrl: string | null;
  } | null>(null);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [reportMessage, setReportMessage] = useState('');
  const [isQuickResponseOpen, setIsQuickResponseOpen] = useState(true); // New state for toggling Quick Responses

  const { userProfile } = useAuth();
  const isUserRenter = isRenter(userProfile);

  // Define Quick Response Templates based on user role
  const renterTemplates = [
    {
      text: 'Hello! Is this property still available?',
      icon: <MessageSquare />
    },
    {
      text: 'Can you provide more details on the property features?',
      icon: <Info />
    },
    { text: 'What is the rental price and deposit required?', icon: <Mail /> },
    {
      text: 'When would it be possible to arrange a viewing?',
      icon: <PhoneCall />
    }
  ];

  const landlordTemplates = [
    {
      text: 'Hello! This property is still available.',
      icon: <MessageSquare />
    },
    {
      text: 'The property includes parking, a gym, and a shared living area.',
      icon: <Info />
    },
    { text: 'The monthly rent is €1000, with a €500 deposit.', icon: <Mail /> },
    { text: 'Viewings are available on weekends.', icon: <PhoneCall /> }
  ];

  const templates = isUserRenter ? renterTemplates : landlordTemplates;

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.trim() || filePreview) {
      onSendMessage(newMessage);
      if (filePreview) {
        onUploadFile(filePreview.file);
      }
      setNewMessage('');
      setFilePreview(null); // Clear preview after sending
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setIsUploading(true);

      // Generate a preview for images
      if (file.type.startsWith('image/')) {
        const previewUrl = URL.createObjectURL(file);
        setFilePreview({ file, previewUrl });
      } else if (file.type === 'application/pdf') {
        setFilePreview({ file, previewUrl: null }); // No preview URL for PDFs
      }

      setIsUploading(false);
    }
  };

  const handleTemplateSelect = (template: string) => {
    setNewMessage(template);
  };

  const handleRemovePreview = () => {
    setFilePreview(null);
  };

  const handleReportSubmit = async () => {
    try {
      await insertDocument('conversationReports', {
        reporterId: userProfile?.id,
        reportMessage,
        timestamp: new Date()
      });
      alert('Report submitted successfully.');
      setReportMessage('');
      setIsReportModalOpen(false);
    } catch (error) {
      console.error('Error submitting report:', error);
      alert('Failed to submit report. Please try again.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSendMessage} className="p-4 border-t bg-white">
        {/* Quick Response Toggle Button */}
        <div className="mb-2 flex items-center justify-between">
          <label className="text-gray-700 font-medium">Quick Responses:</label>
          <button
            type="button"
            onClick={() => setIsQuickResponseOpen(!isQuickResponseOpen)}
            className="text-blue-500 flex items-center"
          >
            {isQuickResponseOpen ? 'Hide' : 'Show'}
            {isQuickResponseOpen ? (
              <ChevronUpIcon className="ml-1 h-4 w-4" />
            ) : (
              <ChevronDownIcon className="ml-1 h-4 w-4" />
            )}
          </button>
        </div>

        {/* Quick Response Templates (collapsible) */}
        {isQuickResponseOpen && (
          <div className="mb-4">
            <div className="flex flex-wrap gap-2">
              {templates.map((template, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleTemplateSelect(template.text)}
                  className="flex items-center gap-2 px-3 py-2 bg-gray-100 border rounded-lg hover:bg-gray-200 transition duration-300"
                >
                  <span className="text-gray-500">{template.icon}</span>
                  <span className="text-sm text-gray-700">{template.text}</span>
                </button>
              ))}
            </div>
          </div>
        )}

        {/* File Preview Section with Information */}
        {filePreview && (
          <div className="mb-4 p-2 bg-gray-100 border rounded-lg">
            <div className="flex items-center justify-between">
              {filePreview.previewUrl ? (
                <img
                  src={filePreview.previewUrl}
                  alt="Preview"
                  className="w-16 h-16 rounded object-cover mr-2"
                />
              ) : (
                <div className="flex items-center">
                  <FileText className="w-10 h-10 text-gray-500 mr-2" />
                  <span className="text-gray-700">{filePreview.file.name}</span>
                </div>
              )}
              <XCircleIcon
                onClick={handleRemovePreview}
                className="h-6 w-6 text-red-500 cursor-pointer hover:text-red-700"
              />
            </div>
            <p className="mt-2 text-sm text-gray-600">
              <strong>Note:</strong> This file is in preview. Click "Send" to
              include it with your message.
            </p>
          </div>
        )}

        {/* Message Input and Send Row */}
        <div className="flex items-center">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="flex-grow border border-gray-300 rounded-full px-4 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 h-12"
            placeholder="Type a message..."
          />

          {remoteConfig.isAttachmentEnabledInChat() && (
            <>
              <label
                htmlFor="file-upload"
                className="ml-2 cursor-pointer flex items-center justify-center h-12 w-12"
              >
                <PaperClipIcon className="h-6 w-6 text-gray-500 hover:text-blue-500" />
              </label>
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                disabled={isUploading}
              />
            </>
          )}

          <button
            type="submit"
            className="ml-2 bg-blue-500 text-white p-3 rounded-full hover:bg-blue-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 flex items-center justify-center h-12 w-12"
            disabled={isUploading}
          >
            <PaperAirplaneIcon className="h-5 w-5 transform rotate-30" />
          </button>
        </div>

        {/* Security Awareness Message */}
        <p className="mt-4 text-xs text-gray-500 text-center">
          Please be security aware.{' '}
          <a
            href="/safety"
            target="_blank"
            className="text-blue-500 hover:underline"
          >
            Read our safety guidelines
          </a>{' '}
          or{' '}
          <span
            className="text-blue-500 hover:underline cursor-pointer"
            onClick={() => setIsReportModalOpen(true)}
          >
            report suspicious messages
          </span>
          .
        </p>
      </form>

      {/* Report Modal */}
      {isReportModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">
              Report Suspicious Message
            </h2>
            <textarea
              className="w-full border border-gray-300 rounded-lg p-2 mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Describe the suspicious activity..."
              value={reportMessage}
              onChange={(e) => setReportMessage(e.target.value)}
            />
            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
                onClick={() => setIsReportModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleReportSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageInput;

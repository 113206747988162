import React from 'react';
import {
  HomeIcon,
  UserGroupIcon,
  LightBulbIcon,
  ShieldCheckIcon
} from '@heroicons/react/24/outline';

const AboutUs: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-4xl font-bold mb-6 text-center">
        About RentSmart.ie
      </h1>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Our Story</h2>
        <p className="mb-4">
          Founded in 2024, RentSmart.ie was born out of a simple idea: to make
          renting in Ireland easier, more transparent, and more efficient for
          both tenants and landlords. We recognized the challenges faced by
          renters in finding suitable homes and the difficulties landlords
          encountered in managing their properties effectively.
        </p>
        <p>
          Our team of real estate experts, technology enthusiasts, and customer
          service professionals came together with a shared vision of
          transforming the rental experience in Ireland. Since our inception,
          we've been committed to leveraging technology to streamline the rental
          process while maintaining a human touch in all our interactions.
        </p>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">Our Mission</h2>
        <div className="bg-blue-100 p-6 rounded-lg">
          <p className="text-lg italic">
            "To create a seamless, trustworthy platform that connects quality
            tenants with responsible landlords, fostering better rental
            experiences across Ireland."
          </p>
        </div>
      </section>

      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">Our Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            {
              icon: HomeIcon,
              title: 'Community',
              description:
                'We believe in building strong, positive rental communities.'
            },
            {
              icon: UserGroupIcon,
              title: 'Fairness',
              description:
                'We strive for fairness and transparency in all our operations.'
            },
            {
              icon: LightBulbIcon,
              title: 'Innovation',
              description:
                'We continuously innovate to improve the rental experience.'
            },
            {
              icon: ShieldCheckIcon,
              title: 'Trust',
              description:
                'We prioritize trust and safety in our platform and services.'
            }
          ].map((value, index) => (
            <div key={index} className="flex items-start">
              <value.icon className="h-8 w-8 text-blue-500 mr-4 flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-2">{value.title}</h3>
                <p>{value.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

          {/* Team section */}
      {/* <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-6">Our Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            {
              name: "Sarah O'Connor",
              role: 'CEO & Co-founder',
              image: 'https://via.placeholder.com/150'
            },
            {
              name: 'Michael Chen',
              role: 'CTO & Co-founder',
              image: 'https://via.placeholder.com/150'
            },
            {
              name: 'Emma Byrne',
              role: 'Head of Customer Relations',
              image: 'https://via.placeholder.com/150'
            }
          ].map((member, index) => (
            <div key={index} className="text-center">
              <img
                src={member.image}
                alt={member.name}
                className="w-32 h-32 rounded-full mx-auto mb-4"
              />
              <h3 className="font-semibold">{member.name}</h3>
              <p className="text-gray-600">{member.role}</p>
            </div>
          ))}
        </div>
      </section> */}

      {/* <section>
        <h2 className="text-2xl font-semibold mb-6">Join Our Journey</h2>
        <p className="mb-4">
          At RentSmart.ie, we're always looking for talented individuals who
          share our passion for improving the rental experience in Ireland.
          Whether you're a tech wizard, a real estate guru, or a customer
          service superstar, we'd love to hear from you.
        </p>
        <p className="mb-4">
          Check out our{' '}
          <a href="/careers" className="text-blue-500 hover:underline">
            Careers page
          </a>{' '}
          for current openings, or reach out to us at{' '}
          <a
            href="mailto:careers@rentsmart.ie"
            className="text-blue-500 hover:underline"
          >
            careers@rentsmart.ie
          </a>{' '}
          to learn more about opportunities at RentSmart.ie.
        </p>
        <p>
          Join us in our mission to make renting smarter, easier, and better for
          everyone in Ireland!
        </p>
      </section> */}
    </div>
  );
};

export default AboutUs;

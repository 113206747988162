// userTypes.ts

import { FieldValue } from 'firebase/firestore';

// Base user interface with common properties
export interface BaseUser {
  id: string;
  name: string;
  email: string;
  accountVerification: {
    emailVerified: boolean;
    identityVerified: boolean;
    phoneVerified: boolean;
  };
  isPlatformAdmin?: boolean;
  phone: string;
  photoUrl?: string;
  accountCreationDate: FieldValue;
  bio: string;
  communicationPreferences: {
    emailOffers: boolean;
    pushNotifications: boolean;
  };
  listingPreferences: {
    showMobile: boolean;
    showEmail: boolean;
  };
  role: 'landlord' | 'renter' | 'agent';
}

// Landlord type
export interface Landlord extends BaseUser {
  role: 'landlord';
  company: string;
  psraLicence?: string;
}
export interface PropertyAgent extends BaseUser {
  role: 'agent';
  company: string;
  psraLicence?: string;
}

// Renter type
export interface Renter extends BaseUser {
  role: 'renter';
  preferredLocation: string;
}

// User type that can be either Landlord, PropertyAgent or Renter
export type User = Landlord | Renter | PropertyAgent;

// Type guard to check if a user is a Landlord
export function isLandlord(user: User | null): user is Landlord {
  if (user == null) return false;
  return user.role === 'landlord';
}

// Type guard to check if a user is a Renter
export function isRenter(user: User | null): user is Renter {
  if (user == null) return false;
  return user.role === 'renter';
}

// Type guard to check if a user is a Landlord or Agent
export function isAgentOrisLandlord(
  user: User | null
): user is Renter | PropertyAgent {
  if (user == null) return false;
  return user.role === 'landlord' || user.role === 'agent';
}

import { FieldValue } from 'firebase/firestore';
import { User } from '../../../types/userTypes';

interface Listing {
  id: number;
  propertyOwnerUid: string;
  title: string;
  price: number;
  size?: number;
  virtualTourUrl?: string;
  location: string;
  type: PropertyType;
  bedrooms: number;
  bathrooms: number;
  images: string[];
  user: User;
  status: Status;
  adType?: AdType;
  dateAdded: string | FieldValue;
  description: string;
  amenities: string[];
  listingIntent: ListingIntent;
  availableFrom: Date;
  expirationDate: Date;
  berRating: string;
  propertyViews: number;
  videoUrl?: string;
}

export type Status = 'active' | 'pending' | 'inactive' | 'all';
export type AdType = 'premium' | 'normal' | 'pro';

export type ListingIntent = 'sale' | 'rent' | 'share';

export const propertyTypes = [
  'Any',
  'Apartment',
  'Room',
  'House',
  'Studio',
  'Duplex',
  'Townhouse'
] as const;

export type PropertyType = (typeof propertyTypes)[number];

export const berRatings = [
  'A1',
  'A2',
  'A3',
  'B1',
  'B2',
  'B3',
  'C1',
  'C2',
  'C3',
  'D1',
  'D2',
  'E1',
  'E2',
  'F',
  'G'
];
export const amenitiesList = [
  'Wi-Fi',
  'Parking',
  'Gym',
  'Pool',
  'Elevator',
  'Furnished',
  'Balcony',
  'Garden',
  'Pet-friendly',
  'Air Conditioning',
  'Washer/Dryer',
  'Dishwasher',
  'Security System',
  'Central Heating',
  'Storage Space'
];

export type Amenity = (typeof amenitiesList)[number];

export function isActiveStatus(status: Status): status is 'active' {
  return status === 'active';
}

export function isPendingStatus(status: Status): status is 'pending' {
  return status === 'pending';
}

export function isInactiveStatus(status: Status): status is 'inactive' {
  return status === 'inactive';
}

// Type Guard for Specific AdType Values
export function isPremiumAd(adType: AdType): adType is 'premium' {
  return adType === 'premium';
}

export function isNormalAd(adType: AdType): adType is 'normal' {
  return adType === 'normal';
}

export function isProAd(adType: AdType): adType is 'pro' {
  return adType === 'pro';
}

export type { Listing };

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  PencilIcon,
  TrashIcon,
  EyeIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/solid';
import Modal from '../../components/modal/modal';
import { useAuth } from '../../auth/firebaseAuth';
import { where, orderBy, Timestamp } from 'firebase/firestore';
import {
  Listing,
  Status
} from '../../components/property/property-card/PropertyCardTypes';
import {
  deleteDocument,
  queryDocuments,
  updateDocument
} from '../../utility/firebaseHelpers';
import { capitalizeWords } from '../../utility/capitalizeWords';

const ITEMS_PER_PAGE = 10;

const MyListingsPage: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [listings, setListings] = useState<Listing[]>([]);
  const [sortField, setSortField] = useState<keyof Listing>('dateAdded');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [filterStatus, setFilterStatus] = useState<Status>('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedListings, setSelectedListings] = useState<number[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [listingToDelete, setListingToDelete] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchListings = async () => {
      if (!currentUser) return;

      setIsLoading(true);
      try {
        const constraints = [
          where('propertyOwnerUid', '==', currentUser.uid),
          orderBy('dateAdded', 'desc') // Initial fetch ordered by date added
        ];
        const fetchedListings = await queryDocuments<Listing>(
          'listings',
          constraints
        );
        setListings(fetchedListings);
      } catch (error) {
        console.error('Error fetching listings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, [currentUser]);

  const handleSort = (field: keyof Listing) => {
    if (field === sortField) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Sort and filter listings on the client side
  const sortedAndFilteredListings = listings
    .filter(
      (listing) => filterStatus === 'all' || listing.status === filterStatus
    )
    .filter(
      (listing) =>
        listing.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listing.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        listing.id.toString().includes(searchTerm)
    )
    .sort((a, b) => {
      const aValue = a[sortField] ?? '';
      const bValue = b[sortField] ?? '';

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

  const paginatedListings = sortedAndFilteredListings.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(
    sortedAndFilteredListings.length / ITEMS_PER_PAGE
  );

  const handleEdit = (id: number) => {
    navigate(`/edit-listing/${id}`);
  };

  const handleDelete = (id: number) => {
    setListingToDelete(id);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    if (listingToDelete) {
      try {
        await deleteDocument('listings', listingToDelete.toString());
        setListings(
          listings.filter((listing) => listing.id !== listingToDelete)
        );
        setIsDeleteModalOpen(false);
        setListingToDelete(null);
      } catch (error) {
        console.error('Error deleting listing:', error);
      }
    }
  };

  const handleBulkStatusUpdate = async (status: Status) => {
    try {
      await Promise.all(
        selectedListings.map((id) =>
          updateDocument('listings', id.toString(), { status })
        )
      );
      setListings((prevListings) =>
        prevListings.map((listing) =>
          selectedListings.includes(listing.id)
            ? { ...listing, status }
            : listing
        )
      );
      setSelectedListings([]); // Clear selected listings after update
    } catch (error) {
      console.error(`Error updating listings status to ${status}:`, error);
    }
  };

  const toggleListingSelection = (id: number) => {
    setSelectedListings((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="py-12">
      <div className="container mx-auto px-4">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-8">
          My Listings
        </h1>
        {listings.length ? (
          <>
            <div className="mb-6 flex flex-wrap justify-between items-center gap-4">
              <div className="flex items-center space-x-4">
                <select
                  id="filterStatus"
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value as Status)}
                  className="border border-gray-300 rounded-lg p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300"
                >
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="inactive">Inactive</option>
                </select>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search listings..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2 pl-10 focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300"
                  />
                  <MagnifyingGlassIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                </div>
              </div>
              <button
                onClick={() => navigate('/add-listing')}
                className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
              >
                Add New Listing
              </button>
            </div>
            {selectedListings.length > 0 && (
              <div className="mb-4 flex gap-2">
                <button
                  onClick={() => handleBulkStatusUpdate('active')}
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-300"
                >
                  Set Active
                </button>
                <button
                  onClick={() => handleBulkStatusUpdate('inactive')}
                  className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600 transition duration-300"
                >
                  Set Inactive
                </button>
              </div>
            )}
            <div className="overflow-x-auto bg-white shadow-md rounded-lg">
              <table className="min-w-full table-auto">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="px-4 py-3">
                      <input
                        type="checkbox"
                        checked={
                          selectedListings.length ===
                            paginatedListings.length &&
                          paginatedListings.length > 0
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedListings(
                              paginatedListings.map((l) => l.id)
                            );
                          } else {
                            setSelectedListings([]);
                          }
                        }}
                      />
                    </th>
                    {[
                      { name: 'ID', key: 'id' },
                      { name: 'Title', key: 'title' },
                      { name: 'Price', key: 'price' },
                      { name: 'Location', key: 'location' },
                      { name: 'Type', key: 'type' },
                      { name: 'Bedrooms', key: 'bedrooms' },
                      { name: 'Bathrooms', key: 'bathrooms' },
                      { name: 'Status', key: 'status' },
                      { name: 'Date Added', key: 'dateAdded' },
                      { name: 'Views', key: 'propertyViews' },
                      { name: 'BER Rating', key: 'berRating' },
                      { name: 'Listing Intention', key: 'listingIntent' },
                      { name: 'Actions', key: 'actions' }
                    ].map((column) => (
                      <th
                        key={column.key}
                        className="px-4 py-3 text-left text-gray-600 font-semibold"
                      >
                        {column.key !== 'actions' ? (
                          <button
                            onClick={() =>
                              handleSort(column.key as keyof Listing)
                            }
                            className="flex items-center focus:outline-none"
                          >
                            {column.name}
                            {sortField === column.key &&
                              (sortDirection === 'asc' ? (
                                <ChevronUpIcon className="w-4 h-4 ml-1" />
                              ) : (
                                <ChevronDownIcon className="w-4 h-4 ml-1" />
                              ))}
                          </button>
                        ) : (
                          column.name
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedListings.map((listing) => (
                    <tr key={listing.id} className="border-b hover:bg-gray-50">
                      <td className="px-4 py-3">
                        <input
                          type="checkbox"
                          checked={selectedListings.includes(listing.id)}
                          onChange={() => toggleListingSelection(listing.id)}
                        />
                      </td>
                      <td className="px-4 py-3 text-blue-700">
                        {' '}
                        <Link
                          to={`/property/${listing.id}`}       
                          onClick={() =>
                            navigate(
                              `/property/${listing.id}`
                            )
                          }
                        >
                          {listing.id}
                        </Link>
                      </td>
                      <td className="px-4 py-3 text-gray-700 font-medium">
                        {capitalizeWords(listing.title)}
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        €{listing.price.toLocaleString()}
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        {capitalizeWords(listing.location)}
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        {listing.type}
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        {listing.bedrooms}
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        {listing.bathrooms}
                      </td>
                      <td className="px-4 py-3">
                        <span
                          className={`px-3 py-1 rounded-full text-xs font-semibold capitalize ${
                            listing.status === 'active'
                              ? 'bg-green-100 text-green-800'
                              : listing.status === 'pending'
                                ? 'bg-yellow-100 text-yellow-800'
                                : 'bg-red-100 text-red-800'
                          }`}
                        >
                          {listing.status}
                        </span>
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        {listing.dateAdded instanceof Timestamp
                          ? listing.dateAdded.toDate().toLocaleDateString()
                          : 'N/A'}
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        {listing.propertyViews}
                      </td>
                      <td className="px-4 py-3 text-gray-600">
                        {listing.berRating}
                      </td>
                      <td className="px-4 py-3 text-gray-600 capitalize">
                        {listing.listingIntent}
                      </td>
                      <td className="px-4 py-3">
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleEdit(listing.id)}
                            className="text-blue-500 hover:text-blue-700"
                            title="Edit Listing"
                          >
                            <PencilIcon className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDelete(listing.id)}
                            className="text-red-500 hover:text-red-700"
                            title="Delete Listing"
                          >
                            <TrashIcon className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() =>
                              navigate(
                                `/property/${listing.id}`
                              )
                            }
                            className="text-green-500 hover:text-green-700"
                            title="View Details"
                          >
                            <EyeIcon className="w-5 h-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Pagination Controls */}
            <div className="mt-6 flex flex-col md:flex-row justify-between items-center">
              <div className="text-gray-600 mb-4 md:mb-0">
                Showing {(currentPage - 1) * ITEMS_PER_PAGE + 1} to{' '}
                {Math.min(
                  currentPage * ITEMS_PER_PAGE,
                  sortedAndFilteredListings.length
                )}{' '}
                of {sortedAndFilteredListings.length} listings
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => setCurrentPage(1)}
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === 1
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  First
                </button>
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.max(prev - 1, 1))
                  }
                  disabled={currentPage === 1}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === 1
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  Previous
                </button>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page) => (
                    <button
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      className={`px-3 py-1 rounded-md ${
                        currentPage === page
                          ? 'bg-blue-500 text-white'
                          : 'bg-white text-gray-700 hover:bg-gray-100'
                      }`}
                    >
                      {page}
                    </button>
                  )
                )}
                <button
                  onClick={() =>
                    setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === totalPages
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  Next
                </button>
                <button
                  onClick={() => setCurrentPage(totalPages)}
                  disabled={currentPage === totalPages}
                  className={`px-3 py-1 rounded-md ${
                    currentPage === totalPages
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  Last
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <p>
              Looks like you haven’t added a property listing yet. Ready to
              create your first one?
            </p>
            <button
              onClick={() => navigate('/add-listing')}
              className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
            >
              Add New Listing
            </button>
          </>
        )}
      </div>
      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <h2 className="text-2xl font-bold mb-4">Confirm Deletion</h2>
        <p className="text-gray-700">
          Are you sure you want to delete this listing? This action cannot be
          undone.
        </p>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-5 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-300"
          >
            Cancel
          </button>
          <button
            onClick={confirmDelete}
            className="px-5 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MyListingsPage;

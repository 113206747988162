import React from 'react';
import { Video } from 'lucide-react';

interface PropertyVideoProps {
  videoUrl: string;
}

const PropertyVideo: React.FC<PropertyVideoProps> = ({ videoUrl }) => {
  // Function to extract YouTube video ID
  const getYouTubeEmbedUrl = (url: string) => {
    try {
      const urlObj = new URL(url);
      let videoId = '';

      if (urlObj.hostname === 'youtu.be') {
        // Handle youtu.be URLs
        videoId = urlObj.pathname.slice(1);
      } else if (urlObj.hostname.includes('youtube.com')) {
        // Handle youtube.com URLs
        videoId = urlObj.searchParams.get('v') || '';
      }

      if (!videoId) {
        throw new Error('Invalid YouTube URL');
      }

      return `https://www.youtube-nocookie.com/embed/${videoId}`;
    } catch (error) {
      console.error('Error parsing YouTube URL:', error);
      return null;
    }
  };

  const embedUrl = getYouTubeEmbedUrl(videoUrl);

  if (!embedUrl) {
    return null;
  }

  return (
    <div className="bg-white p-8 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 flex items-center">
        <Video className="w-7 h-7 mr-3 text-blue-500" />
        Property Video
      </h2>
      <div className="relative w-full pb-[56.25%] rounded-lg overflow-hidden shadow-md">
        <iframe
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full rounded-lg"
          title="Property Video"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default PropertyVideo;

import React from 'react';
import {
  BellIcon,
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/solid';

type NotificationType = 'info' | 'warning' | 'alert' | 'success';
export interface Notification {
  id: string;
  message: string;
  type: NotificationType;
}

interface NotificationsComponentProps {
  notifications: Notification[];
}

const NotificationsComponent: React.FC<NotificationsComponentProps> = ({
  notifications
}) => {

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-3xl font-extrabold mb-6 text-gray-800 flex items-center">
        <BellIcon className="w-8 h-8 text-yellow-500 mr-2" />
        Notifications
      </h2>
      <div className="space-y-4">
        {notifications.length > 0 ? (
          notifications.map((notification) => {
            let bgColor = '';
            let icon = null;

            switch (notification.type) {
              case 'info':
                bgColor = 'bg-blue-50';
                icon = (
                  <InformationCircleIcon className="w-6 h-6 text-blue-500 mr-4" />
                );
                break;
              case 'warning':
                bgColor = 'bg-yellow-50';
                icon = (
                  <ExclamationTriangleIcon className="w-6 h-6 text-yellow-500 mr-4" />
                );
                break;
              case 'alert':
                bgColor = 'bg-red-50';
                icon = (
                  <ExclamationCircleIcon className="w-6 h-6 text-red-500 mr-4" />
                );
                break;
              default:
                bgColor = 'bg-gray-50';
                icon = <BellIcon className="w-6 h-6 text-gray-500 mr-4" />;
            }

            return (
              <div
                key={notification.id}
                className={`p-4 rounded-lg flex items-center ${bgColor} hover:shadow-md transition-shadow duration-300`}
              >
                {icon}
                <p className="text-gray-700">{notification.message}</p>
              </div>
            );
          })
        ) : (
          <p className="text-gray-500 text-center">
            You have no new notifications.
          </p>
        )}
      </div>
    </div>
  );
};

export default NotificationsComponent;

import React, { useState } from 'react';
import {
  BellIcon,
  ChartBarIcon,
  CheckCircleIcon,
  ClockIcon,
  CurrencyDollarIcon,
  HomeIcon
} from '@heroicons/react/24/outline';
import { insertDocument } from '../../../utility/firebaseHelpers';
import { serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../../../auth/firebaseAuth';
import { useAnalyticsWithConsent } from '../../../hooks/useAnalyticsWithConsent';
import { AnalyticsEvents } from '../../../analytics/firebaseAnalytics';

interface NewsletterSubscription {
  email: string;
  userId?: string;
  subscriptionDate: any;
  subscriptionSource: string;
  isVerified: boolean;
  preferences?: {
    location?: string;
    priceRange?: string;
    propertyType?: string;
    listingIntent?: string;
  };
}

interface PropertyAlertsProps {
  variant?: 'compact' | 'large';
}

const PropertyAlerts: React.FC<PropertyAlertsProps> = ({
  variant = 'large'
}) => {
  const [email, setEmail] = useState<string>('');
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  const analytics = useAnalyticsWithConsent();

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const subscribeAlerts = async () => {
    setError('');
    setSubscriptionStatus('');
    const trimmedEmail = email.trim().toLowerCase();

    // Validation
    if (!trimmedEmail) {
      setError('Please enter a valid email address.');
      return;
    }

    if (!validateEmail(trimmedEmail)) {
      setError('Please enter a valid email address.');
      return;
    }

    setIsLoading(true);
    try {
      // Prepare subscription data
      const subscriptionData: NewsletterSubscription = {
        email: trimmedEmail,
        userId: currentUser?.uid || '',
        subscriptionDate: serverTimestamp(),
        subscriptionSource: 'property_alerts',
        isVerified: false
      };

      // Insert subscription
      await insertDocument('newsletter', subscriptionData);

      // Track successful subscription
      analytics.logEvent(AnalyticsEvents.NEWSLETTER_SUBSCRIPTION, {
        source: 'property_alerts',
        user_type: currentUser ? 'authenticated' : 'guest',
        has_preferences: false
      });

      setSubscriptionStatus(
        'Successfully subscribed to property alerts! Please check your email to confirm your subscription.'
      );
      setEmail('');
    } catch (err) {
      console.error('Subscription error:', err);
      analytics.trackError(
        'newsletter_subscription_error',
        err instanceof Error ? err.message : 'Unknown error',
        {
          source: 'property_alerts',
          email: trimmedEmail,
          user_type: currentUser ? 'authenticated' : 'guest'
        }
      );

      setError('Failed to subscribe. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const features = [
    {
      icon: HomeIcon,
      title: 'New Listings',
      description: 'Be first to know about properties matching your criteria'
    },
    {
      icon: ChartBarIcon,
      title: 'Price Updates',
      description: 'Get notified about price changes in your desired areas'
    },
    {
      icon: ClockIcon,
      title: 'Instant Alerts',
      description: 'Real-time notifications for time-sensitive opportunities'
    },
    {
      icon: CurrencyDollarIcon,
      title: 'Market Insights',
      description: 'Weekly updates on market trends and property values'
    }
  ];

  const testimonials = [
    {
      quote:
        'Found my dream apartment within a week thanks to the instant alerts!',
      author: 'Sarah M.',
      location: 'Dublin'
    },
    {
      quote:
        'The price drop notifications helped me save €15,000 on my purchase.',
      author: 'John D.',
      location: 'Galway'
    }
  ];

  return (
    <div>
      {/* Conditional Content Based on Variant */}
      {variant === 'compact' ? (
        <div className="max-w-xl mx-auto px-4 py-8 mt-4 bg-white rounded-lg shadow-md">
          <div className="flex items-center mb-4">
            <BellIcon className="h-8 w-8 text-blue-600 mr-2" />
            <h2 className="text-2xl font-semibold">Property Alerts</h2>
          </div>
          <p className="text-gray-600 mb-6">
            Stay updated with alerts for new listings and market changes.
          </p>
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-gray-700">
                Email Address:
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="you@example.com"
                className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                disabled={isLoading}
              />
            </div>
            {error && (
              <p className="text-red-500 text-sm" role="alert">
                {error}
              </p>
            )}
            <button
              onClick={subscribeAlerts}
              disabled={isLoading}
              className={`w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300 ${
                isLoading && 'opacity-50 cursor-not-allowed'
              }`}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Subscribing...
                </span>
              ) : (
                'Subscribe'
              )}
            </button>
            {subscriptionStatus && (
              <div
                className="mt-4 p-4 bg-green-100 text-green-800 rounded-md"
                role="status"
              >
                {subscriptionStatus}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="mx-auto px-4 py-8 space-y-8">
          {/* Hero Section */}
          <div className="bg-gradient-to-r from-blue-600 to-blue-800 rounded-xl shadow-xl overflow-hidden">
            <div className="px-6 py-12 sm:px-12 text-center text-white">
              <div className="flex justify-center mb-6">
                <BellIcon className="h-12 w-12 text-blue-200" />
              </div>
              <h2 className="text-3xl font-bold mb-4">
                Never Miss Your Perfect Property
              </h2>
              <p className="text-blue-100 text-lg mb-8 max-w-2xl mx-auto">
                Join thousands of successful property seekers getting instant
                alerts for new listings, price drops, and market opportunities.
              </p>

              {/* Subscription Form */}
              <div className="max-w-md mx-auto bg-white rounded-lg p-1">
                <div className="flex flex-col sm:flex-row gap-2">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                    className="flex-1 px-4 py-3 rounded-md border-0 
    text-gray-900 
    placeholder-gray-500 
    bg-white 
    focus:ring-2 
    focus:ring-blue-500 
    focus:outline-none
    hover:bg-gray-50
    transition-colors
    disabled:bg-gray-100 
    disabled:text-gray-400
    disabled:cursor-not-allowed
    shadow-sm"
                    disabled={isLoading}
                  />
                  <button
                    onClick={subscribeAlerts}
                    disabled={isLoading}
                    className="px-6 py-3 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300 flex items-center justify-center whitespace-nowrap"
                  >
                    {isLoading ? (
                      <div className="flex items-center">
                        <svg
                          className="animate-spin h-5 w-5 mr-2"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                            fill="none"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        Subscribing...
                      </div>
                    ) : (
                      'Get Alerts →'
                    )}
                  </button>
                </div>
              </div>

              {/* Error/Success Messages */}
              {error && (
                <div className="mt-4 text-sm bg-red-100 text-red-800 px-4 py-2 rounded-md inline-block">
                  {error}
                </div>
              )}
              {subscriptionStatus && (
                <div className="mt-4 flex items-center justify-center text-sm bg-green-100 text-green-800 px-4 py-2 rounded-md inline-block">
                  <CheckCircleIcon className="h-5 w-5 mr-2" />
                  {subscriptionStatus}
                </div>
              )}
            </div>
          </div>

          {/* Features Grid */}
          <div className="grid md:grid-cols-2 gap-6 mt-12">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
              >
                <feature.icon className="h-8 w-8 text-blue-600 mb-4" />
                <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>

          {/* Social Proof */}
          <div className="bg-gray-50 rounded-xl p-8 mt-12">
            <h3 className="text-xl font-semibold text-center mb-8">
              Trusted by Thousands of Property Seekers
            </h3>
            <div className="grid md:grid-cols-2 gap-6">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="bg-white rounded-lg p-6 shadow-sm">
                  <p className="text-gray-700 italic mb-4">
                    "{testimonial.quote}"
                  </p>
                  <div className="flex items-center">
                    <div className="h-8 w-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-semibold">
                      {testimonial.author[0]}
                    </div>
                    <div className="ml-3">
                      <p className="font-medium">{testimonial.author}</p>
                      <p className="text-sm text-gray-500">
                        {testimonial.location}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Trust Signals */}
          <div className="text-center space-y-4 mt-8">
            <p className="text-sm text-gray-500">
              🔒 Your data is secure and will never be shared
            </p>
            <p className="text-sm text-gray-500">
              ✓ Free · ✓ Cancel anytime · ✓ No spam
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyAlerts;

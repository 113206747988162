// src/components/property-tools-grid/PropertyToolsGrid.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { startTransition } from 'react';
import { PropertyTool, propertyTools } from '../../../data/propertyTools';


const PropertyToolsGrid: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    startTransition(() => {
      navigate(path);
    });
  };

  return (
    <section className="mt-12">
      <h2 className="text-2xl font-semibold mb-6">Property Tools</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {propertyTools.map((tool: PropertyTool) => (
          <div
            key={tool.name}
            className="bg-white rounded-lg shadow-md p-6 flex flex-col items-start hover:shadow-xl transition-shadow duration-300"
          >
            <tool.icon
              className="h-8 w-8 text-blue-600 mb-4"
              aria-hidden="true"
            />
            <h3 className="text-lg font-medium mb-2">{tool.name}</h3>
            <p className="text-gray-600 flex-grow">{tool.description}</p>
            <button
              onClick={() => handleNavigation(tool.path)}
              className="mt-4 inline-flex items-center text-blue-600 hover:text-blue-800 font-semibold"
            >
              Learn More
              <svg
                className="w-4 h-4 ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9 5l7 7-7 7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PropertyToolsGrid;

import React from 'react';
import { Link } from 'react-router-dom';
import { Blog } from '../../types/blogTypes';

interface BlogCardProps {
  blog: Blog;
}

const BlogCard: React.FC<BlogCardProps> = ({ blog }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl">
      <img
        src={blog.image}
        alt={blog.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-6">
        <h2 className="text-xl font-semibold text-gray-800 mb-2">
          {blog.title}
        </h2>
        <p className="text-gray-600 mb-4 line-clamp-3">{blog.excerpt}</p>
        <Link
          to={`/blog/${blog.id}`}
          className="inline-block bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200 ease-in-out"
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;

import React from 'react';

interface PropertyDescriptionProps {
  description: string;
}

const PropertyDescription: React.FC<PropertyDescriptionProps> = ({
  description
}) => {
  return (
    <div className="bg-white p-8 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Description</h2>
      <p className="text-gray-700 leading-relaxed">{description}</p>
    </div>
  );
};

export default PropertyDescription;

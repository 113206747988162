// src/components/property-tools/MortgageCalculator/MortgageCalculator.tsx

import React, { useState } from 'react';
import { CalculatorIcon } from '@heroicons/react/24/outline';

const MortgageCalculator: React.FC = () => {
  const [principal, setPrincipal] = useState<number>(200000);
  const [interestRate, setInterestRate] = useState<number>(3.5);
  const [loanTerm, setLoanTerm] = useState<number>(30);
  const [monthlyPayment, setMonthlyPayment] = useState<number | null>(null);
  const [error, setError] = useState<string>('');

  const calculateMortgage = () => {
    setError('');
    if (principal <= 0 || interestRate <= 0 || loanTerm <= 0) {
      setError('Please enter positive values for all fields.');
      setMonthlyPayment(null);
      return;
    }

    const monthlyInterest = interestRate / 100 / 12;
    const numberOfPayments = loanTerm * 12;
    const numerator =
      monthlyInterest * Math.pow(1 + monthlyInterest, numberOfPayments);
    const denominator = Math.pow(1 + monthlyInterest, numberOfPayments) - 1;
    const payment = principal * (numerator / denominator);
    setMonthlyPayment(payment);
  };

  return (
    <div className="max-w-xl mx-auto px-4 py-8 mt-4 bg-white rounded-lg shadow-md">
      <div className="flex items-center mb-4">
        <CalculatorIcon className="h-8 w-8 text-blue-600 mr-2" />
        <h2 className="text-2xl font-semibold">Mortgage Calculator</h2>
      </div>
      <p className="text-gray-600 mb-6">
        Estimate your monthly mortgage payments with our easy-to-use calculator.
      </p>
      <div className="space-y-4">
        <div>
          <label className="block text-gray-700">Loan Amount (€):</label>
          <input
            type="number"
            value={principal}
            onChange={(e) => setPrincipal(Number(e.target.value))}
            className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="e.g., 200000"
          />
        </div>
        <div>
          <label className="block text-gray-700">Interest Rate (%):</label>
          <input
            type="number"
            step="0.01"
            value={interestRate}
            onChange={(e) => setInterestRate(Number(e.target.value))}
            className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="e.g., 3.5"
          />
        </div>
        <div>
          <label className="block text-gray-700">Loan Term (years):</label>
          <input
            type="number"
            value={loanTerm}
            onChange={(e) => setLoanTerm(Number(e.target.value))}
            className="mt-1 block w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="e.g., 30"
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <button
          onClick={calculateMortgage}
          className="w-full bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition duration-300"
        >
          Calculate
        </button>
        {monthlyPayment !== null && (
          <div className="mt-4 p-4 bg-green-100 text-green-800 rounded-md">
            <p className="text-lg font-medium">Estimated Monthly Payment:</p>
            <p className="text-2xl">€{monthlyPayment.toFixed(2)}</p>
          </div>
        )}
      </div>
      <p className="text-gray-500 mt-4">
        <strong>Why It's Useful:</strong> Understanding your potential mortgage
        payments helps you plan your finances effectively and ensures that you
        choose a property within your budget.
      </p>
    </div>
  );
};

export default MortgageCalculator;

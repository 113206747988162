// src/data/propertyTools.ts

import {
  CalculatorIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  BellIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

export interface PropertyTool {
  name: string;
  description: string;
  icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'> & {
      title?: string;
      titleId?: string;
    } & React.RefAttributes<SVGSVGElement>
  >;
  path: string;
}

export const propertyTools: PropertyTool[] = [
  {
    name: 'Mortgage Calculator',
    description:
      'Estimate your monthly mortgage payments with our easy-to-use calculator.',
    icon: CalculatorIcon,
    path: '/property-tools/mortgage-calculator'
  },
  // {
  //   name: 'Property Comparison',
  //   description:
  //     'Compare different properties side-by-side to make informed decisions.',
  //   icon: ScaleIcon,
  //   path: '/property-tools/property-comparison'
  // },
  // {
  //   name: 'Interactive Property Map',
  //   description:
  //     'Explore properties on an interactive map with detailed information.',
  //   icon: MapIcon,
  //   path: '/property-tools/interactive-property-map'
  // },
  {
    name: 'Price Trend Graph',
    description: 'Analyze price trends over time to gauge market performance.',
    icon: ChartBarIcon,
    path: '/property-tools/price-trend-graph'
  },
  {
    name: 'Affordability Calculator',
    description:
      'Determine how much property you can afford based on your income and expenses.',
    icon: CurrencyDollarIcon,
    path: '/property-tools/affordability-calculator'
  },
  // {
  //   name: 'Property Valuation',
  //   description:
  //     'Get an estimated value of your property based on current market data.',
  //   icon: CurrencyDollarIcon,
  //   path: '/property-tools/property-valuation'
  // },
  // {
  //   name: 'Neighborhood Insights',
  //   description:
  //     'Gain insights into neighborhood demographics, amenities, and more.',
  //   icon: ChartPieIcon,
  //   path: '/property-tools/neighborhood-insights'
  // },
  // {
  //   name: 'Financing Options',
  //   description:
  //     'Explore various financing options to find the best fit for your needs.',
  //   icon: BanknotesIcon,
  //   path: '/property-tools/financing-options'
  // },
  {
    name: 'Rental Yield Calculator',
    description:
      'Calculate the potential rental yield of your investment property.',
    icon: ChartBarIcon,
    path: '/property-tools/rental-yield-calculator'
  },
  {
    name: 'Property Alerts',
    description:
      'Stay updated with alerts for new listings and market changes.',
    icon: BellIcon,
    path: '/property-tools/property-alerts'
  }
];

import React from 'react';
import { WhatsappIcon, FacebookIcon, TelegramIcon } from 'react-share';

export const SupportNetwork: React.FC = () => {
  return (
    <div className="bg-blue-50 p-6 rounded-xl my-8 shadow-md">
      <h2 className="text-xl font-semibold mb-4 text-blue-900">
        Join Our Housing Support Network
      </h2>

      <div className="grid md:grid-cols-3 gap-6">
        {/* WhatsApp Group */}
        <div className="bg-white p-4 rounded-lg shadow-md text-center">
          <WhatsappIcon
            size={60}
            round
            className="text-green-500 mb-3 mx-auto"
          />
          <h3 className="font-medium text-lg mb-2">WhatsApp Group</h3>
          <p className="text-sm text-gray-600 mb-3">
            Connect with others in your area looking for housing.
          </p>
          <a
            href="https://chat.whatsapp.com/JQXsWjrrybP8covAaVggnD"
            target="_blank"
            rel="noopener noreferrer"
            className="text-green-500 font-medium hover:text-green-600"
          >
            Join Group →
          </a>
        </div>

        {/* Facebook Community */}
        <div className="bg-white p-4 rounded-lg shadow-md text-center">
          <FacebookIcon
            size={60}
            round
            className="text-blue-600 mb-3 mx-auto"
          />
          <h3 className="font-medium text-lg mb-2">Facebook Community</h3>
          <p className="text-sm text-gray-600 mb-3">
            Share experiences & find housing together.
          </p>
          <a
            href="https://www.facebook.com/groups/1295007891494794"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 font-medium hover:text-blue-700"
          >
            Join Community →
          </a>
        </div>

        {/* Telegram Alerts */}
        <div className="bg-white p-4 rounded-lg shadow-md text-center">
          <TelegramIcon
            size={60}
            round
            className="text-blue-500 mb-3 mx-auto"
          />
          <h3 className="font-medium text-lg mb-2">Telegram Alerts</h3>
          <p className="text-sm text-gray-600 mb-3">
            Get instant alerts for new listings.
          </p>
          <a
            href="https://t.me/rentsmart_ie"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 font-medium hover:text-blue-600"
          >
            Join Channel →
          </a>
        </div>
      </div>
    </div>
  );
};

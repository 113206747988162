import React, { useRef, useEffect, useCallback } from 'react';
import MessageItem from './MessageItem';
import { Message } from './messagesTypes';
import { User } from 'firebase/auth';

interface MessageListProps {
  messages: Message[];
  currentUser: User | null;
  namePhoto: { [key: string]: { name: string; photoUrl: string | null } };
  onLoadMore: () => void;
  hasMore: boolean;
  loading: boolean;
}

const MessageList: React.FC<MessageListProps> = ({
  messages,
  currentUser,
  namePhoto,
  onLoadMore,
  hasMore,
  loading
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const topElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          onLoadMore();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [hasMore, loading, onLoadMore]
  );

  return (
    <div className="flex flex-col h-full overflow-y-auto p-4 bg-gray-50">
      <div className="flex-grow">
        {hasMore && (
          <div ref={topElementRef}>{loading && 'Loading more...'}</div>
        )}
        {messages.map((message, index) => (
          <MessageItem
            key={message.id}
            message={message}
            isOwnMessage={message.senderId === currentUser?.uid}
            namePhoto={namePhoto}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default MessageList;

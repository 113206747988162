import React, { useState } from 'react';
import {
  CheckCircleIcon,
  ChevronDownIcon} from '@heroicons/react/24/solid';
import {
  Search,
  FileText,
  Scale,
  Camera,
  AlertCircle,
  Info} from 'lucide-react';

const RenterGuide: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const toggleSection = (section: string) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const GuideSection: React.FC<{
    title: string;
    id: string;
    icon: React.ElementType;
    children: React.ReactNode;
  }> = ({ title, id, icon: Icon, children }) => (
    <div className="mb-8 bg-white rounded-xl shadow-md overflow-hidden">
      <button
        onClick={() => toggleSection(id)}
        className="w-full p-6 flex items-center justify-between bg-gradient-to-r from-blue-50 to-white hover:from-blue-100 transition-all"
      >
        <div className="flex items-center space-x-4">
          <div className="p-3 rounded-full bg-blue-100 text-blue-600">
            <Icon size={24} />
          </div>
          <h2 className="text-2xl font-semibold text-gray-900">{title}</h2>
        </div>
        <ChevronDownIcon
          className={`h-6 w-6 text-blue-600 transform transition-transform ${
            activeSection === id ? 'rotate-180' : ''
          }`}
        />
      </button>
      <div
        className={`transition-all duration-300 ease-in-out ${
          activeSection === id
            ? 'max-h-[1000px] opacity-100'
            : 'max-h-0 opacity-0'
        } overflow-hidden`}
      >
        <div className="p-6 border-t border-gray-100">{children}</div>
      </div>
    </div>
  );

  const ChecklistItem: React.FC<{ text: string }> = ({ text }) => (
    <li className="flex items-start space-x-3 py-2">
      <CheckCircleIcon className="h-6 w-6 text-green-500 flex-shrink-0" />
      <span className="text-gray-700">{text}</span>
    </li>
  );

  return (
    <div className="min-h-screen py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Complete Renter's Guide
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Everything you need to know about finding and securing your perfect
            home
          </p>
        </div>

        {/* Quick Navigation */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-12">
          {[
            { title: 'Search Tips', icon: Search, id: 'search' },
            { title: 'Viewings', icon: Camera, id: 'viewings' },
            { title: 'Applications', icon: FileText, id: 'applications' },
            { title: 'Your Rights', icon: Scale, id: 'rights' }
          ].map((item) => (
            <button
              key={item.title}
              onClick={() => setActiveSection(item.id)}
              className={`p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow text-center ${
                activeSection === item.id
                  ? 'ring-2 ring-blue-600 bg-blue-50'
                  : ''
              }`}
            >
              <item.icon className="h-6 w-6 mx-auto mb-2 text-blue-600" />
              <span className="text-sm font-medium text-gray-900">
                {item.title}
              </span>
            </button>
          ))}
        </div>

        {/* Finding the Perfect Rental */}
        <GuideSection title="Smart Search Strategy" id="search" icon={Search}>
          <div className="space-y-6">
            <div className="bg-blue-50 p-4 rounded-lg mb-6">
              <p className="text-blue-800 flex items-start">
                <Info className="h-5 w-5 mr-2 flex-shrink-0" />
                Start your search at least 6-8 weeks before your desired move-in
                date for the best options.
              </p>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h3 className="font-semibold mb-3">Essential Considerations</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Define your maximum budget (including utilities)" />
                  <ChecklistItem text="Map out your preferred locations" />
                  <ChecklistItem text="List must-have amenities" />
                  <ChecklistItem text="Consider commute times" />
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-3">Search Tips</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Set up property alerts" />
                  <ChecklistItem text="Check listings daily" />
                  <ChecklistItem text="Research the neighborhood" />
                  <ChecklistItem text="Use map view to check location" />
                </ul>
              </div>
            </div>
          </div>
        </GuideSection>

        {/* Property Viewings */}
        <GuideSection title="Property Viewings" id="viewings" icon={Camera}>
          <div className="space-y-6">
            <p className="text-gray-600">
              Make the most of your property viewings with these essential
              checks and questions.
            </p>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold mb-3">What to Check</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Water pressure and heating" />
                  <ChecklistItem text="Signs of damp or mold" />
                  <ChecklistItem text="Window and door security" />
                  <ChecklistItem text="Electrical outlets and appliances" />
                  <ChecklistItem text="Mobile phone signal" />
                </ul>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold mb-3">Questions to Ask</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="What bills are included?" />
                  <ChecklistItem text="What's the lease duration?" />
                  <ChecklistItem text="Who handles maintenance?" />
                  <ChecklistItem text="Is the property BER certified?" />
                </ul>
              </div>
            </div>
          </div>
        </GuideSection>

        {/* Application Process */}
        <GuideSection
          title="Application Process"
          id="applications"
          icon={FileText}
        >
          <div className="space-y-6">
            <div className="bg-orange-50 p-4 rounded-lg mb-6">
              <p className="text-orange-800 flex items-start">
                <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
                Never pay any fees or deposits before viewing a property and
                verifying the landlord's credentials.
              </p>
            </div>
            <h3 className="font-semibold mb-3">Required Documents</h3>
            <ul className="space-y-2">
              <ChecklistItem text="Valid ID (Passport or Driving License)" />
              <ChecklistItem text="Proof of income (Pay slips or employment contract)" />
              <ChecklistItem text="Previous landlord references" />
              <ChecklistItem text="Bank statements (usually 3 months)" />
              <ChecklistItem text="PPS number" />
            </ul>
          </div>
        </GuideSection>

        {/* Tenant Rights */}
        <GuideSection title="Your Rights as a Tenant" id="rights" icon={Scale}>
          <div className="space-y-6">
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h3 className="font-semibold mb-3">Your Key Rights</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Safe, well-maintained property" />
                  <ChecklistItem text="Privacy and quiet enjoyment" />
                  <ChecklistItem text="Written contract" />
                  <ChecklistItem text="Rent book and receipts" />
                  <ChecklistItem text="Notice periods for changes" />
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-3">Landlord Obligations</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Register with RTB" />
                  <ChecklistItem text="Maintain the property" />
                  <ChecklistItem text="Handle repairs promptly" />
                  <ChecklistItem text="Provide contact details" />
                </ul>
              </div>
            </div>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h4 className="font-semibold mb-2">Resources</h4>
              <div className="space-y-2">
                <a
                  href="https://www.rtb.ie/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline block"
                >
                  Residential Tenancies Board →
                </a>
                <a
                  href="https://www.threshold.ie/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline block"
                >
                  Threshold (Housing Charity) →
                </a>
              </div>
            </div>
          </div>
        </GuideSection>

        {/* Call to Action */}
        <div className="bg-blue-600 rounded-xl p-8 text-white text-center mt-12">
          <h2 className="text-2xl font-bold mb-4">
            Ready to Find Your New Home?
          </h2>
          <p className="mb-6">
            Start your search today and find the perfect property with
            RentSmart.
          </p>
          <button
            onClick={() => (window.location.href = '/browse')}
            className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
          >
            Browse Properties
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenterGuide;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import PropertyCard from '../../components/property/property-card/PropertyCard';
import { Listing } from '../../components/property/property-card/PropertyCardTypes';
import ShareModal from '../../components/property/property-card/ShareModal';
import { useAuth } from '../../auth/firebaseAuth';
import {
  queryDocuments,
  getDocumentById,
  deleteDocument,
} from '../../utility/firebaseHelpers';
import { where } from 'firebase/firestore';

const BookmarkedPropertiesPage: React.FC = () => {
  const { currentUser } = useAuth();
  const [bookmarkedProperties, setBookmarkedProperties] = useState<Listing[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [currentShareUrl, setCurrentShareUrl] = useState('');
  const [currentShareTitle, setCurrentShareTitle] = useState('');

  useEffect(() => {
    const fetchBookmarkedProperties = async () => {
      setIsLoading(true);
      try {
        let bookmarkIds: string[] = [];
        if (currentUser) {
          // Fetch bookmarks from Firebase
          const bookmarks = await queryDocuments('bookmarks', [
            where('userId', '==', currentUser.uid)
          ]);
          bookmarkIds = bookmarks.map((bookmark) => bookmark.listingId);
        } else {
          // Fetch bookmarks from localStorage
          const storedBookmarks = localStorage.getItem('bookmarkedListings');
          if (storedBookmarks) {
            bookmarkIds = JSON.parse(storedBookmarks);
          }
        }

        // Fetch the actual listing data for each bookmarked property
        const propertyPromises = bookmarkIds.map((id) =>
          getDocumentById('listings', id)
        );
        const properties = await Promise.all(propertyPromises);
        setBookmarkedProperties(properties.filter(Boolean) as unknown as Listing[]);
      } catch (error) {
        console.error('Error fetching bookmarked properties:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookmarkedProperties();
  }, [currentUser]);

  const handleRemoveBookmark = async (id: number) => {
    if (currentUser) {
      try {
        const bookmarks = await queryDocuments('bookmarks', [
          where('userId', '==', currentUser.uid),
          where('listingId', '==', id.toString())
        ]);
        if (bookmarks.length > 0) {
          await deleteDocument('bookmarks', bookmarks[0].id);
        }
      } catch (error) {
        console.error('Error removing bookmark:', error);
      }
    } else {
      // Remove from localStorage
      const storedBookmarks = localStorage.getItem('bookmarkedListings');
      if (storedBookmarks) {
        const bookmarks = JSON.parse(storedBookmarks);
        const updatedBookmarks = bookmarks.filter(
          (bookmarkId: number) => bookmarkId !== id
        );
        localStorage.setItem(
          'bookmarkedListings',
          JSON.stringify(updatedBookmarks)
        );
      }
    }
    // Remove from state
    setBookmarkedProperties((prev) =>
      prev.filter((property) => property.id !== id)
    );
  };

  const handleShare = (listing: Listing) => {
    const shareUrl = `${window.location.origin}/property/${listing.id}`;
    const shareTitle = `Check out this property: ${listing.title}`;
    setCurrentShareUrl(shareUrl);
    setCurrentShareTitle(shareTitle);
    setIsShareModalOpen(true);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (bookmarkedProperties.length === 0) {
    return (
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">Bookmarked Properties</h1>
        <p className="text-gray-600">
          You haven't bookmarked any properties yet.
        </p>
        <Link
          to="/browse"
          className="text-blue-500 hover:underline mt-4 inline-block"
        >
          Browse Properties
        </Link>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Bookmarked Properties</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {bookmarkedProperties.map((property) => (
          <PropertyCard
            key={property.id}
            listing={property}
            isBookmarked={true}
            onBookmarkToggle={() => handleRemoveBookmark(property.id)}
            onShare={() => handleShare(property)}
          />
        ))}
      </div>
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        url={currentShareUrl}
        title={currentShareTitle}
      />
    </div>
  );
};

export default BookmarkedPropertiesPage;

// EditListingPage.tsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { motion, AnimatePresence } from 'framer-motion';
import {
  XMarkIcon,
  HomeIcon,
  ClipboardIcon,
  CalendarIcon,
  StarIcon,
  PhotoIcon
} from '@heroicons/react/24/outline';
import { Timestamp, where } from 'firebase/firestore';
import { useAuth } from '../../auth/firebaseAuth';
import { Listing } from '../../components/property/property-card/PropertyCardTypes';
import { queryDocuments, updateDocument } from '../../utility/firebaseHelpers';
import { deleteImage, uploadFile } from '../../utility/firebaseStorageHelpers';
import toast from 'react-hot-toast';

const propertyTypes = ['Apartment', 'House', 'Studio', 'Duplex', 'Townhouse'];
const berRatings = [
  'A1',
  'A2',
  'A3',
  'B1',
  'B2',
  'B3',
  'C1',
  'C2',
  'C3',
  'D1',
  'D2',
  'E1',
  'E2',
  'F',
  'G'
];
const amenitiesList = [
  'Wi-Fi',
  'Parking',
  'Gym',
  'Pool',
  'Elevator',
  'Furnished',
  'Balcony',
  'Garden',
  'Pet-friendly'
];

const EditListingPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm<Listing>();
  const [activeSection, setActiveSection] = useState('basic');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [listingDocId, setListingDocId] = useState<string | null>(null);
  const [existingImages, setExistingImages] = useState<string[]>([]);
  const [newImageFiles, setNewImageFiles] = useState<File[]>([]);
  const [deletedImages, setDeletedImages] = useState<string[]>([]);

  useEffect(() => {
    const fetchListing = async () => {
      if (!id || !currentUser) return;

      setIsLoading(true);
      setError(null);

      try {
        const constraints = [where('id', '==', Number(id))];
        const listings = await queryDocuments<Listing>('listings', constraints);

        if (listings.length === 0) {
          setError('Listing not found');
          return;
        }

        const listing = listings[0];
        setListingDocId(listing.id);

        if (listing.propertyOwnerUid !== currentUser.uid) {
          setError("You don't have permission to edit this listing");
          return;
        }

        // Set form values
        Object.entries(listing).forEach(([key, value]) => {
          setValue(key as keyof Listing, value);
        });

        // Convert Firestore Timestamp to Date for availableFrom
        if (listing.availableFrom) {
          //setValue('availableFrom', listing.availableFrom.toDate());
        }

        if (listing) {
          // Set existing images
          setExistingImages(listing.images || []);
          setValue('images', listing.images || []);
        }
      } catch (error) {
        console.error('Error fetching listing:', error);
        setError('Failed to fetch listing details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchListing();
  }, [id, currentUser, setValue]);

  const onSubmit = async (data: Listing) => {
    if (!listingDocId || !currentUser) return;

    try {
      // Upload only new images
      const uploadedImageUrls = await Promise.all(
        newImageFiles.map((file) =>
          uploadFile(
            file,
            `listings/${listingDocId}/${Date.now()}_${file.name}`
          )
        )
      );

      // Combine existing images (excluding deleted ones) with new image URLs
      const updatedImages = [
        ...existingImages.filter((img) => !deletedImages.includes(img)),
        ...uploadedImageUrls
      ];

      // Update the listing with new data and updated image list
      await updateDocument<Listing>('listings', listingDocId.toString(), {
        ...data,
        images: updatedImages,
        propertyOwnerUid: currentUser.uid,
        location: data.location.toLowerCase()
      });

      // Delete removed images from storage
      for (const imageUrl of deletedImages) {
        try {
          await deleteImage(imageUrl);
        } catch (error) {
          console.error('Error deleting image:', imageUrl, error);
        }
      }
      toast.success('Your listing has been successfully updated!');
      navigate('/my-listings');
    } catch (error) {
      console.error('Error updating listing:', error);
      setError('Failed to update listing');
    }
  };

  const handleImageDelete = (imageUrl: string) => {
    // Remove the image from the form state
    const currentImages = watch('images') || [];
    setValue(
      'images',
      currentImages.filter((img) => img !== imageUrl)
    );

    // If it's an existing image, add it to deletedImages
    if (existingImages.includes(imageUrl)) {
      setDeletedImages((prevDeleted) => [...prevDeleted, imageUrl]);
    }

    // If it's a new image, remove it from newImageFiles
    setNewImageFiles((prevFiles) =>
      prevFiles.filter((file) => URL.createObjectURL(file) !== imageUrl)
    );
  };

  const handleNewImages = (files: FileList | null) => {
    if (files) {
      const newFiles = Array.from(files);
      setNewImageFiles((prevFiles) => [...prevFiles, ...newFiles]);

      // Create object URLs for preview
      const newImageUrls = newFiles.map((file) => URL.createObjectURL(file));
      setValue('images', [...(watch('images') || []), ...newImageUrls]);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen text-red-500">
        {error}
      </div>
    );
  }
  const sections = [
    { key: 'basic', label: 'Basic Info', icon: HomeIcon },
    { key: 'details', label: 'Details', icon: ClipboardIcon },
    { key: 'additional', label: 'Additional', icon: CalendarIcon },
    { key: 'images', label: 'Images', icon: PhotoIcon }
  ];

  const renderSection = (section: string) => {
    switch (section) {
      case 'basic':
        return (
          <motion.div
            key="basic"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h2 className="text-2xl font-semibold mb-6 flex items-center">
              <HomeIcon className="w-6 h-6 mr-2 text-blue-500" />
              Basic Information
            </h2>
            <div className="space-y-6">
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Title
                </label>
                <input
                  {...register('title', { required: 'Title is required' })}
                  className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                    errors.title ? 'border-red-500' : ''
                  }`}
                />
                {errors.title && (
                  <span className="text-red-500 text-sm">
                    {errors.title.message}
                  </span>
                )}
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Price (€)
                </label>
                <input
                  type="number"
                  {...register('price', {
                    required: 'Price is required',
                    min: { value: 0, message: 'Price must be positive' }
                  })}
                  className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                    errors.price ? 'border-red-500' : ''
                  }`}
                />
                {errors.price && (
                  <span className="text-red-500 text-sm">
                    {errors.price.message}
                  </span>
                )}
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Property Type
                </label>
                <select
                  {...register('type', {
                    required: 'Property type is required'
                  })}
                  className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                    errors.type ? 'border-red-500' : ''
                  }`}
                >
                  <option value="">Select a property type</option>
                  {propertyTypes.map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                {errors.type && (
                  <span className="text-red-500 text-sm">
                    {errors.type.message}
                  </span>
                )}
              </div>
            </div>
          </motion.div>
        );
      case 'details':
        return (
          <motion.div
            key="details"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h2 className="text-2xl font-semibold mb-6 flex items-center">
              <ClipboardIcon className="w-6 h-6 mr-2 text-blue-500" />
              Property Details
            </h2>
            <div className="space-y-6">
              <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
                <div className="flex-1">
                  <label className="block mb-2 font-medium text-gray-700">
                    Bedrooms
                  </label>
                  <input
                    type="number"
                    {...register('bedrooms', {
                      required: 'Bedrooms are required'
                    })}
                    className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                      errors.bedrooms ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.bedrooms && (
                    <span className="text-red-500 text-sm">
                      {errors.bedrooms.message}
                    </span>
                  )}
                </div>
                <div className="flex-1">
                  <label className="block mb-2 font-medium text-gray-700">
                    Bathrooms
                  </label>
                  <input
                    type="number"
                    {...register('bathrooms', {
                      required: 'Bathrooms are required'
                    })}
                    className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                      errors.bathrooms ? 'border-red-500' : ''
                    }`}
                  />
                  {errors.bathrooms && (
                    <span className="text-red-500 text-sm">
                      {errors.bathrooms.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  {...register('description', {
                    required: 'Description is required'
                  })}
                  rows={5}
                  className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                    errors.description ? 'border-red-500' : ''
                  }`}
                />
                {errors.description && (
                  <span className="text-red-500 text-sm">
                    {errors.description.message}
                  </span>
                )}
              </div>
              <div>
                <label className="block mb-4 font-medium text-gray-700">
                  Amenities
                </label>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {amenitiesList.map((amenity) => (
                    <label
                      key={amenity}
                      className="flex items-center text-gray-700"
                    >
                      <input
                        type="checkbox"
                        value={amenity}
                        {...register('amenities')}
                        className="mr-2 h-5 w-5 text-blue-500 focus:ring-blue-500"
                        // Ensure amenities is always an array
                        checked={watch('amenities')?.includes(amenity) || false}
                      />
                      {amenity}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        );
      case 'additional':
        return (
          <motion.div
            key="additional"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h2 className="text-2xl font-semibold mb-6 flex items-center">
              <CalendarIcon className="w-6 h-6 mr-2 text-blue-500" />
              Additional Information
            </h2>
            <div className="space-y-6">
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Available From
                </label>
                <Controller
                  control={control}
                  name="availableFrom"
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) => field.onChange(date)}
                      selected={
                        field.value instanceof Timestamp
                          ? field.value.toDate() // Convert Timestamp to Date
                          : field.value || null // Use Date object directly or null if empty
                      }
                      className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                        errors.availableFrom ? 'border-red-500' : ''
                      }`}
                      placeholderText="Select a date"
                      minDate={new Date()}
                    />
                  )}
                />
                {errors.availableFrom && (
                  <span className="text-red-500 text-sm">
                    {errors.availableFrom.message}
                  </span>
                )}
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  <StarIcon className="w-5 h-5 inline-block mr-1 text-blue-500" />
                  BER Rating
                </label>
                <select
                  {...register('berRating', {
                    required: 'BER rating is required'
                  })}
                  className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                    errors.berRating ? 'border-red-500' : ''
                  }`}
                >
                  <option value="">Select a BER rating</option>
                  {berRatings.map((rating) => (
                    <option key={rating} value={rating}>
                      {rating}
                    </option>
                  ))}
                </select>
                {errors.berRating && (
                  <span className="text-red-500 text-sm">
                    {errors.berRating.message}
                  </span>
                )}
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Video URL (optional)
                </label>
                <input
                  {...register('videoUrl', {
                    pattern: {
                      value:
                        /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
                      message: 'Enter a valid YouTube URL'
                    }
                  })}
                  className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300 ${
                    errors.videoUrl ? 'border-red-500' : ''
                  }`}
                  placeholder="https://www.youtube.com/watch?v=example"
                />
                {errors.videoUrl && (
                  <span className="text-red-500 text-sm">
                    {errors.videoUrl.message}
                  </span>
                )}
              </div>
            </div>
          </motion.div>
        );
      case 'images':
        return (
          <motion.div
            key="images"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <h2 className="text-2xl font-semibold mb-6 flex items-center">
              <PhotoIcon className="w-6 h-6 mr-2 text-blue-500" />
              Images
            </h2>
            <div className="space-y-6">
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Current Images
                </label>
                {watch('images') && watch('images').length > 0 ? (
                  <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                    {watch('images').map((image, index) => (
                      <div key={index} className="relative">
                        <img
                          src={image}
                          alt={`Listing ${index + 1}`}
                          className="w-full h-32 object-cover rounded-lg"
                        />
                        <button
                          type="button"
                          onClick={() => handleImageDelete(image)}
                          className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full hover:bg-red-600 transition duration-300"
                        >
                          <XMarkIcon className="w-4 h-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500">No images uploaded yet.</p>
                )}
              </div>
              <div>
                <label className="block mb-2 font-medium text-gray-700">
                  Add New Images
                </label>
                <input
                  type="file"
                  multiple
                  accept="image/jpeg,image/png,image/gif"
                  onChange={(e) => handleNewImages(e.target.files)}
                  className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none transition duration-300"
                />
              </div>
            </div>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="py-12">
      <div className="container mx-auto px-4 max-w-3xl">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-8 text-center">
          Edit Listing
        </h1>
        <div className="bg-white shadow-md rounded-lg p-8">
          <div className="flex flex-wrap md:flex-nowrap mb-8">
            {sections.map((section) => (
              <button
                key={section.key}
                onClick={() => setActiveSection(section.key)}
                className={`flex-1 py-3 px-4 text-center font-medium text-sm md:text-base ${
                  activeSection === section.key
                    ? 'border-b-4 border-blue-500 text-blue-600'
                    : 'border-b-4 border-transparent text-gray-600 hover:text-blue-600'
                } transition duration-300`}
              >
                <section.icon className="w-5 h-5 mx-auto mb-1" />
                {section.label}
              </button>
            ))}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <AnimatePresence mode="wait">
              {renderSection(activeSection)}
            </AnimatePresence>
            <div className="mt-8 flex justify-between">
              <button
                type="button"
                onClick={() => navigate('/my-listings')}
                className="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition duration-300"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditListingPage;

// src/pages/newsletter-unsubscribe/NewsletterUnsubscribe.tsx
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  queryDocuments,
  updateDocument,
  deleteDocument
} from '../../utility/firebaseHelpers';
import { where } from 'firebase/firestore';
import { CheckCircle, XCircle, Loader2, AlertTriangle } from 'lucide-react';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';
import { AnalyticsEvents } from '../../analytics/firebaseAnalytics';

interface UnsubscribeStatus {
  type: 'loading' | 'success' | 'error' | 'confirm';
  message: string;
}

const NewsletterUnsubscribe: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const analytics = useAnalyticsWithConsent();
  const [status, setStatus] = useState<UnsubscribeStatus>({
    type: 'loading',
    message: 'Processing your request...'
  });
  const [subscriptionData, setSubscriptionData] = useState<any>(null);

  useEffect(() => {
    const verifyUnsubscribeRequest = async () => {
      const token = searchParams.get('token');
      const email = searchParams.get('email');

      if (!token || !email) {
        setStatus({
          type: 'error',
          message:
            'Invalid unsubscribe link. Please check your email and try again.'
        });
        return;
      }

      try {
        // Find the subscription
        const subscriptions = await queryDocuments('newsletter', [
          where('email', '==', email),
          where('status', '==', 'confirmed')
        ]);

        if (subscriptions.length === 0) {
          setStatus({
            type: 'error',
            message: 'No active subscription found for this email address.'
          });
          return;
        }

        // Store subscription data for later use
        setSubscriptionData(subscriptions[0]);

        // Show confirmation screen
        setStatus({
          type: 'confirm',
          message: 'Are you sure you want to unsubscribe from property alerts?'
        });
      } catch (error) {
        console.error('Error verifying unsubscribe request:', error);
        setStatus({
          type: 'error',
          message:
            'An error occurred while processing your request. Please try again later.'
        });
      }
    };

    verifyUnsubscribeRequest();
  }, [searchParams]);

  const handleUnsubscribe = async () => {
    if (!subscriptionData) return;

    setStatus({
      type: 'loading',
      message: 'Processing unsubscription...'
    });

    try {
      // Update subscription status
      await updateDocument('newsletter', subscriptionData.id, {
        status: 'unsubscribed',
        unsubscribedAt: new Date(),
        // Keep the record for analytics but remove personal data
        preferences: null,
        token: null,
        tokenExpiry: null
      });

      // Track unsubscribe event
      analytics.logEvent(AnalyticsEvents.NEWSLETTER_UNSUBSCRIPTION, {
        subscription_duration:
          new Date().getTime() -
          subscriptionData.confirmedAt.toDate().getTime(),
        had_preferences: !!subscriptionData.preferences
      });

      setStatus({
        type: 'success',
        message: 'You have been successfully unsubscribed from property alerts.'
      });
    } catch (error) {
      console.error('Error unsubscribing:', error);
      setStatus({
        type: 'error',
        message:
          'An error occurred while unsubscribing. Please try again later.'
      });
    }
  };

  const handleKeepSubscription = () => {
    navigate('/property-tools/property-alerts');
  };

  const renderContent = () => {
    switch (status.type) {
      case 'loading':
        return (
          <div className="flex flex-col items-center">
            <Loader2 className="w-16 h-16 text-blue-500 animate-spin mb-4" />
            <p className="text-gray-600">{status.message}</p>
          </div>
        );

      case 'confirm':
        return (
          <div className="text-center">
            <AlertTriangle className="w-16 h-16 text-yellow-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Unsubscribe from Property Alerts?
            </h2>
            <p className="text-gray-600 mb-6">You will no longer receive:</p>
            <ul className="text-left mb-8 space-y-2">
              <li className="flex items-center text-gray-600">
                <span className="mr-2">•</span>
                New property notifications
              </li>
              <li className="flex items-center text-gray-600">
                <span className="mr-2">•</span>
                Market updates and insights
              </li>
              <li className="flex items-center text-gray-600">
                <span className="mr-2">•</span>
                Personalized property recommendations
              </li>
            </ul>

            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={handleUnsubscribe}
                className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
              >
                Yes, Unsubscribe
              </button>
              <button
                onClick={handleKeepSubscription}
                className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
              >
                No, Keep My Subscription
              </button>
            </div>
          </div>
        );

      case 'success':
        return (
          <div className="text-center">
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Successfully Unsubscribed
            </h2>
            <p className="text-gray-600 mb-6">{status.message}</p>

            <div className="bg-blue-50 rounded-lg p-6 mb-6">
              <h3 className="font-semibold text-blue-800 mb-2">
                Changed your mind?
              </h3>
              <p className="text-blue-700 mb-4">
                You can resubscribe to property alerts anytime.
              </p>
              <button
                onClick={() => navigate('/property-alerts')}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Resubscribe
              </button>
            </div>

            <button
              onClick={() => navigate('/')}
              className="text-gray-600 hover:text-gray-800 transition-colors"
            >
              Return to Homepage
            </button>
          </div>
        );

      case 'error':
        return (
          <div className="text-center">
            <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Oops! Something went wrong
            </h2>
            <p className="text-gray-600 mb-6">{status.message}</p>

            <div className="space-y-4">
              <button
                onClick={() => navigate('/property-alerts')}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Return to Property Alerts
              </button>
              <p className="text-sm text-gray-500">
                Need help?{' '}
                <a
                  href="mailto:support@rentsmart.ie"
                  className="text-blue-600 hover:underline"
                >
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg p-8">
        {renderContent()}
      </div>
    </div>
  );
};

export default NewsletterUnsubscribe;

import React from 'react';
import { Link } from 'react-router-dom';
import { HomeIcon } from '@heroicons/react/24/outline';

const PropertyListingCTA: React.FC = () => {
  return (
    <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-lg shadow-md p-4 sm:p-6 text-white mt-4">
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0 sm:space-x-4">
        <div className="flex items-start space-x-3 flex-grow">
          <HomeIcon className="h-6 w-6 sm:h-8 sm:w-8 flex-shrink-0 mt-1" />
          <div>
            <h3 className="text-xl sm:text-2xl font-bold leading-tight">
              List Your Property for Free Today!
            </h3>
            <p className="text-indigo-100 text-sm sm:text-base mt-1">
              Reach thousands of interested tenants with RentSmart.ie – no fees
              to get started!
            </p>
          </div>
        </div>
        <Link
          to="/list-your-property"
          className="bg-white text-indigo-600 px-4 py-2 rounded-md font-semibold text-sm sm:text-base hover:bg-indigo-50 transition duration-300 ease-in-out transform hover:scale-105 text-center w-full sm:w-auto"
        >
          Get started
        </Link>
      </div>
    </div>
  );
};

export default PropertyListingCTA;

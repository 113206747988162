import React, { useState } from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { insertDocument } from '../../utility/firebaseHelpers';

const DataRequestPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message:
      'I would like to request a copy of my personal data in accordance with GDPR.'
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Send the request to Firestore for processing
      await insertDocument('dataRequests', formData);

      // Show a success message and reset the form
      setSuccessMessage(
        'Your data request has been successfully submitted. We will process it and get back to you soon.'
      );
      setErrorMessage(null);
      setFormData({
        name: '',
        email: '',
        message:
          'I would like to request a copy of my personal data in accordance with GDPR.'
      });
    } catch (error) {
      console.error('Error submitting data request:', error);
      setErrorMessage(
        'There was an error submitting your request. Please try again later.'
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6 text-center">Request Your Data</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">GDPR Compliance</h2>
        <p className="mb-4 text-gray-700">
          Under GDPR, you have the right to request a copy of your personal data
          that we store. By filling out the form below, you can submit a formal
          request to access your data. We will process your request and respond
          as soon as possible.
        </p>
        <p className="text-gray-700">
          For more information on your rights, please visit the{' '}
          <a
            href="https://www.dataprotection.ie/"
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            Data Protection Commission
          </a>
          .
        </p>
      </section>

      {successMessage && (
        <div className="mb-4 flex items-center bg-green-100 border border-green-300 text-green-800 p-4 rounded-lg">
          <CheckCircleIcon className="h-6 w-6 mr-2 text-green-600" />
          <span>{successMessage}</span>
        </div>
      )}

      {errorMessage && (
        <div className="mb-4 flex items-center bg-red-100 border border-red-300 text-red-800 p-4 rounded-lg">
          <span>{errorMessage}</span>
        </div>
      )}

      <form
        onSubmit={handleSubmit}
        className="space-y-4 bg-white shadow-md p-6 rounded-lg"
      >
        <div>
          <label htmlFor="name" className="block text-gray-700 mb-1">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-gray-700 mb-1">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label htmlFor="message" className="block text-gray-700 mb-1">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows={4}
            className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
          ></textarea>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
        >
          Submit Request
        </button>
      </form>
    </div>
  );
};

export default DataRequestPage;

// src/components/CookieConsent.tsx
import React, { useState } from 'react';
import { useCookieConsent } from '../../context/CookieConsentContext';

interface CookieConsentProps {
  onAccept: () => void;
  onReject: () => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({
  onAccept,
  onReject
}) => {
  const {
    isConsentFormVisible,
    updateConsentStatus,
    hideConsentForm,
    consentSettings,
    consentStatus
  } = useCookieConsent();
  const [showDetails, setShowDetails] = useState(false);

  const handleAcceptAll = () => {
    const allEnabled = {
      analytics: true,
      functionality: true,
      personalization: true,
      advertising: true,
      security: true,
      status: 'accepted' as const
    };

    updateConsentStatus('accepted', allEnabled);
    onAccept();
  };

  const handleReject = () => {
    const allDisabled = {
      analytics: false,
      functionality: false,
      personalization: false,
      advertising: false,
      security: true, // Security cookies are always required
      status: 'rejected' as const
    };

    updateConsentStatus('rejected', allDisabled);
    onReject();
  };

  const handleCustomize = () => {
    setShowDetails(!showDetails);
  };

const handleSavePreferences = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  const formData = new FormData(e.currentTarget);

  // Convert FormData to settings object, explicitly setting false for unchecked boxes
  const settings = {
    analytics: formData.get('analytics') === 'on',
    functionality: formData.get('functionality') === 'on',
    personalization: formData.get('personalization') === 'on',
    advertising: formData.get('advertising') === 'on',
    security: true // Always required
  };

  // Check if any non-security consent was given
  const hasAnyConsent = Object.entries(settings).some(
    ([key, value]) => key !== 'security' && value === true
  );

  updateConsentStatus(hasAnyConsent ? 'partial' : 'rejected', settings);

  hasAnyConsent ? onAccept() : onReject();
};


  if (!isConsentFormVisible && consentStatus !== null) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white p-4 shadow-md z-50">
      <div className="max-w-7xl mx-auto">
        {/* Basic consent message and buttons */}
        <div className="flex flex-col sm:flex-row items-center justify-between mb-4">
          <p className="text-sm text-gray-700 mb-4 sm:mb-0">
            We use cookies to enhance your experience. By continuing to visit
            this site you agree to our use of cookies.
            <a
              href="/privacy-policy"
              className="text-blue-600 hover:underline ml-1"
            >
              Learn more
            </a>
          </p>
          <div className="flex space-x-4">
            <button
              onClick={handleReject}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
            >
              Reject All
            </button>
            <button
              onClick={handleCustomize}
              className="px-4 py-2 bg-gray-100 text-gray-800 rounded hover:bg-gray-200 transition-colors"
            >
              Customize
            </button>
            <button
              onClick={handleAcceptAll}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              Accept All
            </button>
          </div>
        </div>

        {/* Detailed preferences form */}
        {showDetails && (
          <form onSubmit={handleSavePreferences} className="border-t pt-4 mt-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {[
                {
                  id: 'functionality',
                  label: 'Functionality Cookies',
                  description: 'Enable site functionality and preferences'
                },
                {
                  id: 'analytics',
                  label: 'Analytics Cookies',
                  description: 'Help us improve our website'
                },
                {
                  id: 'personalization',
                  label: 'Personalization Cookies',
                  description: 'Remember your preferences'
                },
                {
                  id: 'advertising',
                  label: 'Advertising Cookies',
                  description: 'Help show you relevant ads'
                }
              ].map(({ id, label, description }) => (
                <div key={id} className="flex items-center space-x-3">
                  <input
                    type="checkbox"
                    id={id}
                    name={id}
                    defaultChecked={
                      Boolean(
                        consentSettings[id as keyof typeof consentSettings]
                      ) || false
                    }
                    className="h-4 w-4 text-blue-600 rounded"
                  />
                  <label htmlFor={id} className="text-sm text-gray-700">
                    {label}
                    <span className="block text-xs text-gray-500">
                      {description}
                    </span>
                  </label>
                </div>
              ))}
            </div>

            <div className="mt-4 flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
              >
                Save Preferences
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CookieConsent;

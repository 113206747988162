import React, { useRef } from 'react';
import Slider from 'react-slick';
import {
  ShareIcon,
  BookmarkIcon as BookmarkSolidIcon,
  BookmarkIcon as BookmarkOutlineIcon,
  CameraIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { Listing } from './PropertyCardTypes';
import { formatPrice } from '../../../utility/formatPrice';
import { capitalizeWords } from '../../../utility/capitalizeWords';
import { getListingIntentColor } from '../../../utility/property-utilities/listingIntentColor';
import { BadgeCheck, Bath, Bed, CalendarDays, MapPin, Maximize2 } from 'lucide-react';

interface PropertyCardProps {
  listing: Listing;
  isBookmarked: boolean;
  onBookmarkToggle: (id: number) => void;
  onShare: (listing: Listing) => void;
  isPreview?: boolean;
}

const PropertyCard: React.FC<PropertyCardProps> = ({
  listing,
  isBookmarked,
  onBookmarkToggle,
  onShare,
  isPreview = false
}) => {
  const sliderRef = useRef<Slider>(null);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    adaptiveHeight: true,
    appendDots: (dots: React.ReactNode) => (
      <div className="py-4">
        <ul className="flex justify-center space-x-3">{dots}</ul>
      </div>
    ),
    customPaging: (i: number) => (
      <button className="w-3 h-3 rounded-full bg-gray-300 transition-all duration-300 transform hover:scale-125 hover:bg-blue-500">
        <span className="sr-only">Go to slide {i + 1}</span>
      </button>
    )
  };

  const handlePrevImage = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNextImage = () => {
     sliderRef.current?.slickNext();
  };

   return (
     <div
       className={`bg-white rounded-lg shadow-lg overflow-hidden flex flex-col border border-gray-200 relative
      ${isPreview ? 'pointer-events-none' : 'hover:shadow-xl transition-shadow duration-300'}`}
     >
       {isPreview && (
         <div className="absolute inset-0 bg-black bg-opacity-10 z-10 flex items-center justify-center">
           <div className="bg-white bg-opacity-90 px-4 py-2 rounded-lg shadow-lg">
             <p className="text-gray-700 font-medium">Listing preview Mode</p>
           </div>
         </div>
       )}

       {/* Image Slider */}
       <div className="relative">
         <Slider ref={sliderRef} {...sliderSettings}>
           {listing.images.map((image, index) => (
             <div key={index} className="relative h-64 sm:h-72 md:h-80">
               <img
                 src={image}
                 alt={`${listing.title} ${index + 1}`}
                 className="w-full h-full object-cover"
               />
             </div>
           ))}
         </Slider>

         {/* Listing Type Badge */}
         <div className="absolute top-4 left-4 bg-gradient-to-r from-purple-500 to-blue-500 text-white px-3 py-1 rounded-full text-sm font-semibold shadow-md">
           {listing.type}
         </div>

         {/* Show 'More Photos' cue if there are additional images */}
         {listing.images.length > 1 && (
           <div className="absolute bottom-4 right-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-lg text-sm font-medium shadow-lg flex items-center space-x-1">
             <CameraIcon className="w-5 h-5 text-white" />
             <span>+{listing.images.length - 1} photos</span>
           </div>
         )}

         {/* Navigation Chevrons */}
         {listing.images.length > 1 && (
           <>
             <button
               onClick={handlePrevImage}
               className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
               aria-label="Previous image"
             >
               <ChevronLeftIcon className="w-6 h-6" />
             </button>
             <button
               onClick={handleNextImage}
               className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
               aria-label="Next image"
             >
               <ChevronRightIcon className="w-6 h-6" />
             </button>
           </>
         )}

         {/* Action Buttons (Bookmark & Share) */}
         <div className="absolute top-4 right-4 flex space-x-2">
           <button
             onClick={() => onBookmarkToggle(listing.id)}
             className="p-2 bg-white rounded-full shadow hover:shadow-md transition-shadow duration-300 focus:outline-none"
             aria-label={isBookmarked ? 'Remove Bookmark' : 'Add Bookmark'}
           >
             {isBookmarked ? (
               <BookmarkSolidIcon className="w-6 h-6 text-blue-500" />
             ) : (
               <BookmarkOutlineIcon className="w-6 h-6 text-gray-600" />
             )}
           </button>
           <button
             onClick={() => onShare(listing)}
             className="p-2 bg-white rounded-full shadow hover:shadow-md transition-shadow duration-300 focus:outline-none"
             aria-label="Share Property"
           >
             <ShareIcon className="w-6 h-6 text-gray-600" />
           </button>
         </div>
       </div>

       {/* Property Details */}
       <div className="p-6 flex flex-col flex-grow">
         {/* Title and Price */}
         <div className="flex justify-between items-start mb-2">
           <h2 className="text-xl font-semibold text-gray-800 truncate flex-1 mr-2">
             {capitalizeWords(listing.title)}
           </h2>
           <p className="text-2xl font-bold text-blue-600 whitespace-nowrap">
             €{formatPrice(listing.price)}
             {listing.listingIntent === 'rent' && (
               <span className="text-sm text-gray-500 ml-1"> /month</span>
             )}
           </p>
         </div>

         {/* Location */}
         <div className="flex items-center mb-4">
           <MapPin className="w-4 h-4 mr-2 flex-shrink-0 text-blue-600" />
           <span className="truncate text-sm">
             {capitalizeWords(listing.location)}
           </span>
         </div>

         {/* Property Details */}
         <div className="flex flex-wrap gap-4 mb-6 text-sm text-gray-700">
           <div className="flex items-center">
             <Bed className="w-4 h-4 mr-1 text-blue-600" />
             <span>
               {listing.bedrooms} {listing.bedrooms === 1 ? 'Bed' : 'Beds'}
             </span>
           </div>
           <div className="flex items-center">
             <Bath className="w-4 h-4 mr-1 text-blue-600" />
             <span>
               {listing.bathrooms} {listing.bathrooms === 1 ? 'Bath' : 'Baths'}
             </span>
           </div>
           {listing.size && listing.size > 0 && (
             <div className="flex items-center">
               <Maximize2 className="w-4 h-4 mr-1 text-blue-600" />
               <span>{listing.size} m²</span>
             </div>
           )}
           <div className="flex items-center">
             <BadgeCheck className="w-4 h-4 mr-1 text-blue-600" />
             <span>BER: {listing.berRating || 'N/A'}</span>
           </div>
           <div className="flex items-center">
             <CalendarDays className="w-4 h-4 mr-1 text-blue-600" />
             <span>
               {'seconds' in listing.availableFrom
                 ? new Date(
                     (listing.availableFrom.seconds as unknown as number) * 1000
                   ).toLocaleDateString('en-IE', {
                     year: 'numeric',
                     month: 'short',
                     day: 'numeric'
                   })
                 : 'N/A'}
             </span>
           </div>
         </div>

         {/* Description */}
         <p className="text-gray-600 mb-3 line-clamp-2 overflow-hidden">
           {listing.description}
         </p>

         {/* Listing Intent Badge */}
         <div
           className={`top-4 mb-4 left-4 inline-flex items-center bg-gradient-to-r ${getListingIntentColor(listing.listingIntent)} text-white px-3 py-1 rounded-full text-sm font-semibold shadow-md`}
           style={{ maxWidth: 'fit-content', whiteSpace: 'nowrap' }}
         >
           For {capitalizeWords(listing.listingIntent)}
         </div>

         {/* Amenities */}
         <div className="mb-4">
           <h3 className="text-sm font-semibold text-gray-700 mb-2">
             Amenities:
           </h3>
           <div className="flex flex-wrap gap-2">
             {listing.amenities.slice(0, 3).map((amenity, index) => (
               <span
                 key={index}
                 className="bg-gray-100 text-gray-700 px-2 py-1 rounded-full text-xs"
               >
                 {amenity}
               </span>
             ))}
             {listing.amenities.length > 3 && (
               <span className="bg-gray-100 text-gray-700 px-2 py-1 rounded-full text-xs">
                 +{listing.amenities.length - 3} more
               </span>
             )}
           </div>
         </div>
         {/* View Details Button */}
         {isPreview ? (
           <div className="mt-auto pb-6">
             <span className="inline-block w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white px-6 py-2 rounded-lg shadow opacity-50 text-center cursor-not-allowed">
               Explore Property
             </span>
           </div>
         ) : (
           <Link
             to={`/property/${listing.id}`}
             className="mt-auto inline-block w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white px-6 py-2 rounded-lg shadow hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300 text-center"
           >
             Explore Property
           </Link>
         )}
       </div>
     </div>
   );
};

export default PropertyCard;

// src/pages/newsletter-confirmation/NewsletterConfirmation.tsx
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { queryDocuments, updateDocument } from '../../utility/firebaseHelpers';
import { where } from 'firebase/firestore';
import { CheckCircle, XCircle, Loader2 } from 'lucide-react';

interface ConfirmationStatus {
  type: 'success' | 'error' | 'loading';
  message: string;
}

const NewsletterConfirmation: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<ConfirmationStatus>({
    type: 'loading',
    message: 'Verifying your subscription...'
  });

  useEffect(() => {
    const confirmSubscription = async () => {
      const token = searchParams.get('token');
      const id = searchParams.get('id');

      if (!token || !id) {
        setStatus({
          type: 'error',
          message:
            'Invalid confirmation link. Please check your email and try again.'
        });
        return;
      }

      try {
        // Find the subscription with matching token
        const subscriptions = await queryDocuments('newsletter', [
          where('confirmationToken', '==', token)
        ]);

        if (subscriptions.length === 0) {
          setStatus({
            type: 'error',
            message:
              'Invalid or expired confirmation link. Please request a new confirmation email.'
          });
          return;
        }

        const subscription = subscriptions[0];

        // Check if already confirmed
        if (subscription.status === 'confirmed') {
          setStatus({
            type: 'success',
            message:
              'Your subscription is already confirmed! You will continue receiving property alerts.'
          });
          return;
        }

        // Check token expiry
        const tokenExpiry = subscription.tokenExpiry?.toDate();
        if (tokenExpiry && tokenExpiry < new Date()) {
          setStatus({
            type: 'error',
            message:
              'This confirmation link has expired. Please request a new confirmation email.'
          });
          return;
        }

        // Update subscription status
        await updateDocument('newsletter', subscription.id, {
          status: 'confirmed',
          confirmedAt: new Date(),
          confirmationToken: null, // Clear the token after use
          tokenExpiry: null
        });

        setStatus({
          type: 'success',
          message:
            'Thank you! Your subscription has been confirmed successfully.'
        });
      } catch (error) {
        console.error('Error confirming subscription:', error);
        setStatus({
          type: 'error',
          message:
            'An error occurred while confirming your subscription. Please try again later.'
        });
      }
    };

    confirmSubscription();
  }, [searchParams]);

  const renderContent = () => {
    switch (status.type) {
      case 'loading':
        return (
          <div className="flex flex-col items-center">
            <Loader2 className="w-16 h-16 text-blue-500 animate-spin mb-4" />
            <p className="text-gray-600">{status.message}</p>
          </div>
        );

      case 'success':
        return (
          <div className="text-center">
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Subscription Confirmed!
            </h2>
            <p className="text-gray-600 mb-6">{status.message}</p>

            <div className="space-y-4">
              <div className="bg-green-50 rounded-lg p-4">
                <h3 className="text-lg font-semibold text-green-800 mb-2">
                  What's Next?
                </h3>
                <ul className="text-green-700 space-y-2">
                  <li>✓ You'll start receiving property alerts</li>
                  <li>✓ Customize your preferences anytime</li>
                  <li>✓ Get market insights weekly</li>
                </ul>
              </div>

              <div className="flex flex-col sm:flex-row gap-4 justify-center mt-6">
                <button
                  onClick={() => navigate('/property-tools/property-alerts')}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                >
                  Customize Alerts
                </button>
                <button
                  onClick={() => navigate('/browse')}
                  className="px-6 py-2 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  Browse Properties
                </button>
              </div>
            </div>
          </div>
        );

      case 'error':
        return (
          <div className="text-center">
            <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Oops! Something went wrong
            </h2>
            <p className="text-gray-600 mb-6">{status.message}</p>

            <div className="space-y-4">
              <button
                onClick={() => navigate('/property-tools/property-alerts')}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Return to Property Alerts
              </button>
              <p className="text-sm text-gray-500">
                Need help?{' '}
                <a
                  href="mailto:support@rentsmart.ie"
                  className="text-blue-600 hover:underline"
                >
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg p-8">
        {renderContent()}
      </div>
    </div>
  );
};

export default NewsletterConfirmation;

// src/components/LoadingSpinner.tsx

import React from 'react';

const LoadingSpinner: React.FC = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="relative">
        {/* Outer Ring */}
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="w-24 h-24 border-4 border-t-4 border-gray-300 rounded-full animate-spin"></div>
        </div>
        {/* Inner Gradient Ring */}
        <div className="w-24 h-24 border-4 border-t-4 border-blue-500 rounded-full animate-spin animation-delay-200"></div>
      </div>
      {/* Optional: Loading Text */}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default LoadingSpinner;

// src/utility/viewportUtils.ts

/**
 * Checks if an element is currently visible in the viewport
 */
export const isElementInViewport = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  // Check if element is completely visible
  const vertInView = rect.top >= 0 && rect.top + rect.height <= windowHeight;
  const horInView = rect.left >= 0 && rect.left + rect.width <= windowWidth;

  return vertInView && horInView;
};

/**
 * Gets the percentage of an element that is visible in the viewport
 */
export const getElementVisibility = (element: HTMLElement): number => {
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const elemHeight = rect.height;
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  // Element is not visible at all
  if (elemTop >= windowHeight || elemBottom <= 0) return 0;

  // Element is partially visible
  let visibleHeight = elemHeight;
  if (elemTop < 0) visibleHeight += elemTop;
  if (elemBottom > windowHeight) visibleHeight -= elemBottom - windowHeight;

  return Math.max(0, Math.min(100, (visibleHeight / elemHeight) * 100));
};

/**
 * Checks if element is at least partially visible
 */
export const isElementPartiallyVisible = (element: HTMLElement): boolean => {
  return getElementVisibility(element) > 0;
};

import React, { useState } from 'react';
import { insertDocument } from '../../utility/firebaseHelpers';
import { serverTimestamp } from 'firebase/firestore';
import {
  Loader2,
  ThumbsUp,
  ThumbsDown,
  SmilePlus,
  Ban,
  CheckCircle,
  MessageSquarePlus,
  X
} from 'lucide-react';

type FeedbackType =
  | 'ux'
  | 'features'
  | 'performance'
  | 'support'
  | 'other'
  | null;
type RatingType = 1 | 5 | 0;
type VariantType = 'default' | 'compact' | 'inline' | 'floating';

interface FeedbackCardProps {
  title?: string;
  subtitle?: string;
  variant?: VariantType;
  className?: string;
  onClose?: () => void;
}

// Usages 
// Default variant for dedicated feedback pages
// const FeedbackPage = () => {
//   return (
//     <div className="container mx-auto px-4 py-8">
//       <FeedbackCard />
//     </div>
//   );
// };

// // Compact variant for sidebars or smaller sections
// const SidebarComponent = () => {
//   return (
//     <div className="space-y-4">
//       <FeedbackCard
//         variant="compact"
//         title="Quick Feedback"
//         subtitle="Tell us what you think"
//       />
//     </div>
//   );
// };

// // Inline variant for embedding within content
// const ContentPage = () => {
//   return (
//     <div className="prose">
//       <h1>Our Service</h1>
//       <p>Some content...</p>
//       <FeedbackCard variant="inline" />
//       <p>More content...</p>
//     </div>
//   );
// };

// // Floating variant for global feedback
// const AppLayout = () => {
//   return (
//     <div>
//       {/* Your app content */}
//       <FeedbackCard variant="floating" />
//     </div>
//   );
// };

const FeedbackCard: React.FC<FeedbackCardProps> = ({
  title = 'Help Us Improve',
  subtitle = 'Your feedback helps us serve you better',
  variant = 'default',
  className = '',
  onClose
}) => {
  const [step, setStep] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(null);
  const [rating, setRating] = useState<RatingType>(0);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const feedbackCategories: Array<{
    label: string;
    value: Exclude<FeedbackType, null>;
  }> = [
    { label: 'User Experience', value: 'ux' },
    { label: 'Features', value: 'features' },
    { label: 'Performance', value: 'performance' },
    { label: 'Support', value: 'support' },
    { label: 'Other', value: 'other' }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!feedback.trim() || !feedbackType || rating === 0) {
      setError('Please complete all fields');
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      await insertDocument('platformFeedback', {
        feedback,
        feedbackType,
        rating,
        createdAt: serverTimestamp()
      });

      setIsSubmitting(false);
      setStep(3);

      setTimeout(() => {
        setStep(1);
        setFeedbackType(null);
        setRating(0);
        setFeedback('');
        if (variant !== 'default') {
          setIsExpanded(false);
        }
      }, 3000);
    } catch (err) {
      setIsSubmitting(false);
      setError('Failed to submit feedback. Please try again.');
    }
  };

  if (variant === 'inline') {
    return (
      <div className={`border-l-4 border-blue-500 pl-4 ${className}`}>
        {!isExpanded ? (
          <button
            onClick={() => setIsExpanded(true)}
            className="flex items-center space-x-2 text-blue-600 hover:text-blue-700"
          >
            <MessageSquarePlus className="w-5 h-5" />
            <span>Share your feedback</span>
          </button>
        ) : (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h4 className="font-medium text-gray-800">Quick Feedback</h4>
              <button
                onClick={() => setIsExpanded(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setRating(1)}
                className={`p-2 rounded ${
                  rating === 1
                    ? 'bg-red-100 text-red-500'
                    : 'text-gray-400 hover:text-red-500'
                }`}
              >
                <ThumbsDown className="w-4 h-4" />
              </button>
              <button
                onClick={() => setRating(5)}
                className={`p-2 rounded ${
                  rating === 5
                    ? 'bg-green-100 text-green-500'
                    : 'text-gray-400 hover:text-green-500'
                }`}
              >
                <ThumbsUp className="w-4 h-4" />
              </button>
            </div>
            {rating !== 0 && (
              <div className="space-y-2">
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  className="w-full p-2 text-sm border rounded"
                  rows={2}
                  placeholder="Optional feedback..."
                />
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="w-full bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600"
                >
                  {isSubmitting ? (
                    <Loader2 className="w-4 h-4 animate-spin mx-auto" />
                  ) : (
                    'Send'
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  if (variant === 'compact') {
    return (
      <div className={`bg-white rounded-lg shadow-sm p-4 ${className} mt-4`}>
        {!isExpanded ? (
          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => setIsExpanded(true)}
          >
            <div className="flex items-center space-x-3">
              <SmilePlus className="w-6 h-6 text-blue-500" />
              <div>
                <h4 className="font-medium text-gray-800">Share Feedback</h4>
                <p className="text-sm text-gray-500">Help us improve</p>
              </div>
            </div>
            <button className="text-blue-500 hover:text-blue-600 text-sm">
              Give Feedback
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h4 className="font-medium text-gray-800">{title}</h4>
              <button
                onClick={() => setIsExpanded(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
            <div className="flex justify-between gap-2">
              {feedbackCategories.map((category) => (
                <button
                  key={category.value}
                  onClick={() => setFeedbackType(category.value)}
                  className={`px-3 py-1 rounded text-sm ${
                    feedbackType === category.value
                      ? 'bg-blue-100 text-blue-600'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  {category.label}
                </button>
              ))}
            </div>
            {feedbackType && (
              <div className="space-y-3">
                <div className="flex justify-center gap-4">
                  <button
                    onClick={() => setRating(1)}
                    className={`p-2 rounded ${
                      rating === 1
                        ? 'bg-red-100 text-red-500'
                        : 'text-gray-400 hover:text-red-500'
                    }`}
                  >
                    <ThumbsDown className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => setRating(5)}
                    className={`p-2 rounded ${
                      rating === 5
                        ? 'bg-green-100 text-green-500'
                        : 'text-gray-400 hover:text-green-500'
                    }`}
                  >
                    <ThumbsUp className="w-5 h-5" />
                  </button>
                </div>
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  className="w-full p-2 border rounded"
                  rows={2}
                  placeholder="Optional feedback..."
                />
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  {isSubmitting ? (
                    <Loader2 className="w-4 h-4 animate-spin mx-auto" />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  if (variant === 'floating') {
    return (
      <div
        className={`fixed bottom-4 right-4 z-50 ${
          isExpanded ? 'w-80' : 'w-auto'
        } ${className}`}
      >
        {!isExpanded ? (
          <button
            onClick={() => setIsExpanded(true)}
            className="bg-blue-500 text-white p-3 rounded-full shadow-lg hover:bg-blue-600 transition-all duration-200"
          >
            <MessageSquarePlus className="w-6 h-6" />
          </button>
        ) : (
          <div className="bg-white rounded-lg shadow-lg p-4 space-y-4">
            <div className="flex justify-between items-center">
              <h4 className="font-medium text-gray-800">Quick Feedback</h4>
              <button
                onClick={() => setIsExpanded(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
            <div className="flex gap-2 flex-wrap">
              {feedbackCategories.map((category) => (
                <button
                  key={category.value}
                  onClick={() => setFeedbackType(category.value)}
                  className={`px-3 py-1 rounded text-sm ${
                    feedbackType === category.value
                      ? 'bg-blue-100 text-blue-600'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
                >
                  {category.label}
                </button>
              ))}
            </div>
            {feedbackType && (
              <div className="space-y-3">
                <div className="flex justify-center gap-4">
                  <button
                    onClick={() => setRating(1)}
                    className={`p-2 rounded ${
                      rating === 1
                        ? 'bg-red-100 text-red-500'
                        : 'text-gray-400 hover:text-red-500'
                    }`}
                  >
                    <ThumbsDown className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => setRating(5)}
                    className={`p-2 rounded ${
                      rating === 5
                        ? 'bg-green-100 text-green-500'
                        : 'text-gray-400 hover:text-green-500'
                    }`}
                  >
                    <ThumbsUp className="w-5 h-5" />
                  </button>
                </div>
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  className="w-full p-2 border rounded"
                  rows={2}
                  placeholder="Optional feedback..."
                />
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                  {isSubmitting ? (
                    <Loader2 className="w-4 h-4 animate-spin mx-auto" />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="max-w-lg mx-auto bg-white rounded-xl shadow-lg overflow-hidden mt-4">
      <div className="p-6">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">{title}</h3>
        <p className="text-gray-600 mb-6">{subtitle}</p>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-md text-sm flex items-center gap-2">
            <Ban className="w-4 h-4" />
            {error}
          </div>
        )}

        {step === 1 && (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                What area would you like to give feedback on?
              </label>
              <div className="grid grid-cols-2 gap-3">
                {feedbackCategories.map((category) => (
                  <button
                    key={category.value}
                    onClick={() => {
                      setFeedbackType(category.value);
                      setStep(2);
                    }}
                    className={`p-3 rounded-lg border-2 transition-all duration-200 ${
                      feedbackType === category.value
                        ? 'border-blue-500 bg-blue-50 text-blue-700'
                        : 'border-gray-200 hover:border-blue-200 hover:bg-blue-50'
                    }`}
                  >
                    {category.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                How satisfied are you with this aspect?
              </label>
              <div className="flex justify-center gap-8 mb-6">
                <button
                  type="button"
                  onClick={() => setRating(1 as RatingType)}
                  className={`flex flex-col items-center gap-2 p-3 rounded-lg transition-all ${
                    rating === 1
                      ? 'text-red-500 scale-110'
                      : 'text-gray-400 hover:text-red-500'
                  }`}
                >
                  <ThumbsDown className="w-8 h-8" />
                  <span className="text-sm">Not Satisfied</span>
                </button>
                <button
                  type="button"
                  onClick={() => setRating(5 as RatingType)}
                  className={`flex flex-col items-center gap-2 p-3 rounded-lg transition-all ${
                    rating === 5
                      ? 'text-green-500 scale-110'
                      : 'text-gray-400 hover:text-green-500'
                  }`}
                >
                  <ThumbsUp className="w-8 h-8" />
                  <span className="text-sm">Satisfied</span>
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Would you like to add any specific feedback?
              </label>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                rows={4}
                placeholder="Your feedback helps us improve..."
              />
            </div>

            <div className="flex gap-3">
              <button
                type="button"
                onClick={() => setStep(1)}
                className="flex-1 py-2 px-4 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-all duration-200"
              >
                Back
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="flex-1 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-all duration-200 flex items-center justify-center gap-2"
              >
                {isSubmitting ? (
                  <Loader2 className="w-5 h-5 animate-spin" />
                ) : (
                  'Submit Feedback'
                )}
              </button>
            </div>
          </form>
        )}

        {step === 3 && (
          <div className="text-center py-8">
            <div className="flex justify-center mb-4">
              <div className="bg-green-100 rounded-full p-3">
                <CheckCircle className="w-8 h-8 text-green-500" />
              </div>
            </div>
            <h4 className="text-lg font-medium text-gray-800 mb-2">
              Thank You for Your Feedback!
            </h4>
            <p className="text-gray-600">
              Your input helps us improve RentSmart for everyone.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackCard;

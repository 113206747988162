// src/components/auto-apply/AutoApplyReturn.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  RocketLaunchIcon,
  ClockIcon,
  BellIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  StarIcon,
  ArrowRightIcon
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import StyledNavLink from '../styled-nav-link/StyledNavLink';

interface BenefitProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const Benefit: React.FC<BenefitProps> = ({ icon, title, description }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="flex gap-4 p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300"
  >
    <div className="flex-shrink-0">
      <div className="p-3 bg-blue-50 rounded-lg text-blue-600">{icon}</div>
    </div>
    <div>
      <h3 className="font-semibold text-gray-900 mb-1">{title}</h3>
      <p className="text-gray-600 text-sm leading-relaxed">{description}</p>
    </div>
  </motion.div>
);

const AutoApplyUpsell: React.FC = () => {
  const navigate = useNavigate();

  const benefits = [
    {
      icon: <ClockIcon className="w-6 h-6" />,
      title: 'Save Time',
      description:
        'Automatically apply to properties that match your criteria as soon as they become available.'
    },
    {
      icon: <BellIcon className="w-6 h-6" />,
      title: 'Instant Notifications',
      description:
        'Get immediate alerts when properties matching your preferences are listed or when your applications are reviewed.'
    },
    {
      icon: <StarIcon className="w-6 h-6" />,
      title: 'Priority Applications',
      description:
        'Be among the first to apply for properties, increasing your chances of securing your dream home.'
    },
    {
      icon: <ShieldCheckIcon className="w-6 h-6" />,
      title: 'Secure & Verified',
      description:
        'Your information is securely stored and verified, building trust with property owners.'
    },
    {
      icon: <UserGroupIcon className="w-6 h-6" />,
      title: 'Direct Communication',
      description:
        'Connect directly with landlords once your application is accepted, streamlining the rental process.'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-12">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex justify-center mb-4"
          >
            <RocketLaunchIcon className="w-16 h-16 text-blue-500" />
          </motion.div>
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            Supercharge Your Property Search
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-600"
          >
            Create an account to unlock Auto Apply and streamline your rental
            journey
          </motion.p>
        </div>

        {/* Benefits Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
          {benefits.map((benefit, index) => (
            <motion.div
              key={benefit.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <Benefit {...benefit} />
            </motion.div>
          ))}
        </div>

        {/* CTA Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center bg-white p-8 rounded-xl shadow-lg"
        >
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Ready to Get Started?
          </h2>
          <p className="text-gray-600 mb-6">
            Join thousands of renters who have simplified their property search
            with Auto Apply
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={() => navigate('/signup')}
              className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors duration-300"
            >
              Create Account
              <ArrowRightIcon className="w-5 h-5 ml-2" />
            </button>
            <button
              onClick={() => navigate('/login')}
              className="inline-flex items-center justify-center px-6 py-3 bg-white text-blue-600 font-medium rounded-lg border-2 border-blue-600 hover:bg-blue-50 transition-colors duration-300"
            >
              Sign In
            </button>
          </div>
          <p className="mt-6 text-sm text-gray-500">
            No credit card required • Free to get started
          </p>
        </motion.div>

        {/* Additional Resources */}
        <div className="mt-12 bg-blue-50 rounded-lg p-6">
          <h3 className="font-semibold text-gray-900 mb-4">
            Want to learn more?
          </h3>
          <div className="flex flex-col sm:flex-row gap-4">
            <StyledNavLink to="/auto-apply/how-it-works">
              How Auto Apply Works
            </StyledNavLink>

            <StyledNavLink to="/auto-apply/success-stories">
              Success Stories
            </StyledNavLink>

            <StyledNavLink to="/auto-apply/faq">
              Frequently Asked Questions
            </StyledNavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoApplyUpsell;

import React, { useState } from 'react';
import {
  Compass,
  AlertCircle,
  Eye,
  Globe2,
  RefreshCw,
  Lock
} from 'lucide-react';

interface VirtualTourProps {
  tourUrl: string;
}

type TourProvider = {
  name: string;
  pattern: RegExp;
  embedUrl: (match: RegExpMatchArray) => string;
  aspectRatio: number; // Defines the aspect ratio (height/width * 100)
  validateId?: (id: string) => boolean;
};

const tourProviders: TourProvider[] = [
  {
    name: 'Matterport',
    pattern: /(?:my\.)?matterport\.com\/show\/\?m=([A-Za-z0-9]+)/,
    embedUrl: (match) =>
      `https://my.matterport.com/show/?m=${match[1]}&play=1&qs=1`,
    aspectRatio: 56.25
  },
  {
    name: 'My3DTour',
    pattern: /my3dtour\.com\/(?:tour|view)\/([A-Za-z0-9-]+)/,
    embedUrl: (match) => `https://my3dtour.com/embed/${match[1]}`,
    aspectRatio: 75 // 4:3 ratio
  },
  {
    name: 'VPiX',
    pattern: /vpix\.com\/tour\/([A-Za-z0-9-]+)/,
    embedUrl: (match) => `https://vpix.com/embed/${match[1]}`,
    aspectRatio: 56.25
  },
  {
    name: 'Kuula',
    pattern: /kuula\.co\/share\/collection\/([A-Za-z0-9-]+)/,
    embedUrl: (match) =>
      `https://kuula.co/share/collection/${match[1]}?fs=1&vr=0&zoom=1&autopalt=1`,
    aspectRatio: 66.67
  },
  {
    name: 'ThreeSixty',
    pattern: /360virtualtour\.com\/tour\/([A-Za-z0-9-]+)/,
    embedUrl: (match) => `https://360virtualtour.com/embed/${match[1]}`,
    aspectRatio: 56.25
  }
];

const VirtualTour: React.FC<VirtualTourProps> = ({ tourUrl }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const identifyProvider = (
    url: string
  ): { provider: TourProvider; match: RegExpMatchArray } | null => {
    try {
      const normalizedUrl = url.toLowerCase().trim();

      for (const provider of tourProviders) {
        const match = normalizedUrl.match(provider.pattern);
        if (match) {
          return { provider, match };
        }
      }
      return null;
    } catch (error) {
      console.error('Error identifying tour provider:', error);
      return null;
    }
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
    setHasError(false);
  };

  const handleIframeError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  const handleRetry = () => {
    setIsLoading(true);
    setHasError(false);
    setRetryCount((prev) => prev + 1);
  };

  const renderErrorMessage = () => (
    <div className="bg-white p-8 rounded-xl shadow-lg">
      <div className="flex items-center justify-center p-6 bg-gray-50 rounded-lg border-2 border-dashed border-gray-200">
        <div className="text-center max-w-md">
          <AlertCircle className="w-12 h-12 mx-auto text-red-500 mb-3" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            Virtual Tour Not Available
          </h3>
          <div className="space-y-4">
            <p className="text-sm text-gray-600">This could be due to:</p>
            <ul className="text-sm text-gray-600 space-y-2">
              <li className="flex items-center justify-center">
                <Lock className="w-4 h-4 mr-2" />
                Private or password-protected tour
              </li>
              <li className="flex items-center justify-center">
                <Eye className="w-4 h-4 mr-2" />
                Archived or deleted tour
              </li>
              <li className="flex items-center justify-center">
                <Globe2 className="w-4 h-4 mr-2" />
                Browser compatibility issues
              </li>
            </ul>
            <div className="flex justify-center space-x-4 mt-4">
              <button
                onClick={handleRetry}
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <RefreshCw className="w-4 h-4 mr-2" />
                Retry
              </button>
              <a
                href={tourUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-500 bg-blue-50 rounded-md hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Open Original Link
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const result = tourUrl ? identifyProvider(tourUrl) : null;

  if (!tourUrl) {
    return null;
  }

  if (!result) {
    return renderErrorMessage();
  }

  const { provider, match } = result;
  const embedUrl = provider.embedUrl(match);

  return (
    <div className="bg-white p-8 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 flex items-center">
        <Compass className="w-7 h-7 mr-3 text-blue-500" />
        Virtual Tour
        <span className="ml-3 text-sm font-normal text-gray-500">
          Powered by {provider.name}
        </span>
      </h2>

      <div
        className={`relative w-full rounded-lg overflow-hidden shadow-md`}
        style={{ paddingBottom: `${provider.aspectRatio}%` }}
      >
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <div className="flex items-center space-x-2">
              <RefreshCw className="w-6 h-6 text-blue-500 animate-spin" />
              <span className="text-gray-600">Loading virtual tour...</span>
            </div>
          </div>
        )}
        {hasError ? (
          renderErrorMessage()
        ) : (
          <iframe
            key={retryCount} // Force iframe refresh on retry
            src={embedUrl}
            allowFullScreen
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            className="absolute top-0 left-0 w-full h-full rounded-lg"
            title="Property Virtual Tour"
            loading="lazy"
            onLoad={handleIframeLoad}
            onError={handleIframeError}
          />
        )}
      </div>
    </div>
  );
};

export default VirtualTour;

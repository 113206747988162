import React from 'react';
import { Link } from 'react-router-dom';
import {
  CurrencyDollarIcon,
  ChartBarIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import image17 from '../../../src/media/images/Two_people_looking_happy_that_they_found_their_dre (1).png';
import { useAuth } from '../../auth/firebaseAuth';

const PropertyListingLandingPage: React.FC = () => {
  const { currentUser } = useAuth();
  return (
    <div className="">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24">
        <header className="text-center mb-20">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-indigo-900 mb-4">
            List Your Property on RentSmart.ie
          </h1>
          <p className="text-xl text-indigo-600 max-w-3xl mx-auto">
            Join thousands of successful landlords who trust RentSmart.ie to
            find the perfect tenants for their properties.
          </p>
        </header>

        <section className="grid md:grid-cols-2 gap-12 mb-20">
          <div className="space-y-6">
            <FeatureItem
              icon={<CurrencyDollarIcon className="h-8 w-8 text-green-500" />}
              title="Maximize Your Rental Income"
              description="Our advanced pricing algorithm ensures you get the best price for your property in the current market."
            />
            <FeatureItem
              icon={<ChartBarIcon className="h-8 w-8 text-blue-500" />}
              title="Reach a Wider Audience"
              description="With our extensive marketing network, your property gets maximum exposure to potential tenants."
            />
            <FeatureItem
              icon={<UserGroupIcon className="h-8 w-8 text-purple-500" />}
              title="Quality Tenant Matching"
              description="Our sophisticated matching system helps you find reliable, long-term tenants that suit your preferences."
            />
          </div>
          <div className="flex items-center justify-center">
            <img
              src={image17}
              alt="Happy landlord"
              className="rounded-lg shadow-2xl"
            />
          </div>
        </section>

        <section className="text-center mb-20">
          <h2 className="text-3xl font-bold text-indigo-900 mb-6">
            Trusted by Thousands of Landlords
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <Testimonial
              quote="RentSmart.ie helped me find a great tenant in just 3 days! Their platform is incredibly easy to use."
              author="Sarah L., Dublin"
            />
            <Testimonial
              quote="I've been using RentSmart.ie for years. It's the best platform for property listings, hands down."
              author="Michael O., Cork"
            />
            <Testimonial
              quote="The support team at RentSmart.ie is amazing. They're always there to help with any questions."
              author="Emma R., Galway"
            />
          </div>
        </section>

        <section className="text-center">
          <h2 className="text-3xl font-bold text-indigo-900 mb-6">
            Ready to Get Started?
          </h2>

          {currentUser ? (
            <Link
              to="/dashboard"
              className="inline-block bg-indigo-600 text-white px-8 py-4 rounded-full font-semibold text-lg shadow-lg hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105"
            >
              Go to listing dashboard
            </Link>
          ) : (
            <Link
              to="/signup"
              className="inline-block bg-indigo-600 text-white px-8 py-4 rounded-full font-semibold text-lg shadow-lg hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-105"
            >
              Create Your Account Now
            </Link>
          )}
        </section>
      </div>
    </div>
  );
};

const FeatureItem: React.FC<{
  icon: React.ReactNode;
  title: string;
  description: string;
}> = ({ icon, title, description }) => (
  <div className="flex items-start">
    <div className="flex-shrink-0 mr-4">{icon}</div>
    <div>
      <h3 className="text-xl font-semibold text-indigo-900 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Testimonial: React.FC<{ quote: string; author: string }> = ({
  quote,
  author
}) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <p className="text-gray-600 italic mb-4">"{quote}"</p>
    <p className="text-indigo-600 font-semibold">{author}</p>
  </div>
);

export default PropertyListingLandingPage;

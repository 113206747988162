import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

interface StyledNavLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  showArrow?: boolean;
}

const StyledNavLink: React.FC<StyledNavLinkProps> = ({
  to,
  children,
  className = '',
  showArrow = true
}) => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(to)}
      className={`text-blue-600 hover:text-blue-700 flex items-center ${className}`}
    >
      {children}
      {showArrow && <ArrowRightIcon className="w-4 h-4 ml-1" />}
    </button>
  );
};

export default StyledNavLink;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { queryDocuments } from '../../utility/firebaseHelpers';
import { Listing } from '../../components/property/property-card/PropertyCardTypes';
import PropertyCard from '../../components/property/property-card/PropertyCard';
import { where } from 'firebase/firestore';
import { User } from '../../types/userTypes';
import { Timestamp } from 'firebase/firestore';
import {
  PhoneIcon,
  EnvelopeIcon,
  CalendarIcon,
  HomeIcon
} from '@heroicons/react/24/outline';
import ShareModal from '../../components/property/property-card/ShareModal';

const ITEMS_PER_PAGE = 9;

const LandlordListingsPage: React.FC = () => {
  const { owner } = useParams<{ owner: string }>();
  const [listings, setListings] = useState<Listing[]>([]);
  const [landlord, setLandlord] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [bookmarkedListings, setBookmarkedListings] = useState<number[]>([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [currentShareUrl, setCurrentShareUrl] = useState('');
  const [currentShareTitle, setCurrentShareTitle] = useState('');

  useEffect(() => {
    const fetchListings = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const constraints = [
          where('propertyOwnerUid', '==', owner),
          where('status', '==', 'active')
        ];
        const fetchedListings = await queryDocuments<Listing>(
          'listings',
          constraints
        );
        if (fetchedListings.length > 0) setLandlord(fetchedListings[0].user);
        setListings(fetchedListings);
      } catch (err) {
        console.error('Error fetching listings:', err);
        setError('Failed to fetch listings. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, [owner]);

  const totalPages = Math.ceil(listings.length / ITEMS_PER_PAGE);
  const paginatedListings = listings.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleBookmarkToggle = (id: number) => {
    setBookmarkedListings((prev) =>
      prev.includes(id)
        ? prev.filter((listingId) => listingId !== id)
        : [...prev, id]
    );
  };

  const handleShare = (listing: Listing) => {
    const shareUrl = `${window.location.origin}/property/${listing.id}/${listing.user.id}`;
    const shareTitle = `Check out this property: ${listing.title}`;
    setCurrentShareUrl(shareUrl);
    setCurrentShareTitle(shareTitle);
    setIsShareModalOpen(true);
  };

  if (isLoading) return <div className="text-center py-8">Loading...</div>;
  if (error)
    return <div className="text-center py-8 text-red-500">{error}</div>;
  if (listings.length === 0)
    return (
      <div className="text-center py-8">
        No listings found for this landlord.
      </div>
    );

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Enhanced Landlord Information Section */}
      {landlord && (
        <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-8 rounded-2xl shadow-lg mb-12 overflow-hidden relative">
          <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-blue-300 to-indigo-300 rounded-full opacity-20 transform translate-x-1/3 -translate-y-1/3"></div>
          <div className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-8 relative z-10">
            <div className="relative flex-shrink-0">
              <img
                src={landlord.photoUrl || 'https://via.placeholder.com/150'}
                alt={landlord.name}
                className="w-32 h-32 rounded-full shadow-xl object-cover border-4 border-white"
              />
              <div className="absolute bottom-0 right-0 bg-green-400 w-6 h-6 rounded-full border-4 border-white"></div>
            </div>
            <div className="flex-grow">
              <h2 className="text-3xl font-bold text-gray-800 mb-2">
                {landlord.name}
              </h2>
              <p className="text-gray-600 mb-4 max-w-2xl">{landlord.bio}</p>
              <div className="flex flex-wrap gap-4">
                <div className="flex items-center text-gray-600">
                  <CalendarIcon className="w-5 h-5 mr-2 text-blue-500" />
                  <span className="text-sm">
                    Member since:{' '}
                    {landlord.accountCreationDate instanceof Timestamp
                      ? landlord.accountCreationDate
                          .toDate()
                          .toLocaleDateString()
                      : 'N/A'}
                  </span>
                </div>
                <div className="flex items-center text-gray-600">
                  <HomeIcon className="w-5 h-5 mr-2 text-blue-500" />
                  <span className="text-sm">{listings.length} Properties</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-4 flex-shrink-0">
              {landlord.listingPreferences.showMobile && (
                <a
                  href={`tel:${landlord.phone}`}
                  className="flex items-center justify-center px-4 py-2 bg-white text-blue-600 rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-300"
                >
                  <PhoneIcon className="w-5 h-5 mr-2" />
                  {landlord.phone}
                </a>
              )}
              {landlord.listingPreferences.showEmail && (
                <a
                  href={`mailto:${landlord.email}`}
                  className="flex items-center justify-center px-4 py-2 bg-white text-blue-600 rounded-lg shadow-md hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-300"
                >
                  <EnvelopeIcon className="w-5 h-5 mr-2" />
                  Contact
                </a>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Listings Section */}
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">
        Listings by {landlord?.name || 'Landlord'}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {paginatedListings.map((listing) => (
          <PropertyCard
            key={listing.id}
            listing={listing}
            isBookmarked={bookmarkedListings.includes(listing.id)}
            onBookmarkToggle={handleBookmarkToggle}
            onShare={handleShare}
          />
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-12 flex justify-center">
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
              <button
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium transition-colors duration-150 ${
                  page === currentPage
                    ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                    : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                }`}
              >
                {page}
              </button>
            ))}
          </nav>
        </div>
      )}

      {/* Share Modal */}
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        url={currentShareUrl}
        title={currentShareTitle}
      />
    </div>
  );
};

export default LandlordListingsPage;

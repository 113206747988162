// src/data/propertyTemplates.ts

import { Amenity, PropertyType } from '../components/property/property-card/PropertyCardTypes';


// Update interface to use specific amenity types
export interface PropertyTemplate {
  title: string;
  type: PropertyType;
  description: string;
  bedrooms: number;
  bathrooms: number;
  amenities: Amenity[];
  size: number;
}

export const propertyTemplates: Record<string, PropertyTemplate> = {
  singleBed: {
    title: 'Single Bedroom in Shared House',
    type: 'Room',
    description: `Cozy single bedroom available in a well-maintained shared house. The room comes fully furnished with a single bed, desk, chair, and wardrobe. Perfect for students or young professionals.

The property offers access to shared facilities including a fully equipped kitchen, comfortable living room, and modern bathroom. Located in a quiet residential area with excellent transport links and local amenities nearby.

Bills include electricity, heating, water, and high-speed internet connection.`,
    bedrooms: 1,
    bathrooms: 1,
    size: 12,
    amenities: [
      'Wi-Fi',
      'Furnished',
      'Washer/Dryer',
      'Dishwasher',
      'Central Heating',
      'Storage Space'
    ]
  },

  doubleBed: {
    title: 'Spacious Double Room',
    type: 'Room',
    description: `Bright and spacious double bedroom available in a modern shared house. The room features a comfortable double bed, large wardrobe, study desk, and plenty of natural light.

The house offers excellent shared facilities including a modern kitchen with all appliances, welcoming living room, and well-maintained bathrooms. Ideal location with easy access to public transport, shops, and local amenities.

All bills are included in the rent: electricity, gas, water, and fiber-optic broadband.`,
    bedrooms: 1,
    bathrooms: 1,
    size: 16,
    amenities: [
      'Wi-Fi',
      'Furnished',
      'Washer/Dryer',
      'Dishwasher',
      'Central Heating',
      'Storage Space',
      'Garden'
    ]
  },

  oneBedroomApt: {
    title: 'Modern One-Bedroom Apartment',
    type: 'Apartment',
    description: `Contemporary one-bedroom apartment perfect for singles or couples. This well-designed living space features an open-plan kitchen and living area, a generous double bedroom, and a modern bathroom.

The apartment comes with high-quality fixtures and fittings throughout. The kitchen is fully equipped with modern appliances, and the living area offers comfortable space for relaxing and dining. The bedroom includes built-in wardrobes and ample storage space.

Located in a secure building with excellent accessibility to local amenities, shopping centers, and public transport links.`,
    bedrooms: 1,
    bathrooms: 1,
    size: 45,
    amenities: [
      'Parking',
      'Furnished',
      'Washer/Dryer',
      'Dishwasher',
      'Wi-Fi',
      'Central Heating',
      'Security System',
      'Elevator',
      'Balcony'
    ]
  },

  twoBedroomApt: {
    title: 'Spacious Two-Bedroom Apartment',
    type: 'Apartment',
    description: `Beautiful two-bedroom apartment offering generous living space and modern comfort. Features a large open-plan living and dining area, fully fitted kitchen, two double bedrooms, and a family bathroom.

The master bedroom includes an en-suite bathroom and built-in wardrobes. The second bedroom is also a good-sized double room with ample storage space. The main living area is bright and airy, perfect for entertaining or relaxing.

The apartment is situated in a well-maintained development with secure entry system and designated parking.`,
    bedrooms: 2,
    bathrooms: 2,
    size: 75,
    amenities: [
      'Parking',
      'Furnished',
      'Washer/Dryer',
      'Dishwasher',
      'Wi-Fi',
      'Central Heating',
      'Security System',
      'Elevator',
      'Balcony',
      'Storage Space',
      'Air Conditioning'
    ]
  },

  threeBedroomApt: {
    title: 'Luxurious Three-Bedroom Apartment',
    type: 'Apartment',
    description: `Exceptional three-bedroom apartment offering spacious family accommodation. This premium property features a stunning open-plan kitchen and living area, three generously sized bedrooms, and two modern bathrooms.

The master bedroom benefits from an en-suite bathroom and walk-in wardrobe. Two additional double bedrooms share a family bathroom. The heart of the home is the expansive living area, complete with a modern fitted kitchen and space for dining and relaxing.

Located in a prestigious development with concierge service, secure parking, and beautifully maintained communal gardens.`,
    bedrooms: 3,
    bathrooms: 2,
    size: 95,
    amenities: [
      'Parking',
      'Furnished',
      'Washer/Dryer',
      'Dishwasher',
      'Wi-Fi',
      'Central Heating',
      'Security System',
      'Elevator',
      'Balcony',
      'Storage Space',
      'Air Conditioning',
      'Gym',
      'Garden',
      'Pet-friendly'
    ]
  }
};

export const getTemplateNames = () => ({
  singleBed: 'Single Bedroom',
  doubleBed: 'Double Bedroom',
  oneBedroomApt: 'One-Bedroom Apartment',
  twoBedroomApt: 'Two-Bedroom Apartment',
  threeBedroomApt: 'Three-Bedroom Apartment'
});

// src/data/locations.ts

const counties = [
  'Carlow',
  'Cavan',
  'Clare',
  'Cork',
  'Donegal',
  'Dublin',
  'Galway',
  'Kerry',
  'Kildare',
  'Kilkenny',
  'Laois',
  'Leitrim',
  'Limerick',
  'Longford',
  'Louth',
  'Mayo',
  'Meath',
  'Monaghan',
  'Offaly',
  'Roscommon',
  'Sligo',
  'Tipperary',
  'Waterford',
  'Westmeath',
  'Wexford',
  'Wicklow'
];

const popularTowns = [
  // Dublin Areas
  'South Dublin',
  'North Dublin',
  'Dublin City Centre',
  'Dublin 1',
  'Dublin 2',
  'Dublin 3',
  'Dublin 4',
  'Dublin 6',
  'Dublin 6W',
  'Dublin 7',
  'Dublin 8',
  'Dublin 9',
  'Dublin 10',
  'Dublin 11',
  'Dublin 12',
  'Dublin 13',
  'Dublin 14',
  'Dublin 15',
  'Dublin 16',
  'Dublin 17',
  'Dublin 18',
  'Dublin 20',
  'Dublin 22',
  'Dublin 24',
  'Lucan',
  'Blanchardstown',
  'Tallaght',
  'Clondalkin',
  'Blackrock',
  'Dún Laoghaire',
  'Malahide',
  'Swords',
  'Howth',

  // Major towns and cities outside Dublin
  'Athy',
  'Drogheda',
  'Dundalk',
  'Bray',
  'Naas',
  'Tralee',
  'Ennis',
  'Letterkenny',
  'Navan',
  'Portlaoise',
  'Balbriggan',
  'Athlone',
  'Celbridge',
  'Clonmel',
  'Newbridge',
  'Carlow Town',
  'Tullamore',
  'Portarlington',
  'Longford Town',
  'Tullow',
  'Birr',
  'Castlebar',
  'Tramore',
  'Portmarnock',
  'Cobh',
  'Midleton',
  'Bantry',
  'Nenagh',
  'Gorey',
  'Ballinasloe',
  'Clifden',
  'Galway City',
  'Sligo Town',
  'Mallow',
  'Bunclody',
  'Westport',
  'Bundoran',

  // Additional relevant towns across Ireland
  'Ballina',
  'Killarney',
  'Carrick-on-Shannon',
  'Trim',
  'Thurles',
  'Skibbereen',
  'Roscommon Town',
  'Castleblayney',
  'New Ross',
  'Kinsale',
  'Carrigaline',
  'Dungarvan',
  'Tuam',
  'Ballyshannon',
  'Kilkenny City',
  'Monaghan Town',
  'Arklow',
  'Edenderry',
  'Kilrush',
  'Killorglin',
  'Castlerea',
  'Ballybofey',
  'Loughrea',
  'Fermoy',
  'Listowel',
  'Bandon',
  'Abbeyfeale',
  'Virginia',
  'Ashbourne',
  'Roscrea',
  'Cashel',
  'Mitchelstown',
  'Ballybay',
  'Dingle',
  'Lismore',
  'Rathdrum',
  'Ballaghaderreen'
];

// Helper function to normalize text for searching
export const normalizeText = (text: string): string => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    .replace(/[^a-z0-9\s]/g, ''); // Remove special characters
};

// Helper function to safely extract number from string
const extractNumber = (text: string, pattern: RegExp): string | null => {
  const match = text.match(pattern);
  return match ? match[0] : null;
};

// Create location variants for better matching
const createLocationVariants = (location: string): string[] => {
  const normalized = normalizeText(location);
  const variants = [location.toLowerCase()];

  // Handle Dublin postal codes
  if (normalized.startsWith('dublin')) {
    const dublinVariants = [
      location.toLowerCase(),
      location.toLowerCase().replace('dublin ', 'd'),
      normalized
    ];

    const dublinNumber = extractNumber(location, /\d+/);
    if (dublinNumber && location.match(/dublin \d+/i)) {
      dublinVariants.push(`d${dublinNumber}`);
      dublinVariants.push(`dublin${dublinNumber}`);
    }
    return dublinVariants;
  }

  // Handle Dun Laoghaire variations
  if (normalized.includes('laoghaire')) {
    variants.push('dun laoghaire');
    variants.push('dunlaoghaire');
    variants.push('dun laoire');
    variants.push('dunlaoire');
  }

  return variants;
};

// Create searchable location data
export const locationData = Array.from(new Set([...counties, ...popularTowns]))
  .map(location => ({
    original: location,
    searchTerms: createLocationVariants(location),
    type: counties.includes(location) ? 'county' : 'town'
  }));

// Function to get location suggestions
export const getLocationSuggestions = (input: string, limit = 10): {
  original: string;
  type: string;
  matchQuality: number;
}[] => {
  const searchTerm = normalizeText(input);
  if (!searchTerm) return [];

  return locationData
    .map(location => {
      // Check for matches in search terms
      const bestMatch = location.searchTerms.reduce(
        (best, term) => {
          // Exact match
          if (term === searchTerm) return 1;
          
          // Starts with search term
          if (term.startsWith(searchTerm)) return 0.9;
          
          // Contains search term
          if (term.includes(searchTerm)) return 0.8;
          
          // Partial match (matching characters in order)
          let searchIndex = 0;
          let termIndex = 0;
          let matchedChars = 0;
          
          while (searchIndex < searchTerm.length && termIndex < term.length) {
            if (searchTerm[searchIndex] === term[termIndex]) {
              matchedChars++;
              searchIndex++;
            }
            termIndex++;
          }
          
          const partialMatchScore = matchedChars / searchTerm.length * 0.7;
          
          return Math.max(best, partialMatchScore);
        },
        0
      );

      return {
        original: location.original,
        type: location.type,
        matchQuality: bestMatch
      };
    })
    .filter(result => result.matchQuality > 0)
    .sort((a, b) => b.matchQuality - a.matchQuality)
    .slice(0, limit);
};

// Function to format location for display
export const formatLocation = (location: string): string => {
  // Format Dublin postal codes
  const dublinMatch = location.match(/^d\d+$/i);
  if (dublinMatch) {
    const number = extractNumber(location, /\d+/);
    if (number) {
      return `Dublin ${number}`;
    }
  }
  
  // Capitalize first letter of each word
  return location
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const locations = Array.from(new Set([...counties, ...popularTowns]));

import React, { useState } from 'react';
import { ArrowDownTrayIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Message } from './messagesTypes';
import { Timestamp } from 'firebase/firestore';
import FullScreenImageViewer from './FullScreenImageViewer';

interface MessageItemProps {
  message: Message;
  isOwnMessage: boolean;
  namePhoto: { [key: string]: { name: string; photoUrl: string | null } };
}

const MessageItem: React.FC<MessageItemProps> = ({
  message,
  isOwnMessage,
  namePhoto
}) => {

    const [fullScreenImage, setFullScreenImage] = useState<string | null>(null);
    
  const getStatusIcon = (status: Message['status']) => {
    return <CheckIcon className="h-4 w-4 text-gray-400" />;
  };

   const handleImageClick = (imageUrl: string) => {
     setFullScreenImage(imageUrl);
   };

  return (
    <div
      className={`mb-4 flex ${isOwnMessage ? 'justify-end' : 'justify-start'}`}
    >
      <div
        className={`max-w-[80%] p-3 rounded-xl ${
          isOwnMessage
            ? 'bg-blue-500 text-white rounded-br-sm'
            : 'bg-white text-gray-800 rounded-bl-sm'
        } shadow`}
      >
        {message.attachmentUrl && (
          <div className="mb-2">
            {message.attachmentType?.startsWith('image/') ? (
              <img
                src={message.attachmentUrl}
                alt="Attachment"
                className="max-w-full rounded cursor-pointer"
                onClick={() => handleImageClick(message.attachmentUrl!)}
              />
            ) : (
              <div className="flex items-center">
                <a
                  href={message.attachmentUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-300 underline mr-2"
                >
                  View Attachment
                </a>
                <a
                  href={message.attachmentUrl}
                  download
                  className="text-blue-300 hover:text-blue-400"
                >
                  <ArrowDownTrayIcon className="h-5 w-5" />
                </a>
              </div>
            )}
          </div>
        )}
        <p>{message.content}</p>
        <div className="text-xs mt-1 flex items-center justify-end space-x-1">
          {getStatusIcon(message.status)}
          <span>
            {message.timestamp && message.timestamp instanceof Timestamp
              ? message.timestamp.toDate().toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit'
                })
              : 'Sending...'}
          </span>
        </div>
      </div>
      {fullScreenImage && (
        <FullScreenImageViewer
          imageUrl={fullScreenImage}
          onClose={() => setFullScreenImage(null)}
        />
      )}
    </div>
  );
};

export default MessageItem;

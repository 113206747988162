import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../auth/firebaseAuth';
import { queryDocuments } from '../../utility/firebaseHelpers';
import { Timestamp, where } from 'firebase/firestore';
import ActiveListingsSnapshot from '../../components/active-listings-snapshot/ActiveListingsSnapshot';
import DashboardOverview from '../../components/dashboard-overview/DashboardOverview';
import NotificationsComponent from '../../components/notifications/NotificationsComponent';
import RecentMessagesPreview from '../../components/recent-messages-preview/RecentMessagesPreview';
import PropertyToolsGrid from '../../components/property/property-grid/PropertyToolsGrid';
import { Listing } from '../../components/property/property-card/PropertyCardTypes';
import { Notification } from '../../components/notifications/NotificationsComponent';
import { GuestInquiry } from '../../components/messages/ConversationList/messagesTypes';
import SocialProof from '../../components/social-proof/SocialProof';
import IdentityVerificationForm from '../../components/identity-verification-form/IdentityVerificationForm';
import { remoteConfig } from '../../utility/firebaseRemoteConfigHelper';

const LandlordDashboard: React.FC = () => {
  const { currentUser, userProfile } = useAuth();
  const [listings, setListings] = useState<Listing[]>([]);
  const [pendingInquiries, setPendingInquiries] = useState<GuestInquiry[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState<boolean>(true);

  const generateNotifications = useCallback(
    (listings: Listing[]): Notification[] => {
      const notifications: Notification[] = [];
      const now = new Date();

      listings.forEach((listing) => {
        const { id, title, dateAdded, expirationDate, propertyViews } = listing;

        if (dateAdded instanceof Timestamp) {
          const listingDate = dateAdded.toDate();
          const daysSinceListing = Math.ceil(
            (now.getTime() - listingDate.getTime()) / (1000 * 60 * 60 * 24)
          );
          if (daysSinceListing <= 3) {
            notifications.push({
              id: `${id}-new-listing`,
              message: `Your new listing "${title}" is now active.`,
              type: 'info'
            });
          }
        }

        if (expirationDate instanceof Timestamp) {
          const expDate = expirationDate.toDate();
          const daysUntilExpiration = Math.ceil(
            (expDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
          );
          if (daysUntilExpiration <= 7 && daysUntilExpiration > 0) {
            notifications.push({
              id: `${id}-expiring-soon`,
              message: `Your listing "${title}" will expire in ${daysUntilExpiration} days.`,
              type: 'warning'
            });
          } else if (daysUntilExpiration <= 0) {
            notifications.push({
              id: `${id}-expired`,
              message: `Your listing "${title}" has expired.`,
              type: 'alert'
            });
          }
        }

        if (propertyViews && propertyViews > 100) {
          notifications.push({
            id: `${id}-high-views`,
            message: `Your listing "${title}" has received high interest with over ${propertyViews} views!`,
            type: 'success'
          });
        }

        if (propertyViews && propertyViews < 10) {
          notifications.push({
            id: `${id}-low-views`,
            message: `Consider updating your listing "${title}" for better visibility. It's received only ${propertyViews} views recently.`,
            type: 'info'
          });
        }
      });

      if (!isVerified) {
        notifications.push({
          id: 'verification-needed',
          message:
            'Please verify your identity to access all landlord features.',
          type: 'warning'
        });
      }
      return notifications;
    },
    [isVerified]
  );

  useEffect(() => {
    const fetchListings = async () => {
      if (!currentUser) return;

      setIsLoading(true);
      setError(null);

      try {
        const constraints = [where('propertyOwnerUid', '==', currentUser.uid)];
        const fetchedListings = await queryDocuments<Listing>(
          'listings',
          constraints
        );
        setListings(fetchedListings);
        setNotifications(generateNotifications(fetchedListings));
      } catch (err) {
        console.error('Error fetching listings:', err);
        setError('Failed to fetch listings. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, [currentUser, generateNotifications]);

  useEffect(() => {
    const checkVerificationStatus = async () => {
      if (!currentUser) return;
      try {
        const verifications = await queryDocuments('identityVerification', [
          where('userId', '==', currentUser.uid),
          where('status', '==', 'approved')
        ]);
        setIsVerified(verifications.length > 0);
      } catch (err) {
        console.error('Error checking verification status:', err);
      }
    };

    checkVerificationStatus();
  }, [currentUser]);

  useEffect(() => {
    const fetchInquiries = async () => {
      if (!currentUser || !listings.length) return;

      try {
        const listingIds = listings.map((listing) => listing.id.toString());
        const constraints = [
          where('listingId', 'in', listingIds),
          where('recipientId', '==', currentUser.uid)
        ];

        const fetchedInquiries = await queryDocuments<GuestInquiry>(
          'guestInquiries',
          constraints
        );
        setPendingInquiries(fetchedInquiries);
      } catch (err) {
        console.error('Error fetching inquiries:', err);
        setError('Failed to fetch inquiries. Please try again later.');
      }
    };

    fetchInquiries();
  }, [currentUser, listings]);

  if (isLoading) {
    return <div className="text-center py-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center py-8 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Dashboard</h1>

      {!isVerified && remoteConfig.requireIdentityVerification() && (
        <div className="mb-8">
          <IdentityVerificationForm />
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <DashboardOverview
          isVerified={isVerified}
          landlordName={toTitleCase(userProfile?.name ?? '')}
          totalListings={listings.length}
          activeListings={listings.filter((l) => l.status === 'active').length}
          pendingInquiries={pendingInquiries.length}
        />
        <RecentMessagesPreview />
        <ActiveListingsSnapshot
          listings={listings.map((listing) => ({
            id: listing.id.toString(),
            imageUrl:
              listing.images[0] || 'https://via.placeholder.com/300x200',
            title: listing.title,
            views: listing.propertyViews,
            landlordId: listing.propertyOwnerUid
          }))}
        />
        <NotificationsComponent notifications={notifications} />
      </div>
      <PropertyToolsGrid />
      <SocialProof userType="landlord" />
    </div>
  );
};

function toTitleCase(text: string): string {
  return text?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export default LandlordDashboard;

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Blog } from '../../types/blogTypes';
import { tenantBlogs, landlordBlogs } from '../../data/blogData';

const BlogReadPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const blog: Blog | undefined = [...tenantBlogs, ...landlordBlogs].find(
    (b) => b.id === id
  );

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const renderCTA = () => {
    if (blog.category === 'tenant') {
      return (
        <div className="bg-blue-100 border-l-4 border-blue-500 p-4 mt-8">
          <h3 className="text-lg font-semibold text-blue-800 mb-2">
            Looking for Your Dream Rental?
          </h3>
          <p className="text-blue-700 mb-4">
            Start your search now and find the perfect home that suits your
            needs.
          </p>
          <Link
            to="/browse"
            className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Search Rentals
          </Link>
        </div>
      );
    } else if (blog.category === 'landlord') {
      return (
        <div className="bg-green-100 border-l-4 border-green-500 p-4 mt-8">
          <h3 className="text-lg font-semibold text-green-800 mb-2">
            Ready to List Your Property?
          </h3>
          <p className="text-green-700 mb-4">
            Reach thousands of potential tenants and manage your listings with
            ease.
          </p>
          <Link
            to="/dashboard"
            className="bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition duration-300"
          >
            List Your Property
          </Link>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold mb-6 text-gray-800">{blog.title}</h1>
      <img
        src={blog.image}
        alt={blog.title}
        className="w-full h-64 object-cover rounded-lg mb-8"
      />
      <div
        className="prose prose-lg"
        dangerouslySetInnerHTML={{ __html: blog.content }}
      />
      {renderCTA()}
    </div>
  );
};

export default BlogReadPage;

import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  QuestionMarkCircleIcon,
  MagnifyingGlassIcon,
  HomeIcon,
  UserIcon,
  ShieldCheckIcon,
  BanknotesIcon,
  DocumentTextIcon,
  ChatBubbleLeftRightIcon,
  ChevronRightIcon
} from '@heroicons/react/24/outline';

interface HelpCategory {
  id: string;
  title: string;
  icon: React.ForwardRefExoticComponent<any>;
  topics: HelpTopic[];
}

interface HelpTopic {
  id: string;
  title: string;
  content: string;
  tags: string[];
}

const helpCategories: HelpCategory[] = [
  {
    id: 'account',
    title: 'Account & Profile',
    icon: UserIcon,
    topics: [
      {
        id: 'create-account',
        title: 'How to create an account',
        content: 'To create an account, click on the "Sign Up" button in the top right corner...',
        tags: ['account', 'signup', 'registration']
      },
      {
        id: 'profile-settings',
        title: 'Managing your profile settings',
        content: 'You can manage your profile settings by going to your account dashboard...',
        tags: ['profile', 'settings', 'account']
      }
    ]
  },
  {
    id: 'listings',
    title: 'Property Listings',
    icon: HomeIcon,
    topics: [
      {
        id: 'create-listing',
        title: 'How to create a listing',
        content: 'To create a new property listing, navigate to your dashboard and click "Create Listing"...',
        tags: ['listing', 'property', 'create']
      },
      {
        id: 'edit-listing',
        title: 'Editing your listings',
        content: 'You can edit your listings at any time by going to your dashboard...',
        tags: ['listing', 'edit', 'update']
      }
    ]
  },
  {
    id: 'safety',
    title: 'Safety & Security',
    icon: ShieldCheckIcon,
    topics: [
      {
        id: 'safe-browsing',
        title: 'Safe browsing tips',
        content: 'When browsing listings, always verify the property details...',
        tags: ['safety', 'security', 'tips']
      },
      {
        id: 'report-issue',
        title: 'How to report an issue',
        content: 'If you encounter any suspicious activity...',
        tags: ['report', 'safety', 'security']
      }
    ]
  },
  {
    id: 'payments',
    title: 'Payments & Billing',
    icon: BanknotesIcon,
    topics: [
      {
        id: 'payment-methods',
        title: 'Accepted payment methods',
        content: 'We accept various payment methods including credit cards...',
        tags: ['payment', 'billing', 'methods']
      },
      {
        id: 'subscription',
        title: 'Managing your subscription',
        content: 'You can manage your subscription settings...',
        tags: ['subscription', 'billing', 'payment']
      }
    ]
  },
  {
    id: 'contracts',
    title: 'Contracts & Documents',
    icon: DocumentTextIcon,
    topics: [
      {
        id: 'lease-agreement',
        title: 'Understanding lease agreements',
        content: 'A lease agreement is a legal document that...',
        tags: ['contract', 'lease', 'agreement']
      },
      {
        id: 'document-upload',
        title: 'Uploading documents',
        content: 'To upload documents, navigate to your listing...',
        tags: ['documents', 'upload', 'files']
      }
    ]
  },
  {
    id: 'messaging',
    title: 'Messaging & Communication',
    icon: ChatBubbleLeftRightIcon,
    topics: [
      {
        id: 'messaging-system',
        title: 'Using the messaging system',
        content: 'Our messaging system allows you to communicate safely...',
        tags: ['messages', 'communication', 'chat']
      },
      {
        id: 'notifications',
        title: 'Managing notifications',
        content: 'You can customize your notification settings...',
        tags: ['notifications', 'alerts', 'settings']
      }
    ]
  }
];

const HelpCenterPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<HelpTopic | null>(null);

  const filteredCategories = useMemo(() => {
    if (!searchQuery) return helpCategories;

    return helpCategories.map(category => ({
      ...category,
      topics: category.topics.filter(topic =>
        topic.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        topic.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
        topic.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()))
      )
    })).filter(category => category.topics.length > 0);
  }, [searchQuery]);

  return (
    <div className="min-h-screen py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-12">
          <QuestionMarkCircleIcon className="mx-auto h-12 w-12 text-indigo-600" />
          <h1 className="mt-4 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Help Center
          </h1>
          <p className="mt-4 text-lg text-gray-500">
            Find answers to common questions and learn how to use RentSmart.ie
          </p>
        </div>

        {/* Search Bar */}
        <div className="max-w-2xl mx-auto mb-12">
          <div className="relative">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              type="text"
              placeholder="Search for help..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>

        {/* Content */}
        <div className="grid md:grid-cols-12 gap-8">
          {/* Categories Sidebar */}
          <div className="md:col-span-4 lg:col-span-3">
            <nav className="space-y-1">
              {filteredCategories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => {
                    setSelectedCategory(category.id);
                    setSelectedTopic(null);
                  }}
                  className={`w-full flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                    selectedCategory === category.id
                      ? 'bg-indigo-50 text-indigo-600'
                      : 'text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <category.icon
                    className={`mr-3 flex-shrink-0 h-6 w-6 ${
                      selectedCategory === category.id
                        ? 'text-indigo-600'
                        : 'text-gray-400'
                    }`}
                  />
                  <span className="truncate">{category.title}</span>
                  {category.topics.length > 0 && (
                    <span className="ml-auto text-xs text-gray-400">
                      {category.topics.length}
                    </span>
                  )}
                </button>
              ))}
            </nav>
          </div>

          {/* Topics & Content */}
          <div className="md:col-span-8 lg:col-span-9">
            {selectedTopic ? (
              // Topic Content View
              <div className="bg-white shadow rounded-lg p-6">
                <button
                  onClick={() => setSelectedTopic(null)}
                  className="text-sm text-indigo-600 hover:text-indigo-800 mb-4 flex items-center"
                >
                  ← Back to topics
                </button>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">
                  {selectedTopic.title}
                </h2>
                <div className="prose max-w-none">
                  {selectedTopic.content}
                </div>
              </div>
            ) : selectedCategory ? (
              // Topics List View
              <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
                {filteredCategories
                  .find(cat => cat.id === selectedCategory)
                  ?.topics.map((topic) => (
                    <button
                      key={topic.id}
                      onClick={() => setSelectedTopic(topic)}
                      className="w-full text-left p-6 hover:bg-gray-50 flex items-center justify-between"
                    >
                      <div>
                        <h3 className="text-lg font-medium text-gray-900">
                          {topic.title}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                          {topic.content}
                        </p>
                      </div>
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                    </button>
                  ))}
              </div>
            ) : (
              // Category Grid View (when no category is selected)
              <div className="grid gap-6 sm:grid-cols-2">
                {filteredCategories.map((category) => (
                  <button
                    key={category.id}
                    onClick={() => setSelectedCategory(category.id)}
                    className="relative rounded-lg p-6 bg-white shadow hover:shadow-md transition-shadow"
                  >
                    <div className="flex items-center">
                      <category.icon className="h-8 w-8 text-indigo-600" />
                      <h3 className="ml-3 text-xl font-medium text-gray-900">
                        {category.title}
                      </h3>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      {category.topics.length} articles
                    </p>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Contact Support Section */}
        <div className="mt-12 bg-indigo-50 rounded-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-indigo-900 mb-4">
            Still Need Help?
          </h2>
          <p className="text-indigo-700 mb-6">
            Can't find what you're looking for? Our support team is here to help.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Contact Support
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HelpCenterPage;

import { FieldValue, Timestamp } from 'firebase/firestore';
import { BaseUser } from '../types/userTypes';

// Helper function to create timestamp for consistent dates
const createTimestamp = (date: Date): FieldValue => {
  return Timestamp.fromDate(date);
};

export const dummyUsers: BaseUser[] = [
  {
    id: 'usr_l1',
    name: 'James Murphy',
    email: 'james.murphy@email.com',
    accountVerification: {
      emailVerified: true,
      phoneVerified: true,
      identityVerified: false
    },
    phone: '+353 83 123 4567',
    photoUrl: 'https://randomuser.me/api/portraits/men/32.jpg',
    accountCreationDate: createTimestamp(new Date('2023-09-15')),
    bio: 'Professional landlord with over 10 years of experience in property management. Committed to providing quality housing solutions in Dublin.',
    communicationPreferences: {
      emailOffers: true,
      pushNotifications: true
    },
    listingPreferences: {
      showMobile: true,
      showEmail: true
    },
    role: 'landlord'
  },
  {
    id: 'usr_r1',
    name: "Sarah O'Connor",
    email: 'sarah.oconnor@email.com',
    accountVerification: {
      emailVerified: true,
      phoneVerified: false,
      identityVerified: false
    },
    phone: '+353 87 765 4321',
    photoUrl: 'https://randomuser.me/api/portraits/women/44.jpg',
    accountCreationDate: createTimestamp(new Date('2024-01-20')),
    bio: 'Young professional working in tech, looking for a modern apartment in Dublin city center. Clean, quiet, and responsible tenant.',
    communicationPreferences: {
      emailOffers: false,
      pushNotifications: true
    },
    listingPreferences: {
      showMobile: false,
      showEmail: true
    },
    role: 'renter'
  },
  {
    id: 'usr_a1',
    name: 'Michael Collins',
    email: 'michael.collins@dublinestates.ie',
    accountVerification: {
      emailVerified: true,
      phoneVerified: true,
      identityVerified: false
    },
    phone: '+353 89 888 9999',
    photoUrl: 'https://randomuser.me/api/portraits/men/56.jpg',
    accountCreationDate: createTimestamp(new Date('2023-06-01')),
    bio: 'Licensed real estate agent with Dublin Estates. Specializing in residential properties across Dublin and surrounding areas. IPAV member.',
    communicationPreferences: {
      emailOffers: true,
      pushNotifications: true
    },
    listingPreferences: {
      showMobile: true,
      showEmail: true
    },
    role: 'agent'
  },
  {
    id: 'usr_l2',
    name: 'Emma Byrne',
    email: 'emma.byrne@email.com',
    accountVerification: {
      emailVerified: true,
      phoneVerified: true,
      identityVerified: false
    },
    phone: '+353 85 555 6666',
    accountCreationDate: createTimestamp(new Date('2024-02-10')),
    bio: 'Owner of several properties in the Rathmines area. Focused on providing comfortable student accommodation.',
    communicationPreferences: {
      emailOffers: false,
      pushNotifications: false
    },
    listingPreferences: {
      showMobile: false,
      showEmail: true
    },
    role: 'landlord'
  },
  {
    id: 'usr_r2',
    name: 'David Chen',
    email: 'david.chen@email.com',
    accountVerification: {
      emailVerified: true,
      phoneVerified: false,
      identityVerified: false
    },
    phone: '+353 83 777 8888',
    photoUrl: 'https://randomuser.me/api/portraits/men/67.jpg',
    accountCreationDate: createTimestamp(new Date('2024-03-01')),
    bio: 'International student at Trinity College. Looking for a quiet place to live while completing my masters degree.',
    communicationPreferences: {
      emailOffers: true,
      pushNotifications: true
    },
    listingPreferences: {
      showMobile: true,
      showEmail: true
    },
    role: 'renter'
  }
];

// Helper function to get a random user
export const getRandomUser = (): BaseUser => {
  const randomIndex = Math.floor(Math.random() * dummyUsers.length);
  return dummyUsers[randomIndex];
};

// Helper function to get a user by role
export const getUsersByRole = (
  role: 'landlord' | 'renter' | 'agent'
): BaseUser[] => {
  return dummyUsers.filter((user) => user.role === role);
};

// Helper function to get a user by ID
export const getUserById = (id: string): BaseUser | undefined => {
  return dummyUsers.find((user) => user.id === id);
};

import React from 'react';
import { EyeIcon } from '@heroicons/react/24/solid';

interface StatisticsProps {
  propertyViews: number;
}

const PropertyStatistics: React.FC<StatisticsProps> = ({ propertyViews }) => {
  return (
    <div className="bg-white p-8 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Statistics</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="flex items-center text-gray-600">
          <EyeIcon className="w-6 h-6 mr-3 text-blue-500" />
          <span>{propertyViews} Property Views</span>
        </div>
      </div>
    </div>
  );
};

export default PropertyStatistics;

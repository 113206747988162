import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ChevronRightIcon,
  UserCircleIcon,
  InboxIcon,
  PaperClipIcon
} from '@heroicons/react/24/solid';
import { useAuth } from '../../auth/firebaseAuth';
import { db } from '../../auth/firebaseConfig';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs
} from 'firebase/firestore';
import { getUserById } from '../../utility/firebaseHelpers';

interface Message {
  id: string;
  renterName: string;
  renterPhotoUrl?: string;
  propertyTitle: string;
  messageSnippet: string;
  timestamp: Date;
}

const RecentMessagesPreview: React.FC = () => {
  const { currentUser } = useAuth();
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRecentMessages = async () => {
      if (!currentUser) return;

      setIsLoading(true);
      setError(null);

      try {
        const conversationsRef = collection(db, 'conversations');
        const q = query(
          conversationsRef,
          where('participants', 'array-contains', currentUser.uid),
          orderBy('lastMessageTimestamp', 'desc'),
          limit(5)
        );

        const querySnapshot = await getDocs(q);
        const messagesPromises = querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const otherParticipantId = data.participants.find(
            (id: string) => id !== currentUser.uid
          );
          const otherUser = await getUserById(otherParticipantId);

          return {
            id: doc.id,
            renterName: otherUser?.name || 'Unknown User',
            renterPhotoUrl: otherUser?.photoUrl,
            propertyTitle: data.propertyTitle || 'Unnamed Property',
            messageSnippet: data.lastMessage || 'No message content',
            timestamp: data.lastMessageTimestamp?.toDate() || new Date()
          };
        });

        const fetchedMessages = await Promise.all(messagesPromises);
        setMessages(fetchedMessages);
      } catch (err) {
        console.error('Error fetching recent messages:', err);
        setError('Failed to load recent messages. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecentMessages();
  }, [currentUser]);

  if (isLoading) {
    return <div className="text-center">Loading recent messages...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-3xl font-extrabold mb-6 text-gray-800 flex items-center">
        <InboxIcon className="w-8 h-8 text-blue-500 mr-2" />
        Recent Messages
      </h2>
      <div className="space-y-6">
        {messages.length > 0 ? (
          <>
            {messages.map((message) => {
              const isAttachment = message.messageSnippet === 'Attachment';
              return (
                <Link
                  to={`/messages/${message.id}`}
                  className="block group bg-white rounded-xl shadow-sm hover:shadow-md 
                 border border-gray-100 hover:border-blue-200 
                 transition-all duration-300 ease-in-out overflow-hidden"
                >
                  <div className="p-4 sm:p-5">
                    <div className="flex items-center justify-between">
                      {/* Left side - Avatar and Content */}
                      <div className="flex items-start space-x-4 flex-1 min-w-0">
                        {/* Avatar */}
                        <div className="flex-shrink-0">
                          {message.renterPhotoUrl ? (
                            <div className="relative">
                              <img
                                src={message.renterPhotoUrl}
                                alt={message.renterName}
                                className="w-16 h-16 rounded-full object-cover 
                             ring-2 ring-white shadow-sm"
                              />
                            </div>
                          ) : (
                            <div
                              className="w-12 h-12 rounded-full bg-gray-100 
                              flex items-center justify-center shadow-sm"
                            >
                              <UserCircleIcon className="w-8 h-8 text-gray-400" />
                            </div>
                          )}
                        </div>
                        {/* Content */}
                        <div className="flex-1 min-w-0">
                          <div className="flex items-baseline justify-between mb-1">
                            <h3 className="text-lg font-extrabold text-gray-900 truncate">
                              {message.renterName}
                            </h3>
                          </div>

                          {/* Property Title */}
                          <div className="mb-1">
                            <span
                              className="inline-flex items-center px-2 py-0.5 
                               rounded-md text-xs font-medium bg-blue-50 
                               text-blue-700"
                            >
                              {message.propertyTitle}
                            </span>
                          </div>

                          {/* Message Preview */}
                          {isAttachment ? (
                            <div className="flex items-center space-x-2 text-sm text-gray-600">
                              <PaperClipIcon className="w-4 h-4 text-gray-400" />
                              <span className="text-gray-600">
                                Attachment sent
                              </span>
                            </div>
                          ) : (
                            <p className="text-sm text-gray-600 line-clamp-2">
                              {message.messageSnippet}
                            </p>
                          )}
                        </div>

                        {/* Right Arrow with hover effect */}
                        <div className="flex-shrink-0 self-center ml-4">
                          <div className="p-2 rounded-full group-hover:bg-blue-50 transition-colors">
                            <ChevronRightIcon
                              className="w-5 h-5 text-gray-400 group-hover:text-blue-500 
                           transition-colors"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </>
        ) : (
          <p className="text-gray-500 text-center">
            No new messages yet, but if you've listed your property, tenant
            inquiries are just around the corner!
          </p>
        )}
      </div>
    </div>
  );
};

export default RecentMessagesPreview;

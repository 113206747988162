// src/components/Header.tsx

import React from 'react';

const Header: React.FC = () => {
  return (
    <header className=" text-gray-800 py-8">
      <div className="container mx-auto px-4 text-center">
        {/* Main Title */}
        <h1 className="text-4xl md:text-5xl font-extrabold mb-4">
          Ditch the Headaches, Grab the Keys – Welcome to RentSmart.ie
        </h1>

        {/* Slogan */}
        <p className="text-lg md:text-xl font-medium">
          Your Home Journey Starts Here — Renting or Buying Made Simple
        </p>
      </div>
    </header>
  );
};

export default Header;

import React, { useState } from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ChevronDownIcon
} from '@heroicons/react/24/solid';
import {
  Calculator,
  ClipboardCheck,
  Home,
  Info,
  Scale,
  Shield,
  User
} from 'lucide-react';

const LandlordGuide: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const toggleSection = (section: string) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const GuideSection: React.FC<{
    title: string;
    id: string;
    icon: React.ElementType;
    children: React.ReactNode;
  }> = ({ title, id, icon: Icon, children }) => (
    <div className="mb-8 bg-white rounded-xl shadow-md overflow-hidden">
      <button
        onClick={() => toggleSection(id)}
        className="w-full p-6 flex items-center justify-between bg-gradient-to-r from-blue-50 to-white hover:from-blue-100 transition-all"
      >
        <div className="flex items-center space-x-4">
          <div className="p-3 rounded-full bg-blue-100 text-blue-600">
            <Icon size={24} />
          </div>
          <h2 className="text-2xl font-semibold text-gray-900">{title}</h2>
        </div>
        <ChevronDownIcon
          className={`h-6 w-6 text-blue-600 transform transition-transform ${
            activeSection === id ? 'rotate-180' : ''
          }`}
        />
      </button>
      <div
        className={`transition-all duration-300 ease-in-out ${
          activeSection === id
            ? 'max-h-[1000px] opacity-100'
            : 'max-h-0 opacity-0'
        } overflow-hidden`}
      >
        <div className="p-6 border-t border-gray-100">{children}</div>
      </div>
    </div>
  );

  const ChecklistItem: React.FC<{ text: string }> = ({ text }) => (
    <li className="flex items-start space-x-3 py-2">
      <CheckCircleIcon className="h-6 w-6 text-green-500 flex-shrink-0" />
      <span className="text-gray-700">{text}</span>
    </li>
  );

  const WarningItem: React.FC<{ text: string }> = ({ text }) => (
    <li className="flex items-start space-x-3 py-2">
      <ExclamationCircleIcon className="h-6 w-6 text-red-500 flex-shrink-0" />
      <span className="text-gray-700">{text}</span>
    </li>
  );

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4 max-w-4xl">
        {/* Hero Section */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Complete Landlord's Guide
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Everything you need to know about being a successful landlord in
            Ireland
          </p>
        </div>

        {/* Quick Navigation */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-12">
          {[
            { title: 'Preparation', icon: ClipboardCheck },
            { title: 'Pricing', icon: Calculator },
            { title: 'Legal', icon: Scale },
            { title: 'Management', icon: Home }
          ].map((item) => (
            <button
              key={item.title}
              onClick={() => setActiveSection(item.title.toLowerCase())}
              className="p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow text-center"
            >
              <item.icon className="h-6 w-6 mx-auto mb-2 text-blue-600" />
              <span className="text-sm font-medium text-gray-900">
                {item.title}
              </span>
            </button>
          ))}
        </div>

        {/* Main Content */}
        <GuideSection
          title="Preparing Your Property"
          id="preparation"
          icon={Home}
        >
          <div className="space-y-6">
            <p className="text-gray-600">
              A well-prepared property attracts quality tenants and commands
              better rental rates. Follow these essential steps:
            </p>
            <ul className="space-y-2">
              <ChecklistItem text="Deep clean the entire property, including carpets and windows" />
              <ChecklistItem text="Fix any maintenance issues, no matter how small" />
              <ChecklistItem text="Update fixtures and appliances if needed" />
              <ChecklistItem text="Install required safety equipment (smoke alarms, CO detectors)" />
              <ChecklistItem text="Take professional-quality photos for your listing" />
              <ChecklistItem text="Consider a fresh coat of paint and basic staging" />
            </ul>
            <div className="bg-blue-50 p-4 rounded-lg mt-4">
              <p className="text-sm text-blue-700">
                <Info className="inline-block w-5 h-5 mr-2" />
                Properties that are well-maintained typically rent 30% faster
                and can command higher rental rates.
              </p>
            </div>
          </div>
        </GuideSection>

        <GuideSection
          title="Setting the Right Rent"
          id="pricing"
          icon={Calculator}
        >
          <div className="space-y-6">
            <p className="text-gray-600">
              Determining the optimal rental price is crucial for attracting
              tenants while maximizing your return on investment.
            </p>
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold mb-3">Research Factors</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Local market rates for similar properties" />
                  <ChecklistItem text="Property location and accessibility" />
                  <ChecklistItem text="Property size and condition" />
                  <ChecklistItem text="Included amenities and features" />
                </ul>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="font-semibold mb-3">Consider Costs</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Mortgage payments" />
                  <ChecklistItem text="Property tax and insurance" />
                  <ChecklistItem text="Maintenance and repairs" />
                  <ChecklistItem text="Management fees if applicable" />
                </ul>
              </div>
            </div>
          </div>
        </GuideSection>

        <GuideSection title="Legal Responsibilities" id="legal" icon={Scale}>
          <div className="space-y-6">
            <div className="bg-yellow-50 p-4 rounded-lg mb-6">
              <p className="text-yellow-800 flex items-start">
                <Shield className="h-5 w-5 mr-2 flex-shrink-0" />
                All landlords in Ireland must register with the Residential
                Tenancies Board (RTB) and comply with housing standards
                regulations.
              </p>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <h3 className="font-semibold mb-3">Essential Requirements</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Register tenancy with RTB within one month" />
                  <ChecklistItem text="Provide BER certificate" />
                  <ChecklistItem text="Maintain minimum housing standards" />
                  <ChecklistItem text="Provide rent book and receipts" />
                </ul>
              </div>
              <div>
                <h3 className="font-semibold mb-3">Tenant Rights</h3>
                <ul className="space-y-2">
                  <ChecklistItem text="Right to quiet enjoyment" />
                  <ChecklistItem text="Proper notice periods" />
                  <ChecklistItem text="Security deposit protection" />
                  <ChecklistItem text="Privacy and data protection" />
                </ul>
              </div>
            </div>
          </div>
        </GuideSection>

        <GuideSection
          title="Non-Discriminatory Practices"
          id="fair-housing"
          icon={User}
        >
          <div className="space-y-6">
            <p className="text-gray-600">
              Understanding and following fair housing practices is essential
              for legal compliance and ethical property management.
            </p>
            <ul className="space-y-2">
              <WarningItem text="Do not discriminate based on race, gender, religion, or nationality" />
              <WarningItem text="Avoid language suggesting preference for any protected class" />
              <WarningItem text="Focus listing descriptions on property features only" />
            </ul>
            <div className="bg-blue-50 p-4 rounded-lg">
              <h4 className="font-semibold mb-2">Resources</h4>
              <div className="space-y-2">
                <a
                  href="https://www.ihrec.ie/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline block"
                >
                  Irish Human Rights and Equality Commission →
                </a>
                <a
                  href="https://www.rtb.ie/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline block"
                >
                  Residential Tenancies Board →
                </a>
              </div>
            </div>
          </div>
        </GuideSection>

        {/* Call to Action */}
        <div className="bg-blue-600 rounded-xl p-8 text-white text-center mt-12">
          <h2 className="text-2xl font-bold mb-4">
            Ready to List Your Property?
          </h2>
          <p className="mb-6">
            Join thousands of successful landlords on RentSmart and find your
            ideal tenants.
          </p>
          <button
            onClick={() => (window.location.href = '/signup')}
            className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors"
          >
            Start Listing Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandlordGuide;

import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import BlogCard from '../../components/blog-card/BlogCard';
import { tenantBlogs, landlordBlogs } from '../../data/blogData';

const Blogs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const getTabClassName = (index: number) => {
    const baseClasses =
      'px-6 py-2 text-sm font-medium rounded-full transition-all duration-200 ease-in-out focus:outline-none cursor-pointer';
    const selectedClasses = 'bg-blue-500 text-white border border-blue-500';
    const unselectedClasses =
      'bg-gray-100 text-gray-700 border border-gray-300 hover:bg-gray-200';

    return `${baseClasses} ${selectedTab === index ? selectedClasses : unselectedClasses}`;
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">
        RentSmart Insights Blog
      </h1>
      <Tabs
        selectedIndex={selectedTab}
        onSelect={(index: number) => setSelectedTab(index)}
      >
        <TabList className="flex justify-center mb-8 space-x-2">
          <Tab className={getTabClassName(0)}>For Tenants</Tab>
          <Tab className={getTabClassName(1)}>For Landlords</Tab>
        </TabList>

        <TabPanel>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {tenantBlogs.map((blog) => (
              <BlogCard key={blog.id} blog={blog} />
            ))}
          </div>
        </TabPanel>

        <TabPanel>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {landlordBlogs.map((blog) => (
              <BlogCard key={blog.id} blog={blog} />
            ))}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Blogs;

// src/components/auto-apply/FAQ.tsx

import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { motion, AnimatePresence } from 'framer-motion';

interface FAQItem {
  question: string;
  answer: string;
  category: string;
}

const faqs: FAQItem[] = [
  {
    category: 'Getting Started',
    question: 'What is Auto Apply?',
    answer: 'Auto Apply is a feature that automatically submits your rental application to properties that match your specific criteria. It saves you time by handling the application process as soon as suitable properties become available.'
  },
  {
    category: 'Getting Started',
    question: 'How do I enable Auto Apply?',
    answer: 'To enable Auto Apply, create an account, complete your profile with all required information and documents, set your preferences, and activate the Auto Apply feature from your auto-apply dashboard - https://rentsmart.ie/auto-apply.'
  },
  {
    category: 'Documents & Verification',
    question: 'What documents do I need to upload?',
    answer: 'You will need to upload standard rental application documents such as proof of income, employment verification, references, and identification. These documents will be securely stored and automatically included with your applications.'
  },
  {
    category: 'Documents & Verification',
    question: 'How secure are my documents?',
    answer: 'Your documents are stored securely. They are only shared with landlords when you apply for a property, and you can revoke access or delete them at any time.'
  },
  {
    category: 'Application Process',
    question: 'How quickly are applications submitted?',
    answer: 'Applications are submitted automatically within minutes of a matching property being listed, giving you the best chance of securing your preferred property.'
  },
  {
    category: 'Application Process',
    question: 'Can I set preferences for properties?',
    answer: 'Yes, you can set detailed preferences including location, price range, number of bedrooms, amenities, and more. Auto Apply will only submit applications to properties matching your criteria.'
  },
  {
    category: 'Costs & Billing',
    question: 'Is Auto Apply free to use?',
    answer: 'Basic Auto Apply features are free to use and included with your account.'
  },
  {
    category: 'Costs & Billing',
    question: 'Can I cancel Auto Apply anytime?',
    answer: 'Yes, you can disable Auto Apply at any time from your dashboard with no penalties or fees.'
  }
];

const AutoApplyFAQ: React.FC = () => {
  const [activeCategory, setActiveCategory] =
    useState<string>('Getting Started');
  const [openQuestion, setOpenQuestion] = useState<string | null>(null);

  const categories = Array.from(new Set(faqs.map((faq) => faq.category)));

  const toggleQuestion = (question: string) => {
    setOpenQuestion(openQuestion === question ? null : question);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-xl text-gray-600">
            Everything you need to know about Auto Apply
          </p>
        </div>

        {/* Category Navigation */}
        <div className="flex overflow-x-auto mb-8 pb-2 -mx-4 px-4 sm:px-0 sm:mx-0">
          <div className="flex space-x-4">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setActiveCategory(category)}
                className={`px-4 py-2 rounded-full whitespace-nowrap transition-colors duration-200 ${
                  activeCategory === category
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* FAQ Accordion */}
        <div className="space-y-4">
          {faqs
            .filter((faq) => faq.category === activeCategory)
            .map((faq) => (
              <motion.div
                key={faq.question}
                initial={false}
                className="border border-gray-200 rounded-lg overflow-hidden bg-white"
              >
                <button
                  onClick={() => toggleQuestion(faq.question)}
                  className="w-full flex items-center justify-between px-6 py-4 text-left focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  <span className="font-medium text-gray-900">
                    {faq.question}
                  </span>
                  <ChevronDownIcon
                    className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${
                      openQuestion === faq.question ? 'rotate-180' : ''
                    }`}
                  />
                </button>
                <AnimatePresence>
                  {openQuestion === faq.question && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: 'auto', opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <div className="px-6 pb-4 text-gray-600">
                        {faq.answer}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
        </div>

        {/* Still Have Questions Section */}
        <div className="mt-12 bg-blue-50 rounded-xl p-8 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Still Have Questions?
          </h2>
          <p className="text-gray-600 mb-6">
            Can't find the answer you're looking for? Our support team is here
            to help.
          </p>
          <a
            href="/contact"
            className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors duration-300"
          >
            Contact Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default AutoApplyFAQ;
import React, { useEffect, useState } from 'react';
import { deleteDocument, insertDocument, queryDocuments } from '../../utility/firebaseHelpers';
import { where, limit, orderBy, serverTimestamp } from 'firebase/firestore';
import { Listing } from '../../components/property/property-card/PropertyCardTypes';
import PropertyCard from '../../components/property/property-card/PropertyCard';
import { useAuth } from '../../auth/firebaseAuth';

interface SimilarListingsProps {
  currentListing: Listing;
}

const SimilarListings: React.FC<SimilarListingsProps> = ({ currentListing }) => {
  const [similarListings, setSimilarListings] = useState<Listing[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bookmarkedListings, setBookmarkedListings] = useState<number[]>([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchSimilarListings = async () => {
      try {
        const constraints = [
          where('id', '!=', currentListing.id),
          where('status', '==', 'active'),
          where('listingIntent', '==', currentListing.listingIntent),
          where('type', '==', currentListing.type),
          where('price', '>=', currentListing.price * 0.8),
          where('price', '<=', currentListing.price * 1.2),
          where('location', '==', currentListing.location),
          limit(3),
          orderBy('dateAdded', 'desc')
        ];

        const listings = await queryDocuments<Listing>('listings', constraints);
        setSimilarListings(listings);

        // Fetch bookmarks
        if (currentUser) {
          const bookmarks = await queryDocuments('bookmarks', [
            where('userId', '==', currentUser.uid)
          ]);
          setBookmarkedListings(bookmarks.map((b) => Number(b.listingId)));
        } else {
          const localBookmarks = JSON.parse(
            localStorage.getItem('bookmarks') || '[]'
          );
          setBookmarkedListings(localBookmarks.map(Number));
        }
      } catch (error) {
        console.error('Error fetching similar listings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (currentListing) {
      fetchSimilarListings();
    }
  }, [currentListing, currentUser]);

  const handleBookmarkToggle = async (listingId: number) => {
    if (currentUser) {
      try {
        const bookmarks = await queryDocuments('bookmarks', [
          where('userId', '==', currentUser.uid),
          where('listingId', '==', listingId.toString())
        ]);

        if (bookmarks.length === 0) {
          // Add bookmark
          await insertDocument('bookmarks', {
            userId: currentUser.uid,
            listingId: listingId.toString(),
            createdAt: serverTimestamp()
          });
          setBookmarkedListings((prev) => [...prev, listingId]);
        } else {
          // Remove bookmark
          await deleteDocument('bookmarks', bookmarks[0].id);
          setBookmarkedListings((prev) =>
            prev.filter((id) => id !== listingId)
          );
        }
      } catch (error) {
        console.error('Error toggling bookmark:', error);
      }
    } else {
      // Handle local storage bookmarks
      const bookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
      const updatedBookmarks = bookmarkedListings.includes(listingId)
        ? bookmarks.filter((id: string) => Number(id) !== listingId)
        : [...bookmarks, listingId.toString()];

      localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
      setBookmarkedListings(updatedBookmarks.map(Number));
    }
  };

  const handleShare = (listing: Listing) => {
    const shareUrl = `${window.location.origin}/property/${listing.id}`;
    const shareTitle = `Check out this property: ${listing.title}`;

    if (navigator.share) {
      navigator
        .share({
          title: shareTitle,
          url: shareUrl
        })
        .catch(console.error);
    } else {
      // Fallback to clipboard
      navigator.clipboard
        .writeText(shareUrl)
        .then(() => alert('Link copied to clipboard!'))
        .catch(console.error);
    }
  };

  if (isLoading) {
    return (
      <div className="w-full space-y-4 ">
        <h2 className="text-2xl font-semibold mt-8">Similar Properties</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <div
              key={i}
              className="h-[600px] bg-gray-200 animate-pulse rounded-lg"
            />
          ))}
        </div>
      </div>
    );
  }

  if (!similarListings.length) {
    return null;
  }

  return (
    <div className="w-full space-y-4 mt-8">
      <h2 className="text-2xl font-semibold mb-6">Similar Properties</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {similarListings.map((listing) => (
          <PropertyCard
            key={listing.id}
            listing={listing}
            isBookmarked={bookmarkedListings.includes(listing.id)}
            onBookmarkToggle={handleBookmarkToggle}
            onShare={handleShare}
          />
        ))}
      </div>
    </div>
  );
};

export default SimilarListings;

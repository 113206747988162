import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ChevronDownIcon,
  HomeIcon,
  BuildingOfficeIcon,
  HomeModernIcon,
  AcademicCapIcon,
  CurrencyEuroIcon,
  HeartIcon
} from '@heroicons/react/24/outline';
import marketingRoutes, { MarketingRoute } from '../../routes/marketingRoutes';

type GroupedRoutes = {
  [key: string]: MarketingRoute[];
};

// Grouping routes by location
const groupedRoutes = marketingRoutes.reduce<GroupedRoutes>((acc, route) => {
  const titleParts = route.seo.title.split('|')[0].trim().split(' ');
  const location = titleParts[titleParts.length - 1] || 'Other';

  if (!acc[location]) {
    acc[location] = [];
  }
  acc[location].push(route);
  return acc;
}, {});

const getIcon = (title: string) => {
  if (title.includes('Affordable') || title.includes('Budget')) return HomeIcon;
  if (title.includes('Luxury')) return BuildingOfficeIcon;
  if (title.includes('Student')) return AcademicCapIcon;
  if (title.includes('Investment')) return CurrencyEuroIcon;
  if (title.includes('Family')) return HeartIcon;
  return HomeModernIcon;
};

const ExplorePropertyCategories: React.FC = () => {
  const [expandedLocation, setExpandedLocation] = useState<string | null>(null);

  const handleToggle = (location: string) => {
    // Set the clicked location as expanded if not already expanded, otherwise close it
    setExpandedLocation((prev) => (prev === location ? null : location));
  };

  return (
    <div className="w-full py-2 px-4 rounded-md">
      <h2 className="text-3xl sm:text-4xl font-extrabold text-indigo-900 text-center mb-8 sm:mb-12">
        Discover Your Ideal Property
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 items-start">
        {Object.entries(groupedRoutes).map(([location, routes]) => (
          <div
            key={location}
            className="bg-white rounded-xl shadow-lg overflow-hidden"
          >
            <button
              onClick={() => handleToggle(location)}
              className="w-full px-4 sm:px-6 py-4 flex justify-between items-center bg-indigo-600 text-white"
            >
              <span className="text-lg sm:text-xl font-semibold">
                {location}
              </span>
              <ChevronDownIcon
                className={`h-6 w-6 transition-transform duration-300 ${
                  expandedLocation === location ? 'transform rotate-180' : ''
                }`}
              />
            </button>
            {expandedLocation === location && (
              <div className="p-4 space-y-3">
                {routes.map((route) => {
                  const Icon = getIcon(route.seo.title);
                  return (
                    <Link
                      key={route.path}
                      to={route.path}
                      className="flex items-start px-3 py-2 text-gray-700 hover:bg-indigo-50 rounded-lg transition-colors duration-200"
                    >
                      <Icon className="h-6 w-6 text-indigo-500 mr-3 mt-1 flex-shrink-0" />
                      <span className="text-sm sm:text-base leading-tight">
                        {route.seo.title.split('|')[0].trim()}
                      </span>
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );

};

export default ExplorePropertyCategories;

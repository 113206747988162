import React from 'react';

const CookiePolicy: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Cookie Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">What are cookies?</h2>
        <p className="mb-4">
          Cookies are small text files that are placed on your computer or
          mobile device when you visit a website. They are widely used to make
          websites work more efficiently, as well as to provide information to
          the owners of the site.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">How we use cookies</h2>
        <p className="mb-4">
          RentSmart.ie uses cookies for a variety of purposes. We use them to:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>Remember your login details</li>
          <li>Keep you signed in</li>
          <li>Understand how you use our website</li>
          <li>Personalize your experience</li>
          <li>Improve our services</li>
          <li>Show you relevant advertising</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Types of cookies we use</h2>
        <div className="space-y-4">
          <div>
            <h3 className="text-xl font-semibold mb-2">Essential cookies</h3>
            <p>
              These cookies are necessary for the website to function properly.
              They enable core functionality such as security, network
              management, and accessibility.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">Performance cookies</h3>
            <p>
              These cookies help us understand how visitors interact with our
              website by providing information about the areas visited, the time
              spent on the website, and any issues encountered.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">
              Functionality cookies
            </h3>
            <p>
              These cookies allow the website to remember choices you make (such
              as your username, language, or the region you are in) and provide
              enhanced, more personal features.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">Advertising cookies</h3>
            <p>
              These cookies are used to deliver advertisements more relevant to
              you and your interests. They are also used to limit the number of
              times you see an advertisement as well as help measure the
              effectiveness of the advertising campaigns.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Managing cookies</h2>
        <p className="mb-4">
          Most web browsers allow you to manage your cookie preferences. You can
          set your browser to refuse cookies, or to alert you when cookies are
          being sent. The following links show how to adjust your browser
          settings to manage cookies:
        </p>
        <ul className="list-disc pl-8 mb-4">
          <li>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=en"
              className="text-blue-500 hover:underline"
            >
              Google Chrome
            </a>
          </li>
          <li>
            <a
              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop"
              className="text-blue-500 hover:underline"
            >
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a
              href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
              className="text-blue-500 hover:underline"
            >
              Safari
            </a>
          </li>
          <li>
            <a
              href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              className="text-blue-500 hover:underline"
            >
              Microsoft Edge
            </a>
          </li>
        </ul>
        <p className="mb-4">
          Please note that if you choose to disable cookies, some features of
          our website may not function properly.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">
          Changes to our cookie policy
        </h2>
        <p className="mb-4">
          We may update our Cookie Policy from time to time. Any changes will be
          posted on this page and, where appropriate, notified to you by email.
        </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Contact us</h2>
        <p className="mb-4">
          If you have any questions about our Cookie Policy, please contact us
          at:
        </p>
        <p>Email: privacy@rentsmart.ie</p>
      </section>
    </div>
  );
};

export default CookiePolicy;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './auth/firebaseAuth';
import { CookieConsentProvider } from './context/CookieConsentContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CookieConsentProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </CookieConsentProvider>
  </React.StrictMode>
);

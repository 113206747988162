import React, { useState } from 'react';
import {
  EnvelopeIcon,
  CheckCircleIcon
} from '@heroicons/react/24/solid';
import { insertDocument } from '../../utility/firebaseHelpers'; // Import the helper

const ContactPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Insert the feedback document into Firestore
      await insertDocument('feedback', formData);

      // Show success message and reset form
      setSuccessMessage(
        'Thank you for your message. We will get back to you soon!'
      );
      setFormData({ name: '', email: '', subject: '', message: '' });

      // Hide the success message after a few seconds
      setTimeout(() => setSuccessMessage(null), 5000);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert(
        'There was an error submitting your message. Please try again later.'
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 max-w-6xl">
      <h1 className="text-3xl font-bold mb-8 text-center">Contact Us</h1>

      {successMessage && (
        <div className="flex items-center bg-green-100 border border-green-300 text-green-800 p-4 rounded-lg mb-8">
          <CheckCircleIcon className="w-6 h-6 mr-2 text-green-600" />
          <span>{successMessage}</span>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="email" className="block mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="subject" className="block mb-1">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label htmlFor="message" className="block mb-1">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows={4}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              ></textarea>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
            >
              Send Message
            </button>
          </form>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
          <div className="space-y-4 mb-8">
            <div className="flex items-center">
              <EnvelopeIcon className="w-6 h-6 mr-2 text-blue-500" />
              <span>support@rentsmart.ie</span>
            </div>
            {/* <div className="flex items-center">
              <PhoneIcon className="w-6 h-6 mr-2 text-blue-500" />
              <span>+353 1 234 5678</span>
            </div> */}
            {/* <div className="flex items-center">
              <MapIcon className="w-6 h-6 mr-2 text-blue-500" />
              <span>123 Main Street, Dublin 2, Ireland</span>
            </div> */}
          </div>
{/* 
          <h2 className="text-2xl font-semibold mb-4">Our Location</h2>
          <div className="bg-gray-300 h-64 rounded-lg flex items-center justify-center">
            <span className="text-gray-600">Map Placeholder</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactPage;

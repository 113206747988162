// src/utils/imageUtils.ts

// Import your images manually
import image1 from '../media/images/Two_people_looking_happy_that_they_found_their_dre (1).png';
import image2 from '../media/images/Two_people_looking_happy_that_they_found_their_dre (2).png';
import image3 from '../media/images/Two_people_looking_happy_that_they_found_their_dre (3).png';
import image4 from '../media/images/Two_people_looking_happy_that_they_found_their_dre (4).png';
import image5 from '../media/images/Two_people_looking_happy_that_they_found_their_dre (5).png';
import image6 from '../media/images/Two_people_looking_happy_that_they_found_their_dre.png';
import image7 from '../media/images/Two_people_who_look_like_happy_that_they_just_foun.png';
import image8 from '../media/images/Two_young_people_looking_happy_that_they_found_the (1).png';
import image9 from '../media/images/Two_young_people_looking_happy_that_they_found_the (2).png';
import image10 from '../media/images/Two_young_people_looking_happy_that_they_found_the (3).png';
import image11 from '../media/images/Two_young_people_looking_happy_that_they_found_the (4).png';
import image12 from '../media/images/Two_young_people_looking_happy_that_they_found_the (5).png';
import image13 from '../media/images/Two_young_people_looking_happy_that_they_found_the (6).png';
import image14 from '../media/images/Two_young_people_looking_happy_that_they_found_the (7).png';
import image15 from '../media/images/Two_young_people_looking_happy_that_they_found_the (8).png';
import image16 from '../media/images/Two_young_people_looking_happy_that_they_found_the (9).png';
import image17 from '../media/images/Two_young_people_looking_happy_that_they_found_the (10).png';
import image18 from '../media/images/Two_young_people_looking_happy_that_they_found_the.png';

// Create an array of all imported images
const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18
];

export function getRandomImage(): string {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
}

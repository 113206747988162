// hooks/usePropertyViews.ts
import { useEffect } from 'react';
import { doc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../auth/firebaseConfig';

export const usePropertyViews = (propertyId: string | undefined) => {
  const updatePropertyViews = async (id: string) => {
    // Get viewed properties from sessionStorage
    const viewedProperties = sessionStorage.getItem('viewedProperties');
    const viewedSet = viewedProperties
      ? new Set(JSON.parse(viewedProperties))
      : new Set();

    // If property has already been viewed in this session, don't increment
    if (viewedSet.has(id)) {
      return;
    }

    // Update view count in Firestore
    const listingRef = doc(db, 'listings', id);
    try {
      await updateDoc(listingRef, { propertyViews: increment(1) });

      // Mark property as viewed in this session
      viewedSet.add(id);
      sessionStorage.setItem(
        'viewedProperties',
        JSON.stringify([...viewedSet])
      );
    } catch (error) {
      console.error('Error updating property view count:', error);
    }
  };

  useEffect(() => {
    if (propertyId) {
      updatePropertyViews(propertyId);
    }
  }, [propertyId]); // Only runs when propertyId changes
};

import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  url: string;
  title: string;
}

const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose, url, title }) => {
  const [isCopied, setIsCopied] = useState(false);

  if (!isOpen) return null;

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-lg">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Share this property</h2>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-800 transition-colors">
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <p className="text-gray-600 mb-4">Know someone that might be interested in this ?</p>
        <div className="grid grid-cols-2 gap-6 mb-6">
          <div className="flex items-center">
            <FacebookShareButton url={url}>
              <FacebookIcon size={48} round />
            </FacebookShareButton>
            <span className="ml-2 text-gray-700">Share on Facebook</span>
          </div>
          <div className="flex items-center">
            <TwitterShareButton url={url} title={title}>
              <TwitterIcon size={48} round />
            </TwitterShareButton>
            <span className="ml-2 text-gray-700">Share on Twitter</span>
          </div>
          <div className="flex items-center">
            <WhatsappShareButton url={url} title={title}>
              <WhatsappIcon size={48} round />
            </WhatsappShareButton>
            <span className="ml-2 text-gray-700">Share on WhatsApp</span>
          </div>
          <div className="flex items-center">
            <EmailShareButton url={url} subject={title} body={`Check out this property on RentSmart.ie - Ireland's No 1 property website`}>
              <EmailIcon size={48} round />
            </EmailShareButton>
            <span className="ml-2 text-gray-700">Share via Email</span>
          </div>
        </div>
        <div className="flex items-center bg-gray-200 rounded-lg p-3">
          <input
            type="text"
            value={url}
            readOnly
            className="flex-grow bg-transparent outline-none text-gray-700"
          />
          <button
            onClick={handleCopyLink}
            className="ml-3 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
          >
            {isCopied ? 'Copied!' : 'Copy'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;

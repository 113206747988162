// src/components/auto-apply/HowAutoApplyWorks.tsx

import React from 'react';
import {
  ClipboardDocumentCheckIcon,
  RocketLaunchIcon,
  BellIcon,
  ChatBubbleLeftRightIcon,
  KeyIcon,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const steps = [
  {
    icon: <ClipboardDocumentCheckIcon className="w-8 h-8" />,
    title: 'Set Your Preferences',
    description:
      'Define your ideal property criteria including location, budget, and must-have amenities.',
    color: 'bg-blue-50 text-blue-600'
  },
  {
    icon: <RocketLaunchIcon className="w-8 h-8" />,
    title: 'Automatic Matching',
    description:
      'Our system continuously scans new listings and matches them with your criteria.',
    color: 'bg-purple-50 text-purple-600'
  },
  {
    icon: <BellIcon className="w-8 h-8" />,
    title: 'Instant Applications',
    description:
      'When a match is found, your application is automatically submitted to the property.',
    color: 'bg-orange-50 text-orange-600'
  },
  {
    icon: <ChatBubbleLeftRightIcon className="w-8 h-8" />,
    title: 'Direct Communication',
    description:
      'Connect directly with landlords once your application is accepted.',
    color: 'bg-pink-50 text-pink-600'
  },
  {
    icon: <KeyIcon className="w-8 h-8" />,
    title: 'Secure Your Home',
    description:
      'Complete the rental process and move into your new home faster than ever.',
    color: 'bg-teal-50 text-teal-600'
  }
];

const HowAutoApplyWorks: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-5xl mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            How Auto Apply Works
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Streamline your property search with our automated application
            system
          </p>
        </div>

        {/* Steps Grid */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-16">
          {steps.map((step, index) => (
            <motion.div
              key={step.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300 p-6"
            >
              <div
                className={`rounded-lg w-16 h-16 flex items-center justify-center mb-4 ${step.color}`}
              >
                {step.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {step.title}
              </h3>
              <p className="text-gray-600">{step.description}</p>
            </motion.div>
          ))}
        </div>

        {/* FAQ Preview */}
        <div className="bg-white rounded-xl shadow-lg p-8 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Still have questions?
          </h2>
          <p className="text-gray-600 mb-6">
            Learn more about how Auto Apply can help you find your next home
            faster
          </p>
          <div className="flex justify-center gap-4">
            <a
              href="/auto-apply/faq"
              className="inline-flex items-center px-6 py-3 bg-blue-600 text-white font-medium rounded-lg hover:bg-blue-700 transition-colors duration-300"
            >
              View FAQ
            </a>
            <a
              href="/auto-apply/success-stories"
              className="inline-flex items-center px-6 py-3 bg-white text-blue-600 font-medium rounded-lg border-2 border-blue-600 hover:bg-blue-50 transition-colors duration-300"
            >
              Read Success Stories
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowAutoApplyWorks;

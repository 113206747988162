import React, { useState, useEffect } from 'react';
import { useAuth } from '../../auth/firebaseAuth';
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  startAfter,
  limit,
  where,
  QueryDocumentSnapshot,
  Timestamp,
  Query,
  FieldValue,
} from 'firebase/firestore';
import { db } from '../../auth/firebaseConfig';
import { XCircle } from 'lucide-react';

interface Document {
  name: string;
  url: string;
  type: string;
  uploadedAt: string;
}

export interface VerificationRequest {
  id: string;
  userId: string;
  userEmail: string;
  userFullname: string;
  phone: string;
  documentType: 'passport' | 'driverLicense' | 'nationalId' | 'other';
  documentNumber: string;
  documents: Document[];
  status: 'pending' | 'approved' | 'rejected';
  submittedAt: FieldValue;
  reviewedAt?: Timestamp;
  updatedAt?: Timestamp | FieldValue;
  reviewedBy?: string;
}

const AdminVerificationDashboard: React.FC = () => {
  const { userProfile, currentUser } = useAuth();
  const [verifications, setVerifications] = useState<VerificationRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(
    null
  );
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searchEmail, setSearchEmail] = useState<string>('');
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedVerification, setSelectedVerification] = useState<
    string | null
  >(null);
  const [rejectionReason, setRejectionReason] = useState('');
  const [selectedFile, setSelectedFile] = useState<Document | null>(null);
  const [imageLoadError, setImageLoadError] = useState<{
    [key: string]: string;
  }>({});
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const ITEMS_PER_PAGE = 10;

  const buildQuery = (isNextPage: boolean = false): Query => {
    let baseQuery = collection(db, 'identityVerification');
    let constraints = [];

    // Add email search if provided
    if (searchEmail) {
      constraints.push(
        where('userEmail', '==', searchEmail.toLowerCase().trim())
      );
    } else {
      constraints.push(where('status', '==', 'pending'));
    }

    //constraints.push(orderBy('submittedAt', 'desc'));

    if (isNextPage && lastVisible) {
      constraints.push(startAfter(lastVisible));
    }

    constraints.push(limit(ITEMS_PER_PAGE));

    return query(baseQuery, ...constraints);
  };

  useEffect(() => {
    fetchVerifications();
  }, [page]);

  const fetchVerifications = async (
    isNextPage: boolean = false
  ): Promise<void> => {
        try {
          setLoading(true);
          const q = buildQuery(isNextPage);
          const snapshot = await getDocs(q);
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          })) as VerificationRequest[];

          setVerifications(docs);
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setHasMore(snapshot.docs.length === ITEMS_PER_PAGE);
        } catch (err) {
          setError('Failed to fetch verification requests');
        } finally {
          setLoading(false);
          setIsSearching(false);
        }
  };

  // Access control check
  if (!userProfile?.isPlatformAdmin) {
    return (
      <div className="max-w-2xl mx-auto mt-8 bg-red-50 border-l-4 border-red-500 p-4">
        <p className="text-red-700">
          You do not have permission to access this page. Please contact an
          administrator if you believe this is an error.
        </p>
      </div>
    );
  }


  
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setPage(1);
    setLastVisible(null);
    setIsSearching(true);
    fetchVerifications();
  };

  const resetSearch = () => {
    setSearchEmail('');
    setPage(1);
    setLastVisible(null);
    setIsSearching(false);
    fetchVerifications();
  };
 const handleApprove = async (verificationId: string): Promise<void> => {

   try {
     if (!currentUser) throw new Error('No authenticated user');
     // Update verification status
     await updateDoc(doc(db, 'identityVerification', verificationId), {
       status: 'approved',
       reviewedAt: new Date(),
       reviewedBy: currentUser.uid
     });

     // Update user's verification status
     const verification = verifications.find((v) => v.id === verificationId);
     if (verification) {
       await updateDoc(doc(db, 'users', verification.userId), {
         'accountVerification.identityVerified': true,
         'accountVerification.identityVerifiedAt': new Date()
       });
     }

     fetchVerifications();
   } catch (err) {
     setError('Failed to approve verification');
   }
 };

  const handleImageError = (docUrl: string) => {
    setImageLoadError((prev) => ({
      ...prev,
      [docUrl]:
        'Failed to load image. Please check your permissions or try again later.'
    }));
  };

  const handleDocumentError = (doc: Document) => {
    setImageLoadError((prev) => ({
      ...prev,
      [doc.url]: `Failed to load ${doc.type.includes('pdf') ? 'PDF' : 'document'}. Please check your permissions or try again later.`
    }));
  };

const handleReject = async (
  verificationId: string,
  rejectionReason: string
): Promise<void> => {

  try {
    if (!currentUser) throw new Error('No authenticated user');

    // Update verification status with rejection reason
    await updateDoc(doc(db, 'identityVerification', verificationId), {
      status: 'rejected',
      reviewedAt: new Date(),
      reviewedBy: currentUser.uid,
      rejectionReason,
      reviewNotes: rejectionReason // For admin reference
    });

    // Update user's verification status
    const verification = verifications.find((v) => v.id === verificationId);
    if (verification) {
      await updateDoc(doc(db, 'users', verification.userId), {
        isVerified: false,
        lastVerificationAttempt: new Date(),
        verificationStatus: 'rejected'
      });
    }

    // The email will be triggered by the Cloud Function watching for document updates
    fetchVerifications();
  } catch (err) {
    setError('Failed to reject verification');
  }
};

  const handleNextPage = (): void => {
    setPage((prev) => prev + 1);
    fetchVerifications(true);
  };

  const handlePrevPage = (): void => {
    if (page > 1) {
      setPage((prev) => prev - 1);
      fetchVerifications();
    }
  };

    const handleFileClick = (doc: Document) => {
      if (doc.type.includes('pdf')) {
        // For PDFs, open in new tab
        window.open(doc.url, '_blank');
      } else {
        // For images, show in modal
        setSelectedFile(doc);
      }
    };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h1 className="text-2xl font-bold text-gray-900">
            Identity Verification Requests
          </h1>
          {/* Search Form */}
          <div className="mt-4">
            <form onSubmit={handleSearch} className="flex gap-2">
              <input
                type="email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
                placeholder="Search by email..."
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                disabled={loading}
              >
                Search
              </button>
              {isSearching && (
                <button
                  type="button"
                  onClick={resetSearch}
                  className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  Reset
                </button>
              )}
            </form>
          </div>
        </div>

        <div className="p-6">
          {error && (
            <div className="mb-4 bg-red-50 border-l-4 border-red-500 p-4">
              <p className="text-red-700">{error}</p>
            </div>
          )}

          {loading ? (
            <div className="flex justify-center p-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
            </div>
          ) : (
            <div className="space-y-4">
              {verifications.map((verification) => (
                <div
                  key={verification.id}
                  className="bg-white border border-gray-200 rounded-lg p-4 shadow-sm hover:shadow-md transition-shadow"
                >
                  <div className="flex flex-col md:flex-row justify-between gap-4">
                    <div className="flex-1">
                      <h5 className="font-semibold text-gray-900">
                        User: {verification.userEmail}
                      </h5>
                      <p className="text-sm text-gray-600">
                        Full name: {verification.userFullname}
                      </p>
                      <p className="text-sm text-gray-600">
                        Document Type: {verification.documentType}
                      </p>
                      <p className="text-sm text-gray-600">
                        Submitted:{' '}
                        {(verification.submittedAt as Timestamp)
                          ?.toDate()
                          .toLocaleDateString()}
                      </p>
                      <p className="text-sm text-gray-600">
                        Document Number: {verification.documentNumber}
                      </p>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
                      {verification.documents.map((doc, index) => (
                        <div key={index} className="relative group">
                          {imageLoadError[doc.url] ? (
                            // Error state display
                            <div className="h-24 w-24 border rounded flex flex-col items-center justify-center p-2 bg-gray-50">
                              <svg
                                className="w-8 h-8 text-red-500 mb-1"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                />
                              </svg>
                              <span className="text-xs text-gray-500 text-center">
                                {imageLoadError[doc.url]}
                              </span>
                            </div>
                          ) : doc.type.includes('pdf') ? (
                            // PDF document display
                            <div
                              onClick={() => handleFileClick(doc)}
                              className="h-24 w-24 border rounded flex flex-col items-center justify-center cursor-pointer hover:bg-gray-50 transition-colors"
                            >
                              <svg
                                className="w-8 h-8 text-red-500 mb-1"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                                />
                              </svg>
                              <span className="text-xs text-gray-500">
                                PDF Document
                              </span>
                            </div>
                          ) : (
                            // Image display
                            <>
                              <img
                                src={doc.url}
                                alt={`Document ${index + 1}`}
                                className="h-24 w-24 object-cover rounded cursor-pointer"
                                onClick={() => handleFileClick(doc)}
                                onError={() => handleImageError(doc.url)}
                              />
                              <div
                                onClick={() => handleFileClick(doc)}
                                className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all flex items-center justify-center opacity-0 group-hover:opacity-100"
                              >
                                <svg
                                  className="w-6 h-6 text-white"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                                  />
                                </svg>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="flex gap-2 items-center">
                      <button
                        onClick={() => handleApprove(verification.id)}
                        className="p-2 text-green-600 hover:bg-green-50 rounded-full transition-colors"
                        title="Approve"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          setSelectedVerification(verification.id);
                          setShowRejectModal(true);
                        }}
                        className="p-2 text-red-600 hover:bg-red-50 rounded-full transition-colors"
                        title="Reject"
                      >
                        <XCircle className="w-6 h-6" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              {verifications.length === 0 && (
                <div className="text-center py-8 text-gray-500">
                  {isSearching
                    ? 'No verification requests found for this email'
                    : 'No pending verification requests'}
                </div>
              )}

              <div className="flex justify-between items-center mt-6">
                <button
                  onClick={handlePrevPage}
                  disabled={page === 1}
                  className={`flex items-center gap-1 px-4 py-2 rounded transition-colors ${
                    page === 1
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-blue-600 hover:bg-blue-50'
                  }`}
                >
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                  Previous
                </button>
                <span className="text-sm text-gray-600">Page {page}</span>
                <button
                  onClick={handleNextPage}
                  disabled={!hasMore}
                  className={`flex items-center gap-1 px-4 py-2 rounded transition-colors ${
                    !hasMore
                      ? 'text-gray-400 cursor-not-allowed'
                      : 'text-blue-600 hover:bg-blue-50'
                  }`}
                >
                  Next
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        style={{ display: showRejectModal ? 'flex' : 'none' }}
      >
        <div className="bg-white rounded-lg p-6 max-w-md w-full">
          <h3 className="text-lg font-semibold mb-4">Reject Verification</h3>

          <textarea
            className="w-full p-2 border rounded-md mb-4"
            rows={4}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder="Enter rejection reason..."
          />

          <div className="flex justify-end gap-2">
            <button
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-md"
              onClick={() => {
                setShowRejectModal(false);
                setSelectedVerification(null);
                setRejectionReason('');
              }}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              onClick={() => {
                if (selectedVerification && rejectionReason.trim()) {
                  handleReject(selectedVerification, rejectionReason.trim());
                  setShowRejectModal(false);
                  setSelectedVerification(null);
                  setRejectionReason('');
                }
              }}
            >
              Reject
            </button>
          </div>
        </div>
      </div>

      {/* File viewer modal */}
      {selectedFile && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
          onClick={() => setSelectedFile(null)}
        >
          <div
            className="relative max-w-4xl max-h-screen p-4"
            onClick={(e) => e.stopPropagation()}
          >
            {selectedFile.type.includes('pdf') ? (
              <div className="bg-white rounded-lg overflow-hidden">
                <div className="flex justify-between items-center p-4 border-b">
                  <h3 className="text-lg font-semibold">PDF Document</h3>
                  <button
                    onClick={() => setSelectedFile(null)}
                    className="text-gray-500 hover:text-gray-700 transition-colors"
                  >
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  className="relative"
                  style={{ height: 'calc(90vh - 4rem)' }}
                >
                  <iframe
                    src={`${selectedFile.url}#view=FitH`}
                    className="w-full h-full"
                    title="PDF document"
                    onError={() => handleDocumentError(selectedFile)}
                  />
                </div>
              </div>
            ) : (
              <>
                <img
                  src={selectedFile.url}
                  alt="Document"
                  className="max-w-full max-h-[90vh] object-contain"
                  onError={() => handleDocumentError(selectedFile)}
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFile(null);
                  }}
                  className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
                >
                  <svg
                    className="w-8 h-8"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminVerificationDashboard;

import React, { useState, useRef, DragEvent, useEffect } from 'react';
import {
  IdentificationIcon,
  DocumentArrowUpIcon,
  XMarkIcon,
  ShieldCheckIcon,
  ClockIcon,
  ArrowPathIcon,
  ChatBubbleLeftIcon,
  ExclamationCircleIcon,
  XCircleIcon
} from '@heroicons/react/24/outline';
import { db } from '../../auth/firebaseConfig';
import { useAuth } from '../../auth/firebaseAuth';
import { uploadFile } from '../../utility/firebaseStorageHelpers';
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import StyledNavLink from '../styled-nav-link/StyledNavLink';
import { VerificationRequest } from '../../pages/admin-verification-dashboard/AdminVerificationDashboard';

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 10MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];

interface VerificationFormData {
  documentType: 'passport' | 'driverLicense' | 'nationalId' | 'other';
  documentNumber: string;
}

const IdentityVerificationForm: React.FC = () => {
  const { currentUser, userProfile } = useAuth();
  const [formData, setFormData] = useState<VerificationFormData>({
    documentType: 'passport',
    documentNumber: ''
  });
  const [documents, setDocuments] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<
    'none' | 'pending' | 'approved' |'rejected'
  >('none');
  const [submissionDate, setSubmissionDate] = useState<Date | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFile = (file: File): string | null => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      return 'File type not supported. Please upload PNG, JPG, or PDF files.';
    }
    if (file.size > MAX_FILE_SIZE) {
      return 'File size exceeds 50MB limit.';
    }
    return null;
  };

  const handleFiles = (files: FileList | null) => {
    if (!files) return;

    const newFiles: File[] = [];
    const errors: string[] = [];

    Array.from(files).forEach((file) => {
      const error = validateFile(file);
      if (error) {
        errors.push(`${file.name}: ${error}`);
      } else {
        newFiles.push(file);
      }
    });

    if (errors.length > 0) {
      setError(errors.join('\n'));
      return;
    }

    setDocuments((prev) => [...prev, ...newFiles]);
    setError(null);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    handleFiles(files);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFiles(e.target.files);
  };

  const removeFile = (index: number) => {
    setDocuments((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const checkVerificationStatus = async () => {
      if (!currentUser) return;

       console.log('attempting to find verification: ');

      try {
        const verificationQuery = query(
          collection(db, 'identityVerification'),
          where('userId', '==', currentUser.uid)
        );

        const querySnapshot = await getDocs(verificationQuery);
        if (!querySnapshot.empty) {
          const verificationDoc = querySnapshot.docs[0].data();
          setVerificationStatus(verificationDoc.status);
          setSubmissionDate(verificationDoc.submittedAt?.toDate() || null);
        }
      } catch (error) {
           console.log('error finding:');
        console.error('Error checking verification status:', error);
      }
    };

    checkVerificationStatus();
  }, [currentUser]);

   const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      if (!currentUser || !userProfile)
        throw new Error('User not authenticated');
      if (!formData.documentType || !formData.documentNumber) {
        throw new Error('Please fill in all required fields');
      }
      if (documents.length === 0) {
        throw new Error('Please upload your identity document');
      }

      // Upload documents with correct path structure
      const uploadedDocs = await Promise.all(
        documents.map(async (file, index) => {
          // Create a clean filename by removing special characters and spaces
          const cleanFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
          
          // Construct the path according to storage rules
          const path = `identityVerification/${currentUser.uid}/${cleanFileName}`;
          
          try {
            const url = await uploadFile(file, path);
            return {
              name: file.name,
              url,
              type: file.type,
              uploadedAt: new Date().toISOString()
            };
          } catch (uploadError) {
            console.error(`Error uploading file ${file.name}:`, uploadError);
            throw new Error(`Failed to upload ${file.name}. Please try again.`);
          }
        })
      );

      const verificationDoc: Partial<VerificationRequest> = {
        userId: currentUser.uid,
        userEmail: currentUser.email ?? '',
        userFullname: userProfile?.name,
        phone: userProfile?.phone,
        documentType: formData.documentType,
        documentNumber: formData.documentNumber,
        documents: uploadedDocs,
        status: 'pending',
        submittedAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      };

      // Create verification document
      await addDoc(collection(db, 'identityVerification'), {
        ...verificationDoc
      });

      setSuccess(true);
      setVerificationStatus('pending');
      setSubmissionDate(new Date());
    } catch (error) {
      console.error('Error submitting verification:', error);
      setError(
        error instanceof Error ? error.message : 'Failed to submit verification'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // If verification is pending, show review status
  if (verificationStatus === 'pending') {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6 mt-4">
        <div className="flex items-center mb-6">
          <ClockIcon className="h-8 w-8 text-indigo-600 mr-3" />
          <div>
            <h2 className="text-xl font-bold text-gray-900">
              Identity Verification In Progress
            </h2>
            <p className="text-sm text-gray-500">
              Submitted on {submissionDate?.toLocaleDateString()}
            </p>
          </div>
        </div>

        <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
          <div className="flex">
            <ShieldCheckIcon className="h-5 w-5 text-blue-400 mr-2" />
            <div>
              <p className="text-sm text-blue-700">
                Your identity verification documents are currently being
                reviewed. This process typically takes 1-2 business days. We'll
                notify you once the verification is complete.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-6 space-y-4">
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 bg-indigo-600 rounded-full"></div>
            <span className="text-sm text-gray-600">
              Documents received and under review
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 bg-gray-300 rounded-full"></div>
            <span className="text-sm text-gray-600">
              Verification approval pending
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 bg-gray-300 rounded-full"></div>
            <span className="text-sm text-gray-600">Account activation</span>
          </div>
        </div>

        <div className="mt-6 text-sm text-gray-500">
          Need help?{' '}
          <StyledNavLink to='/contact' className="text-indigo-600 hover:text-indigo-800">
            Contact Support
          </StyledNavLink>
        </div>
      </div>
    );
  }

  if (verificationStatus === 'rejected') {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mt-4">
      <div className="flex items-center mb-6">
        <XCircleIcon className="h-8 w-8 text-red-600 mr-3" />
        <div>
          <h2 className="text-xl font-bold text-gray-900">
            Identity Verification Rejected
          </h2>
          <p className="text-sm text-gray-500">
            Reviewed on {submissionDate?.toLocaleDateString()}
          </p>
        </div>
      </div>

      <div className="bg-red-50 border-l-4 border-red-400 p-4">
        <div className="flex">
          <ExclamationCircleIcon className="h-5 w-5 text-red-400 mr-2" />
          <div>
            <h3 className="text-sm font-medium text-red-800">
              Your verification was not approved
            </h3>
            <p className="text-sm text-red-700 mt-1">
              This could be due to one or more of the following reasons:
            </p>
            <ul className="list-disc list-inside text-sm text-red-700 mt-2 space-y-1">
              <li>Documents were unclear or unreadable</li>
              <li>Documents provided were expired or invalid</li>
              <li>Information provided did not match the documents</li>
              <li>Required document sections were missing or cut off</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <h4 className="text-sm font-medium text-gray-900 mb-3">What to do next:</h4>
        <div className="space-y-4">
          <div className="flex items-start space-x-3">
            <div className="flex-shrink-0 w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center text-xs text-gray-600">
              1
            </div>
            <span className="text-sm text-gray-600">
              Review the requirements for identity verification
            </span>
          </div>
          <div className="flex items-start space-x-3">
            <div className="flex-shrink-0 w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center text-xs text-gray-600">
              2
            </div>
            <span className="text-sm text-gray-600">
              Prepare new, clear copies of your documents
            </span>
          </div>
          <div className="flex items-start space-x-3">
            <div className="flex-shrink-0 w-5 h-5 rounded-full bg-gray-200 flex items-center justify-center text-xs text-gray-600">
              3
            </div>
            <span className="text-sm text-gray-600">
              Submit a new verification request
            </span>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-col sm:flex-row gap-4">
        <button
          onClick={() => window.location.reload()} // Or your resubmit handler
          className="inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ArrowPathIcon className="h-5 w-5 mr-2" />
          Submit New Request
        </button>
        <StyledNavLink 
          to='/contact' 
          className="inline-flex justify-center items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <ChatBubbleLeftIcon className="h-5 w-5 mr-2" />
          Contact Support
        </StyledNavLink>
      </div>
    </div>
  );
}

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center mb-6">
        <IdentificationIcon className="h-8 w-8 text-indigo-600 mr-3" />
        <div>
          <h2 className="text-xl font-bold text-gray-900">
            Identity Verification
          </h2>
          <p className="text-sm text-gray-500">
            Required for account activation
          </p>
        </div>
      </div>

      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-6">
        <div className="flex">
          <ShieldCheckIcon className="h-5 w-5 text-yellow-400 mr-2" />
          <div>
            <p className="text-sm text-yellow-700">
              <span className="font-medium">Why we need this: </span>
              To maintain a safe and trustworthy platform, we require identity
              verification for all landlords or property agents. This helps
              prevent fraud and protects all users on our platform. Your
              documents are securely stored and handled with strict
              confidentiality.
            </p>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="documentType"
            className="block text-sm font-medium text-gray-700"
          >
            Document Type*
          </label>
          <select
            id="documentType"
            name="documentType"
            value={formData.documentType}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500"
            required
          >
            <option value="passport">Passport</option>
            <option value="driverLicense">Driver's License</option>
            <option value="nationalId">National ID</option>
            <option value="other">Other Document</option>
          </select>
        </div>

        <div>
          <label
            htmlFor="documentNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Document Number*
          </label>
          <input
            type="text"
            id="documentNumber"
            name="documentNumber"
            value={formData.documentNumber}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Upload Document*
          </label>
          <div
            className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-md transition-colors duration-200 ${
              isDragging
                ? 'border-indigo-500 bg-indigo-50'
                : 'border-gray-300 hover:border-indigo-400'
            }`}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="space-y-1 text-center">
              <DocumentArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                >
                  <span>Upload files</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    ref={fileInputRef}
                    className="sr-only"
                    multiple
                    onChange={handleFileInputChange}
                    accept=".jpg,.jpeg,.png,.pdf"
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-gray-500">
                PNG, JPG, PDF up to 30MB each
              </p>
            </div>
          </div>
        </div>

        {documents.length > 0 && (
          <div className="mt-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Uploaded Files:
            </h4>
            <ul className="space-y-2">
              {documents.map((file, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center p-2 bg-gray-50 rounded-lg"
                >
                  <span className="text-sm text-gray-600 truncate max-w-xs">
                    {file.name}
                  </span>
                  <button
                    type="button"
                    onClick={() => removeFile(index)}
                    className="ml-2 text-gray-400 hover:text-red-500"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800 whitespace-pre-line">
                  {error}
                </h3>
              </div>
            </div>
          </div>
        )}

        {success && (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">
                  Verification documents submitted successfully! We will review
                  your documents and update your account status.
                </h3>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isSubmitting}
            className={`inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {isSubmitting ? 'Submitting...' : 'Submit for Verification'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default IdentityVerificationForm;

// src/components/cta/AutoApplyCTA.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  RocketLaunchIcon,
  CheckCircleIcon,
  ArrowRightIcon
} from '@heroicons/react/24/solid';
import { motion } from 'framer-motion';
import image18 from '../../../src/media/images/Two_people_looking_happy_that_they_found_their_dre (1).png';

interface AutoApplyCTAProps {
  className?: string;
  variant?: 'default' | 'compact' | 'inline';
  hideImage?: boolean;
}

// Usages
// // In a main search results page (full version)
// const SearchResultsPage = () => {
//   return (
//     <div className="container mx-auto px-4">
//       <AutoApplyCTA className="mb-8" />
//       {/* Rest of the search results content */}
//     </div>
//   );
// };

// // In a property details page (compact version)
// const PropertyDetailsPage = () => {
//   return (
//     <div className="container mx-auto px-4">
//       <AutoApplyCTA variant="compact" className="mb-4" />
//       {/* Property details content */}
//     </div>
//   );
// };

// // In a sidebar or small space (inline version)
// const SearchFiltersSidebar = () => {
//   return (
//     <div className="space-y-4">
//       <AutoApplyCTA variant="inline" />
//       {/* Other sidebar content */}
//     </div>
//   );
// };

// // Without image for narrower layouts
// const NarrowLayoutPage = () => {
//   return (
//     <div className="container mx-auto px-4 max-w-2xl">
//       <AutoApplyCTA hideImage={true} className="mb-8" />
//       {/* Page content */}
//     </div>
//   );
// };

const AutoApplyCTA: React.FC<AutoApplyCTAProps> = ({
  className = '',
  variant = 'default',
  hideImage = false
}) => {
  const navigate = useNavigate();

  const renderFeatures = () => (
    <div className="flex flex-col space-y-3">
      {[
        'Quick application process',
        'Free to use for renters',
        'Automatic matching with properties',
        'Direct communication with landlords',
      ].map((feature, index) => (
        <div key={index} className="flex items-center space-x-2">
          <CheckCircleIcon className="w-5 h-5 text-green-500 flex-shrink-0" />
          <span className="text-gray-600">{feature}</span>
        </div>
      ))}
    </div>
  );

  if (variant === 'compact') {
    return (
      <div className={`bg-white rounded-lg shadow-md p-4 ${className}`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <RocketLaunchIcon className="w-8 h-8 text-blue-500" />
            <div>
              <h3 className="font-semibold text-gray-800">Enable Auto-Apply</h3>
              <p className="text-sm text-gray-500">
                Speed up your property search
              </p>
            </div>
          </div>
          <button
            onClick={() => navigate('/auto-apply/setup')}
            className="flex items-center space-x-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
          >
            <span>Get Started</span>
            <ArrowRightIcon className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  if (variant === 'inline') {
    return (
      <div className={`flex items-center space-x-4 ${className}`}>
        <RocketLaunchIcon className="w-6 h-6 text-blue-500" />
        <div className="flex-grow">
          <p className="text-sm font-medium text-gray-900">
            Speed up your search with Auto-Apply
          </p>
          <p className="text-xs text-gray-500">
            Save time by automatically applying to matching properties
          </p>
        </div>
        <button
          onClick={() => navigate('/auto-apply/setup')}
          className="text-blue-500 hover:text-blue-700 text-sm font-medium"
        >
          Enable
        </button>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className={`bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl shadow-lg overflow-hidden ${className} mt-4`}
    >
      <div className="p-8">
        <div className="flex flex-col lg:flex-row items-center gap-8">
          <div className="flex-1 space-y-6">
            <div className="flex items-center space-x-2">
              <RocketLaunchIcon className="w-8 h-8 text-blue-500" />
              <h2 className="text-3xl font-bold text-gray-900">
                Supercharge Your Property Search
              </h2>
            </div>

            <p className="text-lg text-gray-600">
              Enable Auto-Apply and let us automatically submit your application
              to properties that match your criteria. Save time and be among the
              first to apply!
            </p>

            {renderFeatures()}

            <div className="flex flex-col sm:flex-row gap-4 pt-4">
              <button
                onClick={() => navigate('/auto-apply')}
                className="flex items-center justify-center space-x-2 bg-blue-500 text-white px-8 py-3 rounded-lg hover:bg-blue-600 transform hover:-translate-y-1 transition-all duration-300 shadow-md"
              >
                <span>Get Started</span>
                <ArrowRightIcon className="w-5 h-5" />
              </button>
            </div>
          </div>

          {!hideImage && (
            <div className="flex-1 lg:flex justify-center hidden">
              <motion.img
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.2 }}
                src={image18}
                alt="Auto-apply illustration"
                className="rounded-lg shadow-lg"
              />
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default AutoApplyCTA;

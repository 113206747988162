// src/components/email-verification/EmailVerificationRequired.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MailCheck, AlertCircle, Loader2, CheckCircle } from 'lucide-react';

interface EmailVerificationRequiredProps {
  userEmail: string;
  userId: string;
  userName: string;
}

interface ResendStatus {
  lastAttempt: number;
  attempts: number;
}

const EMAIL_COOLDOWN = 60000; // 1 minute
const MAX_ATTEMPTS = 3; // Maximum attempts per hour
const ATTEMPTS_RESET = 3600000; // 1 hour

const CLOUD_FUNCTION_URL =
  'https://resendverificationemail-byx4ztggoq-ew.a.run.app';

const EmailVerificationRequired: React.FC<EmailVerificationRequiredProps> = ({
  userEmail,
  userId,
  userName
}) => {
  const [isResending, setIsResending] = useState(false);
  const [resendStatus, setResendStatus] = useState<ResendStatus | null>(null);
  const [cooldownRemaining, setCooldownRemaining] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Load previous resend attempts from localStorage
    const storedStatus = localStorage.getItem(`emailVerification_${userId}`);
    if (storedStatus) {
      const status = JSON.parse(storedStatus);
      // Check if we should reset attempts (after 1 hour)
      if (Date.now() - status.lastAttempt > ATTEMPTS_RESET) {
        const newStatus = { lastAttempt: 0, attempts: 0 };
        setResendStatus(newStatus);
        localStorage.setItem(
          `emailVerification_${userId}`,
          JSON.stringify(newStatus)
        );
      } else {
        setResendStatus(status);
        if (Date.now() - status.lastAttempt < EMAIL_COOLDOWN) {
          setCooldownRemaining(
            EMAIL_COOLDOWN - (Date.now() - status.lastAttempt)
          );
        }
      }
    } else {
      const initialStatus = { lastAttempt: 0, attempts: 0 };
      setResendStatus(initialStatus);
      localStorage.setItem(
        `emailVerification_${userId}`,
        JSON.stringify(initialStatus)
      );
    }
  }, [userId]);

  useEffect(() => {
    // Countdown timer for cooldown
    if (cooldownRemaining > 0) {
      const timer = setInterval(() => {
        setCooldownRemaining((prev) => Math.max(0, prev - 1000));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [cooldownRemaining]);

 const handleResendVerification = async () => {
   setError(null);
   setSuccess(null);

   // Check local rate limiting
   if (!resendStatus) return;

   if (cooldownRemaining > 0) {
     setError(
       `Please wait ${Math.ceil(cooldownRemaining / 1000)} seconds before trying again.`
     );
     return;
   }

   setIsResending(true);
   try {
     const response = await fetch(CLOUD_FUNCTION_URL, {
       method: 'POST',
       headers: {
         'Content-Type': 'application/json'
       },
       body: JSON.stringify({
         email: userEmail
       })
     });

     const data = await response.json();

     if (!response.ok) {
       if (response.status === 429) {
         const resetTime = new Date(data.resetTime);
         setError(
           `Too many attempts. Please try again after ${resetTime.toLocaleTimeString()}`
         );
       } else {
         throw new Error(data.error || 'Failed to send verification email');
       }
       return;
     }

     // Update local rate limiting
     const newStatus = {
       lastAttempt: Date.now(),
       attempts: (resendStatus.attempts || 0) + 1
     };
     setResendStatus(newStatus);
     localStorage.setItem(
       `emailVerification_${userEmail}`, // Use email instead of userId
       JSON.stringify(newStatus)
     );
     setCooldownRemaining(EMAIL_COOLDOWN);

     setSuccess(
       'Verification email sent! Please check your inbox and spam folder.'
     );
   } catch (error) {
     setError('Failed to send verification email. Please try again later.');
   } finally {
     setIsResending(false);
   }
 };

  return (
    <div className="min-h-[60vh] flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-8">
        <div className="text-center mb-6">
          <div className="inline-block p-3 bg-blue-100 rounded-full mb-4">
            <MailCheck className="w-8 h-8 text-blue-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            Email Verification Required
          </h2>
          <p className="text-gray-600">
            To create a listing, please verify your email address first.
          </p>
        </div>

        <div className="space-y-4">
          <div className="bg-gray-50 rounded-lg p-4">
            <div className="flex items-start space-x-3">
              <AlertCircle className="w-5 h-5 text-blue-500 mt-0.5 flex-shrink-0" />
              <div>
                <p className="text-sm text-gray-600">
                  A verification email was sent to:
                </p>
                <p className="font-medium text-gray-900">{userEmail}</p>
              </div>
            </div>
          </div>

          {error && (
            <div className="bg-red-50 text-red-700 p-4 rounded-lg text-sm">
              {error}
            </div>
          )}

          {success && (
            <div className="bg-green-50 text-green-700 p-4 rounded-lg text-sm flex items-center space-x-2">
              <CheckCircle className="w-5 h-5" />
              <span>{success}</span>
            </div>
          )}

          <div className="bg-blue-50 p-4 rounded-lg">
            <h2 className="text-2xl font-semibold text-blue-900 mb-2">
              Haven't received the email?
            </h2>
            <ul className="text-sm text-blue-800 space-y-2 mb-4">
              <li>• Check your spam/junk folder</li>
              <li>• Make sure your email address is correct</li>
              <li>• Wait a few minutes before trying again</li>
            </ul>

            <button
              onClick={handleResendVerification}
              disabled={isResending || cooldownRemaining > 0}
              className={`w-full flex items-center justify-center px-4 py-2 rounded-md text-sm font-medium ${
                isResending || cooldownRemaining > 0
                  ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
              } transition-colors duration-150`}
            >
              {isResending ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin mr-2" />
                  Sending...
                </>
              ) : cooldownRemaining > 0 ? (
                `Wait ${Math.ceil(cooldownRemaining / 1000)}s to resend`
              ) : (
                'Resend Verification Email'
              )}
            </button>

            {resendStatus && (
              <p className="text-xs text-gray-500 text-center mt-2">
                {MAX_ATTEMPTS - resendStatus.attempts} attempts remaining
              </p>
            )}
          </div>

          <div className="text-center">
            <button
              onClick={() => navigate('/dashboard')}
              className="text-blue-600 hover:text-blue-800 text-sm"
            >
              Return to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationRequired;

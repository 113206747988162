import React, { useEffect, useState } from 'react';
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ChevronLeftIcon
} from '@heroicons/react/24/solid';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getDocs,
  limit,
  startAfter
} from 'firebase/firestore';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import GuestInquiryView from './GuestInquiryView';
import { db } from '../../../auth/firebaseConfig';
import { Conversation, GuestInquiry, Message } from './messagesTypes';


interface MessageViewProps {
  selectedConversation: string | null;
  selectedGuestInquiry: GuestInquiry | null;
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  currentUser: any; // Replace 'any' with your user type
  onBackToList: () => void;
  isMobileView: boolean;
  namePhoto: { [key: string]: { name: string; photoUrl: string | null } };
  onSendMessage: (content: string) => void;
  onUploadFile: (file: File) => void;
  onArchiveConversation: (id: string, archive: boolean) => void;
  conversations: Conversation[];
  messagesEndRef: React.RefObject<HTMLDivElement>;
}

const MessageView: React.FC<MessageViewProps> = ({
  selectedConversation,
  selectedGuestInquiry,
  messages,
  setMessages,
  currentUser,
  onBackToList,
  isMobileView,
  namePhoto,
  onSendMessage,
  onUploadFile,
  onArchiveConversation,
  conversations,
  messagesEndRef
}) => {
  const selectedConversationData = conversations.find(
    (c) => c.id === selectedConversation
  );
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (selectedConversation) {
      loadInitialMessages();
    }
  }, [selectedConversation]);

  console.log('selectedConversation: ', selectedConversation);

  const loadInitialMessages = async () => {
    if (!selectedConversation) return;
    setLoading(true);
    const messagesQuery = query(
      collection(db, 'conversations', selectedConversation, 'messages'),
      orderBy('timestamp', 'desc'),
      limit(10)
    );
    const snapshot = await getDocs(messagesQuery);
    const fetchedMessages = snapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }) as Message)
      .reverse();
    setMessages(fetchedMessages);
    setLoading(false);
    setHasMore(snapshot.docs.length === 10);
  };

  const loadMoreMessages = async () => {
    if (!selectedConversation || loading || !hasMore) return;
    setLoading(true);
    const lastMessage = messages[0];
    const messagesQuery = query(
      collection(db, 'conversations', selectedConversation, 'messages'),
      orderBy('timestamp', 'desc'),
      startAfter(lastMessage.timestamp),
      limit(10)
    );
    const snapshot = await getDocs(messagesQuery);
    const newMessages = snapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }) as Message)
      .reverse();
    setMessages((prevMessages) => [...newMessages, ...prevMessages]);
    setLoading(false);
    setHasMore(snapshot.docs.length === 10);
  };

  useEffect(() => {
    if (selectedConversation) {
      const messagesQuery = query(
        collection(db, 'conversations', selectedConversation, 'messages'),
        orderBy('timestamp', 'asc')
      );

      const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const newMessages = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        })) as Message[];
        setMessages(newMessages);
      });

      return () => unsubscribe();
    }
  }, [selectedConversation, setMessages]);

  if (!selectedConversation && !selectedGuestInquiry) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center p-4">
        <p className="text-gray-500 text-lg mb-4">
          Select a conversation or guest inquiry to view messages
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      {selectedConversation ? (
        <>
          <div className="p-4 border-b flex items-center justify-between bg-white">
            <div className="flex items-center">
              {isMobileView && (
                <button
                  onClick={onBackToList}
                  className="mr-4 text-gray-600 hover:text-gray-800"
                >
                  <ChevronLeftIcon className="h-6 w-6" />
                </button>
              )}
              <h2 className="font-semibold text-gray-800 text-xl">
                {namePhoto[selectedConversation]?.name || 'Conversation'}
              </h2>
            </div>
            <button
              onClick={() =>
                onArchiveConversation(
                  selectedConversation,
                  !selectedConversationData?.archived
                )
              }
              className="text-gray-500 hover:text-blue-600"
              title={
                selectedConversationData?.archived
                  ? 'Unarchive conversation'
                  : 'Archive conversation'
              }
            >
              {selectedConversationData?.archived ? (
                <ArchiveBoxXMarkIcon className="h-6 w-6" />
              ) : (
                <ArchiveBoxIcon className="h-6 w-6" />
              )}
            </button>
          </div>
          <div className="flex-grow overflow-y-auto">
            <MessageList
              messages={messages}
              currentUser={currentUser}
              namePhoto={namePhoto}
              onLoadMore={loadMoreMessages}
              hasMore={false}
              loading={false}
            />
            <div ref={messagesEndRef} />
          </div>
          <MessageInput
            onSendMessage={onSendMessage}
            onUploadFile={onUploadFile}
          />
        </>
      ) : selectedGuestInquiry ? (
        <GuestInquiryView
          inquiry={selectedGuestInquiry}
          onBackToList={onBackToList}
          isMobileView={isMobileView}
        />
      ) : (
        <div className="flex flex-col items-center justify-center h-full text-center p-4">
          <p className="text-gray-500 text-lg mb-4">
            Select a conversation or guest inquiry to view messages
          </p>
        </div>
      )}
    </div>
  );
};

export default MessageView;

import React from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';

interface FullScreenImageViewerProps {
  imageUrl: string;
  onClose: () => void;
}

const FullScreenImageViewer: React.FC<FullScreenImageViewerProps> = ({
  imageUrl,
  onClose
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative max-w-4xl max-h-full">
        <img
          src={imageUrl}
          alt="Full screen"
          className="max-w-full max-h-[90vh] object-contain"
        />
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-gray-300"
        >
          <XMarkIcon className="h-8 w-8" />
        </button>
      </div>
    </div>
  );
};

export default FullScreenImageViewer;

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/firebaseAuth';
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  Timestamp,
  serverTimestamp,
  doc,
  updateDoc,
  writeBatch
} from 'firebase/firestore';
import { db } from '../../auth/firebaseConfig';
import {
  getUserById,
  insertDocument,
  updateDocument
} from '../../utility/firebaseHelpers';
import { uploadFile } from '../../utility/firebaseStorageHelpers';
import {
  FirebaseConversation,
  GuestInquiry,
  GuestInquiryConversation,
  Message,
  RawGuestInquiry,
  RegularConversation
} from '../../components/messages/ConversationList/messagesTypes';
import MessageView from '../../components/messages/ConversationList/MessageView';
import UnifiedConversationList from '../../components/messages/ConversationList/UnifiedConversationList';

const MessagesPage: React.FC = () => {
  const { currentUser } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [conversations, setConversations] = useState<RegularConversation[]>([]);
  const [rawGuestInquiries, setRawGuestInquiries] = useState<RawGuestInquiry[]>(
    []
  );
  const [guestInquiries, setGuestInquiries] = useState<GuestInquiry[]>([]);

  const [activeView, setActiveView] = useState<
    'list' | 'conversation' | 'guestInquiry'
  >('list');
  const [selectedConversation, setSelectedConversation] = useState<
    string | null
  >(null);
  const [selectedGuestInquiry, setSelectedGuestInquiry] =
    useState<GuestInquiry | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [namePhoto, setNamePhoto] = useState<{
    [key: string]: { name: string; photoUrl: string | null };
  }>({});
  const [isMobileView, setIsMobileView] = useState(false);

  const handleArchiveConversation = async (
    conversationId: string,
    archive: boolean
  ) => {
    try {
      await updateDoc(doc(db, 'conversations', conversationId), {
        archived: archive
      });
      setConversations((prev) =>
        prev.map((conv) =>
          conv.id === conversationId ? { ...conv, archived: archive } : conv
        )
      );
      if (selectedConversation === conversationId && archive) {
        setSelectedConversation(null);
      }
    } catch (error) {
      console.error('Error archiving conversation:', error);
    }
  };

  useEffect(() => {
    if (id) {
      setSelectedConversation(id);
    }
  }, [id]);

  const prevMessages = usePrevious(messages);

  // Transform raw guest inquiry to processed guest inquiry
  const processGuestInquiry = useCallback(
    (raw: RawGuestInquiry): GuestInquiry => ({
      ...raw,
      timestamp:
        raw.timestamp instanceof Timestamp
          ? raw.timestamp
          : Timestamp.fromDate(new Date()),
      archived: raw.archived || false
    }),
    []
  );

  const processConversation = useCallback(
    (id: string, data: FirebaseConversation): RegularConversation => ({
      id,
      type: 'conversation',
      participants: data.participants || [],
      lastMessage: data.lastMessage || '',
      lastMessageTimestamp:
        data.lastMessageTimestamp instanceof Timestamp
          ? data.lastMessageTimestamp
          : Timestamp.fromDate(new Date()),
      archived: data.archived || false
    }),
    []
  );

  useEffect(() => {
    if (
      prevMessages &&
      messages.length > prevMessages.length &&
      selectedConversation
    ) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, prevMessages, selectedConversation]);

  useEffect(() => {
    const handleResize = () => setIsMobileView(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUserName = useCallback(
    async (userId: string) => {
      if (namePhoto[userId]) return namePhoto[userId]; // Return both name and photoUrl if cached

      try {
        const user = await getUserById(userId); // Assume this fetches both name and photoUrl
        if (user) {
          setNamePhoto((prev) => ({
            ...prev,
            [userId]: { name: user.name, photoUrl: user.photoUrl || null } // Store name and photoUrl
          }));
          return { name: user.name, photoUrl: user.photoUrl || null };
        }
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
      return { name: 'Unknown User', photoUrl: null };
    },
    [namePhoto]
  );

  // Update the useEffect that fetches conversations
useEffect(() => {
  if (!currentUser) return;

  const conversationsQuery = query(
    collection(db, 'conversations'),
    where('participants', 'array-contains', currentUser.uid),
    orderBy('lastMessageTimestamp', 'desc')
  );

  const unsubscribeConversations = onSnapshot(conversationsQuery, {
    next: async (snapshot) => {
      try {
        const fetchedConversations = snapshot.docs.map((doc) => {
          const data = doc.data() as FirebaseConversation;
          return processConversation(doc.id, data);
        });

        setConversations(fetchedConversations);

        // Fetch user names for all participants
        const participantIds = new Set(
          fetchedConversations.flatMap((conv) => conv.participants)
        );

        participantIds.forEach((id) => {
          if (id !== currentUser.uid) {
            fetchUserName(id).catch((error) => {
              console.error('Error fetching user name:', error);
            });
          }
        });
      } catch (error) {
        console.error('Error processing conversations:', error);
      }
    },
    error: (error) => {
      console.error('Error in conversations subscription:', error);
    }
  });

  const inquiriesQuery = query(
    collection(db, 'guestInquiries'),
    where('recipientId', '==', currentUser.uid),
    orderBy('timestamp', 'desc')
  );

  const unsubscribeInquiries = onSnapshot(inquiriesQuery, {
    next: (snapshot) => {
      try {
        const fetchedInquiries = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data()
            }) as RawGuestInquiry
        );
        setRawGuestInquiries(fetchedInquiries);
      } catch (error) {
        console.error('Error processing guest inquiries:', error);
      }
    },
    error: (error) => {
      console.error('Error in guest inquiries subscription:', error);
    }
  });

  return () => {
    unsubscribeConversations();
    unsubscribeInquiries();
  };
}, [currentUser, fetchUserName, processConversation]);

  useEffect(() => {
    if (messages.length > 0) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (isMobileView) {
      if (selectedConversation) {
        setActiveView('conversation');
      } else if (selectedGuestInquiry) {
        setActiveView('guestInquiry');
      } else {
        setActiveView('list');
      }
    } else {
      setActiveView('list');
    }
  }, [isMobileView, selectedConversation, selectedGuestInquiry]);

  const handleBackToList = () => {
    setActiveView('list');
    setSelectedConversation(null);
    setSelectedGuestInquiry(null);
  };

  const handleSendMessage = async (content: string) => {
    if (content.trim() && selectedConversation && currentUser) {
      const timestamp = Timestamp.fromDate(new Date()); // Use Timestamp instead of serverTimestamp
      const messageData: Omit<Message, 'id'> = {
        senderId: currentUser.uid,
        recipientId:
          conversations
            .find((c) => c.id === selectedConversation)
            ?.participants.find((p) => p !== currentUser.uid) || '',
        content,
        timestamp,
        status: 'sent'
      };

      const batch = writeBatch(db);
      
      try {
        const messageRef = doc(
          collection(db, `conversations/${selectedConversation}/messages`)
        );
        batch.set(messageRef, messageData);

        // Update conversation's last message
        const conversationRef = doc(db, 'conversations', selectedConversation);
        batch.update(conversationRef, {
          lastMessage: content,
          lastMessageTimestamp: timestamp
        });

        await batch.commit();
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleUploadFile = async (file: File) => {
    if (!selectedConversation || !currentUser) return;

    try {
      const attachmentUrl = await uploadFile(
        file,
        `conversations/${selectedConversation}/attachments/${file.name}`
      );
      const messageData: Omit<Message, 'id'> = {
        senderId: currentUser.uid,
        recipientId:
          conversations
            .find((c) => c.id === selectedConversation)
            ?.participants.find((p) => p !== currentUser.uid) || '',
        content: '',
        timestamp: serverTimestamp() as Timestamp,
        status: 'sent',
        attachmentUrl,
        attachmentType: file.type
      };

      await insertDocument(
        `conversations/${selectedConversation}/messages`,
        messageData
      );
      await updateDocument('conversations', selectedConversation, {
        lastMessage: 'Attachment',
        lastMessageTimestamp: serverTimestamp()
      });
    } catch (error) {
      console.error('Error uploading attachment:', error);
    }
  };

  // Add a function to handle archiving guest inquiries
  const handleArchiveGuestInquiry = async (
    inquiryId: string,
    archive: boolean
  ) => {
    try {
      await updateDoc(doc(db, 'guestInquiries', inquiryId), {
        archived: archive
      });
      setGuestInquiries((prev) =>
        prev.map((inquiry) =>
          inquiry.id === inquiryId ? { ...inquiry, archived: archive } : inquiry
        )
      );
      if (selectedGuestInquiry?.id === inquiryId && archive) {
        setSelectedGuestInquiry(null);
      }
    } catch (error) {
      console.error('Error archiving guest inquiry:', error);
    }
  };

  // Transform processed guest inquiry to conversation format
  const transformToGuestInquiryConversation = useCallback(
    (inquiry: GuestInquiry): GuestInquiryConversation => ({
      id: inquiry.id,
      type: 'guestInquiry',
      guestEmail: inquiry.senderEmail,
      guestName: inquiry.senderName,
      recipientId: inquiry.recipientId,
      archived: inquiry.archived || false,
      lastMessage: inquiry.content,
      lastMessageTimestamp: inquiry.timestamp
    }),
    []
  );

  // Process guest inquiries
  const processedGuestInquiries = useMemo(
    () => rawGuestInquiries.map(processGuestInquiry),
    [rawGuestInquiries, processGuestInquiry]
  );

  const handleSelectItem = (
    id: string,
    type: 'conversation' | 'guestInquiry'
  ) => {
    if (type === 'conversation') {
      setSelectedConversation(id);
      setSelectedGuestInquiry(null);
      if (isMobileView) setActiveView('conversation');
      navigate(`/messages/${id}`);
    } else {
      // Use processedGuestInquiries instead of guestInquiries
      const inquiry =
        processedGuestInquiries.find((gi) => gi.id === id) || null;
      setSelectedGuestInquiry(inquiry);
      setSelectedConversation(null);
      if (isMobileView) setActiveView('guestInquiry');
    }
  };

  useEffect(() => {
    // Update guestInquiries state whenever rawGuestInquiries changes
    setGuestInquiries(processedGuestInquiries);
  }, [processedGuestInquiries]);

  const handleArchiveItem = (
    id: string,
    archive: boolean,
    type: 'conversation' | 'guestInquiry'
  ) => {
    if (type === 'conversation') {
      handleArchiveConversation(id, archive);
    } else {
      handleArchiveGuestInquiry(id, archive);
    }
  };

  // Transform conversations to include type and ensure timestamp is Timestamp
  const transformedConversations: RegularConversation[] = conversations.map(
    (conv) => ({
      ...conv,
      type: 'conversation',
      lastMessageTimestamp:
        conv.lastMessageTimestamp instanceof Timestamp
          ? conv.lastMessageTimestamp
          : Timestamp.fromDate(new Date())
    })
  );

  // Transform to conversation format for unified list
  const transformedGuestInquiries = useMemo(
    () => processedGuestInquiries.map(transformToGuestInquiryConversation),
    [processedGuestInquiries, transformToGuestInquiryConversation]
  );

  return (
    <div className="h-screen flex flex-col bg-gray-50">
      <div className="border-b border-gray-200 bg-white">
        <h1 className="text-2xl font-semibold text-gray-800 p-4">Messages</h1>
      </div>
      <div className="flex-grow flex overflow-hidden">
        {(!isMobileView || activeView === 'list') && (
          <div
            className={`${
              isMobileView ? 'w-full' : 'w-1/3'
            } bg-white border-r border-gray-200 flex flex-col`}
          >
            <UnifiedConversationList
              conversations={transformedConversations}
              guestInquiries={transformedGuestInquiries}
              selectedItemId={
                selectedConversation || selectedGuestInquiry?.id || null
              }
              onSelectItem={handleSelectItem}
              onArchiveItem={handleArchiveItem}
              namePhoto={namePhoto}
              currentUser={currentUser}
            />
          </div>
        )}
        {(!isMobileView ||
          activeView === 'conversation' ||
          activeView === 'guestInquiry') && (
          <div
            className={`${
              isMobileView ? 'w-full' : 'w-2/3'
            } bg-white flex flex-col`}
          >
            <MessageView
              selectedConversation={selectedConversation}
              selectedGuestInquiry={selectedGuestInquiry}
              messages={messages}
              setMessages={setMessages}
              currentUser={currentUser}
              messagesEndRef={messagesEndRef}
              onBackToList={handleBackToList}
              isMobileView={isMobileView}
              namePhoto={namePhoto}
              onSendMessage={handleSendMessage}
              onUploadFile={handleUploadFile}
              onArchiveConversation={handleArchiveConversation}
              conversations={conversations}
            />
          </div>
        )}
      </div>
    </div>
  );
};

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>(undefined);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export default MessagesPage;

// src/analytics/firebaseAnalytics.ts
import {
  logEvent as firebaseLogEvent,
  setUserProperties,
  AnalyticsCallOptions,
  Analytics as FirebaseAnalytics,
  EventNameString
} from 'firebase/analytics';
import { User } from 'firebase/auth';
import { analytics } from '../auth/firebaseConfig';

/**
 * FIREBASE ANALYTICS USAGE
 * const analytics = useAnalyticsWithConsent();
  
  // Using predefined events
  analytics.logEvent(AnalyticsEvents.PAGE_VIEW, {
    screen_name: 'home'
  });
  
  // Using helper methods
  analytics.trackPageView('home', {
    source: 'navigation'
  });
  
  analytics.trackPropertyView('123', {
    type: 'apartment',
    price: 1500,
    location: 'Dublin'
  });
 */

// Define custom event types that extend Firebase's EventNameString
// Define custom event types
type CustomEventName =
  // User Events
  | 'user_signup'
  | 'user_login'
  | 'user_logout'
  | 'profile_update'
  | 'user_journey'
  | 'email_verification'
  | 'verification_email_resent'
  | 'email_verified'

  // Property Events
  | 'property_view'
  | 'property_search'
  | 'property_filter'
  | 'property_inquiry'
  | 'property_engagement'
  | 'property_listing_create'
  | 'property_listing_update'
  | 'property_listing_delete'

  // Engagement Events
  | 'screen_view'
  | 'select_content'
  | 'content_engagement'
  | 'feature_usage'
  | 'user_interaction'
  | 'newsletter_subscription'
  | 'newsletter_unsubscribe'

  // Interaction Events
  | 'bookmark_interaction'
  | 'share_interaction'
  | 'property_engagement'
  | 'content_engagement'

  // Search Events
  | 'search_behavior'
  | 'search_results_view'
  | 'property_search'
  | 'search_results'
  | 'search_sort'
  | 'search_session_end'
  | 'pagination_interaction'

  // Performance Events
  | 'performance_metrics'
  | 'error_occurred'

  // Messaging Events
  | 'message_sent'
  | 'message_read';

// Combine custom events with Firebase's built-in events
type ValidEventName = EventNameString | CustomEventName;

// Define event names object
export const AnalyticsEvents = {
  // User Events
  USER_SIGNUP: 'user_signup' as ValidEventName,
  USER_LOGIN: 'user_login' as ValidEventName,
  USER_LOGOUT: 'user_logout' as ValidEventName,
  PROFILE_UPDATE: 'profile_update' as ValidEventName,
  USER_JOURNEY: 'user_journey' as ValidEventName,
  EMAIL_VERIFICATION: 'email_verification' as ValidEventName,
  VERIFICATION_EMAIL_SENT: 'verification_email_resent' as ValidEventName,
  EMAIL_VERIFIED: 'email_verified' as ValidEventName,

  // Property Events
  PROPERTY_VIEW: 'property_view' as ValidEventName,
  PROPERTY_SEARCH: 'property_search' as ValidEventName,
  PROPERTY_FILTER: 'property_filter' as ValidEventName,
  PROPERTY_INQUIRY: 'property_inquiry' as ValidEventName,
  PROPERTY_ENGAGEMENT: 'property_engagement' as ValidEventName,
  PROPERTY_LISTING_CREATE: 'property_listing_create' as ValidEventName,
  PROPERTY_LISTING_UPDATE: 'property_listing_update' as ValidEventName,
  PROPERTY_LISTING_DELETE: 'property_listing_delete' as ValidEventName,

  // Engagement Events
  PAGE_VIEW: 'screen_view' as ValidEventName,
  CONTENT_ENGAGEMENT: 'content_engagement' as ValidEventName,
  FEATURE_USE: 'feature_usage' as ValidEventName,
  USER_INTERACTION: 'user_interaction' as ValidEventName,
  NEWSLETTER_SUBSCRIPTION: 'newsletter_subscription' as ValidEventName,
  NEWSLETTER_UNSUBSCRIPTION: 'newsletter_unsubscribe' as ValidEventName,

  // Search Events
  SEARCH_BEHAVIOR: 'search_behavior' as ValidEventName,
  SEARCH_RESULTS_VIEW: 'search_results_view' as ValidEventName,
  SEARCH_RESULTS: 'search_results' as ValidEventName,
  SEARCH_SORT: 'search_sort' as ValidEventName,
  SEARCH_SESSION_END: 'search_session_end' as ValidEventName,
  PAGINATION_INTERACTION: 'pagination_interaction' as ValidEventName,

  // Interaction Events
  BOOKMARK_INTERACTION: 'bookmark_interaction' as ValidEventName,
  SHARE_INTERACTION: 'share_interaction' as ValidEventName,

  // Performance Events
  PERFORMANCE_METRICS: 'performance_metrics' as ValidEventName,
  ERROR_OCCURRED: 'error_occurred' as ValidEventName,

  // Messaging Events
  MESSAGE_SENT: 'message_sent' as ValidEventName,
  MESSAGE_READ: 'message_read' as ValidEventName
} as const;

// Analytics utility class
export class AnalyticsService {
  private analytics: FirebaseAnalytics;

  constructor() {
    this.analytics = analytics;
  }

  // Log a basic event
  // Update the logEvent function to cast eventName as `string` to bypass TypeScript's strict type checking
  /**
   *
   * @param eventName
   * @param eventParams
   * @param options
   *
   * This will allow you to log custom events without TypeScript errors, but be cautious: Firebase will ignore any events it doesn’t recognize by default, so ensure the event names used align with Firebase standards where possible.
   */
  logEvent(
    eventName: ValidEventName,
    eventParams?: Record<string, any>,
    options?: AnalyticsCallOptions
  ) {
    try {
      // Cast `eventName` as string to satisfy `firebaseLogEvent` type requirement
      firebaseLogEvent(
        this.analytics,
        eventName as string,
        eventParams,
        options
      );
    } catch (error) {
      console.error('Analytics event logging error:', error);
    }
  }

  // Set user properties
  setUserProperties(properties: Record<string, any>) {
    try {
      setUserProperties(this.analytics, properties);
    } catch (error) {
      console.error('Setting user properties error:', error);
    }
  }

  // Track page views using Firebase's screen_view event
  trackPageView(pageName: string, pageParams?: Record<string, any>) {
    this.logEvent('screen_view', {
      screen_name: pageName,
      ...pageParams
    });
  }

  // Track user actions using Firebase's select_content event
  trackUserAction(
    action: string,
    category: string,
    label?: string,
    value?: number
  ) {
    this.logEvent('select_content', {
      content_type: category,
      content_id: action,
      item_id: label,
      value
    });
  }

  // Track property views
  trackPropertyView(propertyId: number, propertyData: any) {
    this.logEvent('property_view', {
      property_id: propertyId,
      property_type: propertyData.type,
      property_price: propertyData.price,
      property_location: propertyData.location
    });
  }

  // Track search and filters
  trackPropertySearch(searchParams: Record<string, any>) {
    this.logEvent('property_search', {
      search_query: searchParams.query,
      filters_used: searchParams.filters,
      results_count: searchParams.resultsCount
    });
  }

  // Track errors
  trackError(errorCode: string, errorMessage: string, errorContext?: any) {
    this.logEvent('error_occurred', {
      error_code: errorCode,
      error_message: errorMessage,
      error_context: errorContext
    });
  }

  // Initialize user analytics
  initializeUser(user: User | null) {
    if (user) {
      this.setUserProperties({
        user_id: user.uid,
        user_type: user.email?.includes('@rentsmart.ie') ? 'staff' : 'user',
        account_creation_date: user.metadata.creationTime
      });
    }
  }
}

// Create a single instance of the analytics service
export const analyticsService = new AnalyticsService();

// Export types for use in other files
export type { ValidEventName };

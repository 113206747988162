import React from 'react';
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TelegramShareButton,
  TelegramIcon,
  XIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import { Listing } from '../property/property-card/PropertyCardTypes';
import { ClockIcon } from 'lucide-react';

export const ShareToApplyButton: React.FC<{ listing: Listing }> = ({
  listing
}) => {
  const generateShareContent = () => ({
    title: `${listing.title} - Available in ${listing.location}`,
    text: `Found this ${listing.bedrooms}-bedroom ${listing.type} for €${listing.price} in ${listing.location} on RentSmart.ie! Check it out:`,
    url: `https://rentsmart.ie/property/${listing.id}`
  });

  const shareIconSize = 35;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t p-3 md:relative md:border-none md:p-0 shadow-lg z-50">
      <div className="flex flex-col space-y-2">
        <p className="text-center text-lg font-medium flex items-center justify-center">
          <ClockIcon className="h-6 w-6 text-red-600 mr-2" />
          Share with friends now before this property is gone!
        </p>
        <div className="flex flex-wrap justify-center space-x-3 md:space-x-4 mt-2">
          <WhatsappShareButton url={generateShareContent().url}>
            <div className="flex flex-col items-center">
              <div className="rounded-full flex items-center justify-center">
                <WhatsappIcon size={shareIconSize} round />
              </div>
              <span className="text-xs md:text-sm mt-1">WhatsApp</span>
            </div>
          </WhatsappShareButton>

          <FacebookShareButton url={generateShareContent().url}>
            <div className="flex flex-col items-center">
              <div className="rounded-full flex items-center justify-center">
                <FacebookIcon size={shareIconSize} round />
              </div>
              <span className="text-xs md:text-sm mt-1">Facebook</span>
            </div>
          </FacebookShareButton>

          <TwitterShareButton url={generateShareContent().url}>
            <div className="flex flex-col items-center">
              <div className="rounded-full flex items-center justify-center">
                <XIcon size={shareIconSize} round />
              </div>
              <span className="text-xs md:text-sm mt-1">X</span>
            </div>
          </TwitterShareButton>

          <TelegramShareButton url={generateShareContent().url}>
            <div className="flex flex-col items-center">
              <div className="rounded-full flex items-center justify-center">
                <TelegramIcon size={shareIconSize} round />
              </div>
              <span className="text-xs md:text-sm mt-1">Telegram</span>
            </div>
          </TelegramShareButton>

          <EmailShareButton
            subject={generateShareContent().text}
            body={generateShareContent().title}
            url={generateShareContent().url}
          >
            <div className="flex flex-col items-center">
              <div className="rounded-full flex items-center justify-center">
                <EmailIcon size={shareIconSize} round />
              </div>
              <span className="text-xs md:text-sm mt-1">Email</span>
            </div>
          </EmailShareButton>
        </div>
      </div>
    </div>
  );
};

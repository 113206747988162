import React from 'react';

interface BERDetailsProps {
  berRating: string;
}

const berScaleOrder = [
  'A1',
  'A2',
  'A3',
  'B1',
  'B2',
  'B3',
  'C1',
  'C2',
  'C3',
  'D1',
  'D2',
  'E1',
  'E2',
  'F',
  'G'
];

const getBerColor = (rating: string) => {
  const colors: { [key: string]: string } = {
    A1: 'bg-green-600',
    A2: 'bg-green-500',
    A3: 'bg-green-400',
    B1: 'bg-lime-500',
    B2: 'bg-lime-400',
    B3: 'bg-lime-300',
    C1: 'bg-yellow-500',
    C2: 'bg-yellow-400',
    C3: 'bg-yellow-300',
    D1: 'bg-orange-500',
    D2: 'bg-orange-400',
    E1: 'bg-red-500',
    E2: 'bg-red-400',
    F: 'bg-red-600',
    G: 'bg-red-700'
  };
  return colors[rating] || 'bg-gray-500';
};

const BERDetails: React.FC<BERDetailsProps> = ({ berRating }) => {
  const berIndex = berScaleOrder.indexOf(berRating);

  return (
    <div className="bg-white p-8 rounded-xl shadow-lg">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">BER Details</h2>
      <div className="flex flex-col items-center mb-6">
        <span
          className={`px-6 py-3 rounded-full text-white font-bold text-xl ${getBerColor(berRating)}`}
        >
          BER {berRating}
        </span>
        <p className="mt-2 text-sm text-gray-600">Building Energy Rating</p>
      </div>

      <div className="mb-6">
        <div className="flex justify-between mb-2">
          <span className="text-green-600 font-semibold">More efficient</span>
          <span className="text-red-600 font-semibold">Less efficient</span>
        </div>
        <div className="flex">
          {berScaleOrder.map((rating, index) => (
            <div
              key={rating}
              className={`flex-1 h-8 ${getBerColor(rating)} ${
                index === berIndex ? 'border-4 border-blue-500' : ''
              }`}
            ></div>
          ))}
        </div>
        <div className="flex justify-between mt-1">
          <span className="text-xs">A1</span>
          <span className="text-xs">G</span>
        </div>
      </div>

      <p className="text-sm text-gray-700 mb-4">
        The Building Energy Rating (BER) is an indication of the energy
        performance of this property. Properties with an A rating are the most
        energy-efficient and will have the lowest energy bills.
      </p>

      <div className="bg-blue-50 border-l-4 border-blue-500 p-4 rounded">
        <p className="text-sm text-blue-700">
          This property has a BER rating of {berRating}, which is
          {berIndex < 7
            ? ' good'
            : berIndex < 10
              ? ' average'
              : ' below average'}
          .
          {berIndex < 7
            ? ' It is energy-efficient and should have lower energy costs.'
            : berIndex < 10
              ? ' It has moderate energy efficiency and average energy costs.'
              : ' It may have higher energy costs and could benefit from energy-saving improvements.'}
        </p>
      </div>
    </div>
  );
};

export default BERDetails;

import React from 'react';
import { Link } from 'react-router-dom';
import { PencilIcon, EyeIcon, HomeModernIcon } from '@heroicons/react/24/solid';

interface Listing {
  id: string;
  landlordId: string;
  imageUrl: string;
  title: string;
  views: number;
}

interface ActiveListingsSnapshotProps {
  listings: Listing[];
}

const ActiveListingsSnapshot: React.FC<ActiveListingsSnapshotProps> = ({
  listings
}) => {
  return (
    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
      <h2 className="text-3xl font-extrabold mb-6 text-gray-800 flex items-center">
        <HomeModernIcon className="w-8 h-8 text-green-500 mr-2" />
        Active Listings
      </h2>
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {listings.length > 0 ? (
            listings.map((listing) => (
              <div
                key={listing.id}
                className="bg-gradient-to-r from-green-50 to-teal-50 rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300 overflow-hidden"
              >
                <img
                  src={listing.imageUrl}
                  alt={listing.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="font-bold text-gray-700 text-lg truncate">
                    {listing.title}
                  </h3>
                  <p className="text-sm text-gray-500 mt-1">
                    {listing.views} views
                  </p>
                  <div className="mt-4 flex justify-between items-center">
                    <Link
                      to={`/edit-listing/${listing.id}`}
                      data-testid={`edit-link-${listing.id}`}
                      className="flex items-center text-blue-500 hover:text-blue-600 transition duration-300"
                    >
                      <PencilIcon className="w-5 h-5 mr-1" />
                      Edit
                    </Link>
                    <Link
                      to={`/property/${listing.id}`}
                      data-testid={`view-link-${listing.id}`}
                      className="flex items-center text-green-500 hover:text-green-600 transition duration-300"
                    >
                      <EyeIcon className="w-5 h-5 mr-1" />
                      View
                    </Link>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center col-span-3">
              You currently have no active listings.
            </p>
          )}
        </div>

        {/* Action button container - now consistently positioned for both cases */}
        <div className="flex justify-center w-full pt-4">
          {listings.length > 0 ? (
            <Link
              to="/my-listings"
              className="flex items-center bg-gradient-to-r from-green-500 to-teal-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
            >
              <HomeModernIcon className="w-6 h-6 mr-2" />
              View All Listings
            </Link>
          ) : (
            <Link
              to="/add-listing"
              className="flex items-center bg-gradient-to-r from-green-500 to-teal-500 text-white px-6 py-3 rounded-lg hover:shadow-lg transform hover:-translate-y-1 transition-all duration-300"
            >
              <HomeModernIcon className="w-6 h-6 mr-2" />
              Add New Listing
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveListingsSnapshot;

import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import {
  BookmarkIcon,
  ShareIcon,
  XMarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CameraIcon
} from '@heroicons/react/24/solid';
import { BookmarkIcon as BookmarkOutlineIcon } from '@heroicons/react/24/outline';

// Import slider styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface PropertyPicturesProps {
  images: string[];
  title: string;
  isBookmarked: boolean;
  handleBookmark: () => void;
  handleShare: () => void;
}

const PropertyPictures: React.FC<PropertyPicturesProps> = ({
  images,
  title,
  isBookmarked,
  handleBookmark,
  handleShare
}) => {
  const [expandedView, setExpandedView] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    adaptiveHeight: true,
    beforeChange: (current: number, next: number) => setCurrentImageIndex(next),
    appendDots: (dots: React.ReactNode) => (
      <div className="py-4">
        <ul className="flex justify-center space-x-3">{dots}</ul>
      </div>
    ),
    customPaging: (i: number) => (
      <button className="w-3 h-3 rounded-full bg-gray-300 transition-all duration-300 transform hover:scale-125 hover:bg-blue-500">
        <span className="sr-only">Go to slide {i + 1}</span>
      </button>
    )
  };

  const handleImageClick = () => {
    setExpandedView(true);
  };

  const handlePrevImage = () => {
    sliderRef.current?.slickPrev();
  };

  const handleNextImage = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <div className="mb-12 relative">
      <div className="rounded-xl overflow-hidden shadow-lg">
        <Slider ref={sliderRef} {...sliderSettings}>
          {images.map((image, index) => (
            <div
              key={index}
              className="relative h-[500px]"
              onClick={handleImageClick}
            >
              <img
                src={image}
                alt={`${title} - ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </Slider>

        {/* Image Counter */}
        <div className="absolute top-4 left-4 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full text-sm font-semibold flex items-center space-x-2">
          <CameraIcon className="w-4 h-4" />
          <span>
            {currentImageIndex + 1} / {images.length}
          </span>
        </div>

        {/* Navigation Chevrons */}
        {images.length > 1 && (
          <>
            <button
              onClick={handlePrevImage}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
              aria-label="Previous image"
            >
              <ChevronLeftIcon className="w-6 h-6" />
            </button>
            <button
              onClick={handleNextImage}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full hover:bg-opacity-75 transition-all duration-300"
              aria-label="Next image"
            >
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          </>
        )}
      </div>

      {/* Overlay with Title and Action Buttons */}
      <div className="absolute inset-x-0 bottom-0 p-6 bg-gradient-to-t from-black to-transparent rounded-b-xl">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white drop-shadow-lg whitespace-normal">
            {title}
          </h1>
          <div className="flex space-x-4">
            <button
              onClick={handleBookmark}
              className="p-3 bg-white bg-opacity-75 rounded-full shadow-md hover:shadow-lg transition-all duration-300"
              aria-label={isBookmarked ? 'Remove bookmark' : 'Add bookmark'}
            >
              {isBookmarked ? (
                <BookmarkIcon className="w-6 h-6 text-blue-500" />
              ) : (
                <BookmarkOutlineIcon className="w-6 h-6 text-gray-600" />
              )}
            </button>
            <button
              onClick={handleShare}
              className="p-3 bg-white bg-opacity-75 rounded-full shadow-md hover:shadow-lg transition-all duration-300"
              aria-label="Share property"
            >
              <ShareIcon className="w-6 h-6 text-gray-600" />
            </button>
          </div>
        </div>
      </div>

      {/* Expanded Image View */}
      {expandedView && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
          <button
            onClick={() => setExpandedView(false)}
            className="absolute top-4 right-4 text-white"
            aria-label="Close expanded view"
          >
            <XMarkIcon className="w-8 h-8" />
          </button>
          <button
            onClick={handlePrevImage}
            className="absolute left-4 text-white"
            aria-label="Previous image"
          >
            <ChevronLeftIcon className="w-12 h-12" />
          </button>
          <img
            src={images[currentImageIndex]}
            alt={`${title} - Expanded View`}
            className="max-h-[90vh] max-w-[90vw] object-contain"
          />
          <button
            onClick={handleNextImage}
            className="absolute right-4 text-white"
            aria-label="Next image"
          >
            <ChevronRightIcon className="w-12 h-12" />
          </button>
          <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 text-white">
            {currentImageIndex + 1} / {images.length}
          </div>
        </div>
      )}
    </div>
  );

};

export default PropertyPictures;

// src/components/auto-apply/SuccessStories.tsx

import React from 'react';
import { UserCircle, Star, Home, Clock, MoveRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

interface SuccessStory {
  name: string;
  location: string;
  image?: string;
  quote: string;
  stats: {
    timeToRent: string;
    applicationsSent: number;
    daysSearching: number;
  };
}

const successStories: SuccessStory[] = [
  {
    name: 'Sarah Johnson',
    location: 'Dublin City Centre',
    quote:
      'Auto Apply helped me secure my dream apartment in just 3 days. The automatic matching system saved me countless hours of searching and applying.',
    stats: {
      timeToRent: '3 days',
      applicationsSent: 4,
      daysSearching: 5
    }
  },
  {
    name: 'Michael Chen',
    location: 'Rathmines',
    quote:
      "I was among the first to apply for my current apartment thanks to Auto Apply's instant notifications. The process was seamless!",
    stats: {
      timeToRent: '1 week',
      applicationsSent: 6,
      daysSearching: 8
    }
  },
  {
    name: 'Emma Walsh',
    location: 'Blackrock',
    quote:
      'Setting my preferences once and letting Auto Apply automatically submit applications saved me so much time. I found my ideal place without constantly checking listings.',
    stats: {
      timeToRent: '5 days',
      applicationsSent: 3,
      daysSearching: 6
    }
  }
];

const SuccessStories: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gradient-to-b py-16 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Success Stories
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            See how Auto Apply has helped renters find their perfect home
          </p>
        </div>

        {/* Success Stories Grid */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 mb-16">
          {successStories.map((story, index) => (
            <motion.div
              key={story.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-lg overflow-hidden"
            >
              <div className="p-6">
                {/* Profile Section */}
                <div className="flex items-center mb-4">
                  {story.image ? (
                    <img
                      src={story.image}
                      alt={story.name}
                      className="w-12 h-12 rounded-full object-cover"
                    />
                  ) : (
                    <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center">
                      <UserCircle className="w-8 h-8 text-blue-500" />
                    </div>
                  )}
                  <div className="ml-4">
                    <h3 className="font-semibold text-gray-900">
                      {story.name}
                    </h3>
                    <p className="text-sm text-gray-500">{story.location}</p>
                  </div>
                </div>

                {/* Quote */}
                <div className="mb-6">
                  <div className="flex mb-2">
                    {[...Array(5)].map((_, i) => (
                      <Star
                        key={i}
                        className="w-5 h-5 text-yellow-400 fill-current"
                      />
                    ))}
                  </div>
                  <p className="text-gray-600 italic">"{story.quote}"</p>
                </div>

                {/* Stats */}
                <div className="border-t pt-4">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-center">
                      <div className="flex items-center justify-center text-blue-500 mb-1">
                        <Clock className="w-4 h-4 mr-1" />
                        <span className="font-semibold">
                          {story.stats.timeToRent}
                        </span>
                      </div>
                      <p className="text-xs text-gray-500">Time to Rent</p>
                    </div>
                    <div className="text-center">
                      <div className="flex items-center justify-center text-green-500 mb-1">
                        <Home className="w-4 h-4 mr-1" />
                        <span className="font-semibold">
                          {story.stats.applicationsSent}
                        </span>
                      </div>
                      <p className="text-xs text-gray-500">Applications</p>
                    </div>
                    <div className="text-center">
                      <div className="flex items-center justify-center text-purple-500 mb-1">
                        <Clock className="w-4 h-4 mr-1" />
                        <span className="font-semibold">
                          {story.stats.daysSearching}
                        </span>
                      </div>
                      <p className="text-xs text-gray-500">Days Searching</p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="bg-blue-600 text-white rounded-xl shadow-lg p-8 text-center">
          <h2 className="text-2xl font-bold mb-4">
            Ready to Write Your Success Story?
          </h2>
          <p className="text-blue-100 mb-6">
            Join thousands of happy renters who found their home using Auto
            Apply
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => navigate('/signup')}
              className="px-6 py-3 bg-white text-blue-600 font-medium rounded-lg hover:bg-blue-50 transition-colors duration-300 flex items-center"
            >
              Get Started
              <MoveRight className="w-5 h-5 ml-2" />
            </button>
            <button
              onClick={() => navigate('/auto-apply/how-it-works')}
              className="px-6 py-3 bg-blue-700 text-white font-medium rounded-lg hover:bg-blue-800 transition-colors duration-300"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStories;

// src/pages/email-verification/EmailVerification.tsx
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { CheckCircle, XCircle, Loader2, AlertTriangle } from 'lucide-react';
import { useAnalyticsWithConsent } from '../../hooks/useAnalyticsWithConsent';
import { AnalyticsEvents } from '../../analytics/firebaseAnalytics';

const VERIFY_EMAIL_URL = 'https://verifyemail-byx4ztggoq-ew.a.run.app';

interface VerificationStatus {
  type: 'loading' | 'success' | 'error' | 'expired';
  message: string;
  details?: string;
}

const EmailVerification: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const analytics = useAnalyticsWithConsent();
  const [status, setStatus] = useState<VerificationStatus>({
    type: 'loading',
    message: 'Verifying your email...'
  });

  useEffect(() => {
    const verifyEmail = async () => {
      const token = searchParams.get('token');

      if (!token) {
        setStatus({
          type: 'error',
          message: 'Invalid verification link',
          details: 'The verification link is missing required information.'
        });
        return;
      }

      try {
        const response = await fetch(`${VERIFY_EMAIL_URL}?token=${token}`);
        const data = await response.json();

        if (!response.ok) {
          // Handle different error cases based on status code
          switch (response.status) {
            case 404:
              setStatus({
                type: 'error',
                message: 'Invalid verification link',
                details:
                  'This verification link is invalid or has already been used.'
              });
              break;
            case 410:
              setStatus({
                type: 'expired',
                message: 'Verification link expired',
                details:
                  'This verification link has expired. Please request a new one.'
              });
              break;
            case 400:
              setStatus({
                type: 'error',
                message: 'Already verified',
                details:
                  'This email has already been verified. You can proceed to use all features.'
              });
              break;
            default:
              throw new Error(data.error || 'Verification failed');
          }
          return;
        }

        // Track successful verification
        analytics.logEvent(AnalyticsEvents.EMAIL_VERIFIED, {
          success: true,
          verification_time: new Date().toISOString()
        });

        setStatus({
          type: 'success',
          message: 'Email verified successfully!'
        });
      } catch (error) {
        console.error('Error verifying email:', error);

        analytics.trackError(
          'email_verification_error',
          error instanceof Error ? error.message : 'Unknown error'
        );

        setStatus({
          type: 'error',
          message: 'Verification failed',
          details:
            'An error occurred while verifying your email. Please try again later.'
        });
      }
    };

    verifyEmail();
  }, [searchParams, analytics]);

  const renderContent = () => {
    switch (status.type) {
      case 'loading':
        return (
          <div className="flex flex-col items-center">
            <Loader2 className="w-16 h-16 text-blue-500 animate-spin mb-4" />
            <p className="text-gray-600">{status.message}</p>
          </div>
        );

      case 'success':
        return (
          <div className="text-center">
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Email Verified Successfully!
            </h2>
            <p className="text-gray-600 mb-8">
              Your email has been verified. You now have full access to all
              RentSmart features.
            </p>

            <div className="space-y-4">
              <button
                onClick={() => navigate('/dashboard')}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Go to Dashboard
              </button>

              <div className="mt-8 bg-blue-50 p-4 rounded-lg">
                <h3 className="font-semibold text-blue-800 mb-2">
                  What's Next?
                </h3>
                <ul className="text-blue-700 text-sm space-y-2">
                  <li>✓ Create property listings</li>
                  <li>✓ Contact property owners</li>
                  <li>✓ Save favorite properties</li>
                  <li>✓ Receive property alerts</li>
                </ul>
              </div>
            </div>
          </div>
        );

      case 'expired':
        return (
          <div className="text-center">
            <AlertTriangle className="w-16 h-16 text-yellow-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Verification Link Expired
            </h2>
            <p className="text-gray-600 mb-4">{status.message}</p>
            <p className="text-sm text-gray-500 mb-8">{status.details}</p>

            <div className="space-y-4">
              <button
                onClick={() => navigate('/dashboard')}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Return to Dashboard
              </button>
              <p className="text-sm">
                Need a new verification link?{' '}
                <button
                  onClick={() => navigate('/resend-verification')}
                  className="text-blue-600 hover:underline"
                >
                  Click here
                </button>
              </p>
            </div>
          </div>
        );

      case 'error':
        return (
          <div className="text-center">
            <XCircle className="w-16 h-16 text-red-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Verification Failed
            </h2>
            <p className="text-gray-600 mb-4">{status.message}</p>
            {status.details && (
              <p className="text-sm text-gray-500 mb-8">{status.details}</p>
            )}

            <div className="space-y-4">
              <button
                onClick={() => navigate('/dashboard')}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Return to Dashboard
              </button>
              <p className="text-sm text-gray-500">
                Need help?{' '}
                <a
                  href="mailto:support@rentsmart.ie"
                  className="text-blue-600 hover:underline"
                >
                  Contact Support
                </a>
              </p>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg p-8">
        {renderContent()}
      </div>
    </div>
  );
};

export default EmailVerification;

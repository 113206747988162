/**
 * Generates a random numeric property ID of the specified length.
 * @param length The desired length of the property ID (default is 8)
 * @returns A string representing the generated numeric property ID
 */
export function generateNumericPropertyId(length: number = 8): number {
  if (length <= 0) {
    throw new Error('Length must be a positive integer');
  }

  let result = '';

  // Generate the first digit (1-9 to avoid leading zeros)
  result += Math.floor(Math.random() * 9) + 1;

  // Generate the rest of the digits
  for (let i = 1; i < length; i++) {
    result += Math.floor(Math.random() * 10);
  }

  return Number(result);
}

// Example usage:
// const propertyId = generateNumericPropertyId(10); // Generates a 10-digit property ID
// console.log(propertyId); // Outputs something like "6723841509"
